import { useMutation, useQuery, useQueryClient } from "react-query";
import qs from "qs-lite";
import api from "../../utils/api";
import { CUSTOMERS } from "./useCustomerData";
import { useHubSnackBar } from "../useSnackBar";

const PARTS = "/parts";

const getPartData = ({ queryKey }) => {
  const id = queryKey[1];
  return api.get(`${PARTS}/${id}`);
};

export const useGetPartData = ({ id }) => {
  return useQuery([PARTS, id], getPartData);
};

const getAllParts = ({ queryKey }) => {
  const queryString = qs.stringify(queryKey[1]);
  return api.get(`${PARTS}?${queryString}`);
};

export const useGetAllParts = () => {
  const query = new URLSearchParams(window.location.search);

  const orderBy = query.get("orderBy") || "partName";
  const sortOrder = query.get("sortOrder") || "asc";
  const limit = query.get("limit") || 100;
  const offset = query.get("offset") || 0;

  const queryProps = {
    limit,
    offset,
    orderBy,
    sortOrder,
  };

  return useQuery([PARTS, { ...queryProps }], getAllParts);
};

const postCreatePart = (payload) => api.post(PARTS, payload);
export const usePostCreatePart = () => {
  const { addSnackbarSuccess } = useHubSnackBar();
  const queryClient = useQueryClient();

  return useMutation(postCreatePart, {
    onSuccess: () => {
      queryClient.invalidateQueries(CUSTOMERS);
      addSnackbarSuccess({ message: "Successfully Created Contact" });
    },
  });
};

const putPartData = (data) => {
  const id = data.id;
  delete data.id;
  return api.put(`${PARTS}/${id}`, data);
};
export const usePutPartData = () => {
  const { addSnackbarSuccess } = useHubSnackBar();
  const queryClient = useQueryClient();

  return useMutation(putPartData, {
    onSuccess: () => {
      queryClient.invalidateQueries(PARTS);
      addSnackbarSuccess({ message: "Successfully Updated Part" });
    },
  });
};

const postPartEstiamte = (payload) => api.post(`${PARTS}/estimate`, payload);
export const usePostPartEstimate = () => useMutation(postPartEstiamte);

const deletePartById = (id) => api.delete(`${PARTS}/${id}`);
export const useDeletePartById = () => {
  const { addSnackbarSuccess } = useHubSnackBar();
  const queryClient = useQueryClient();

  return useMutation(deletePartById, {
    onSuccess: () => {
      queryClient.invalidateQueries(CUSTOMERS);
      addSnackbarSuccess({ message: "Successfully Deleted Part" });
    },
  });
};
