import { styled } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import { PickersDay } from '@mui/x-date-pickers';

export const StyledBarBox = styled(Box)(({ theme }) => ({
    minHeight: "2px",
    width: "100%",
    borderRadius: "5px",
}))

export const StyledBarStack = styled(Stack, {
    shouldForwardProp: (prop) => !["isOffDay"].includes(prop)
})(({ isOffDay }) => (isOffDay ? { display: "none" } : {
    flexDirection: "row",
    width: "100%",
    height: "100%",
    alignItems: "end",
    gap: "5px",
}))

export const StyledBarEditStack = styled(Stack)({
    justifyContent: "end",
    width: "100%",
    height: "100%",
    gap: "4px",
})

export const StyledDateStack = styled(Stack, {
    shouldForwardProp: (prop) => !["isOffDay"].includes(prop)
})(({ isOffDay }) => ({
    height: "100%",
    width: "100%",
    justifyContent: isOffDay ? "start" : "space-between",
    alignItems: "center",
    gap: "5px",
    padding: "5px",
}))


export const StyledPickerDay = styled(PickersDay, {
    shouldForwardProp: (prop) => !["notCurrent", "isOffDay", "today", "selected"].includes(prop)
})(({ notCurrent, isOffDay, today, selected, theme }) => ({
    width: "75px",
    height: "75px",
    margin: 0,
    border: "0.5px solid",
    borderColor: theme.palette.hubColors.greyLight,
    backgroundColor: isOffDay ? theme.palette.hubColors.greyLighter : "transparent",
    "&.MuiButtonBase-root": {
        borderRadius: "0px !important",
    },
    "& .MuiStack-root": {
        opacity: notCurrent ? 0.5 : 1,
    },
    ...(today ? { color: theme.palette.hubColors.mainFocus, fontWeight: 600 } : {}),
    ...(selected ? {
        color: theme.palette.hubColors.mainFocus,
        fontWeight: 600,
        border: "1px solid",
        backgroundColor: theme.palette.hubColors.mainFocusLightest,
        borderColor: theme.palette.hubColors.mainFocus,
    } : {})
}))

export const PopperStyles = {
    "& .MuiDateCalendar-root": {
        minWidth: "525px !important",
        width: "525px !important",
        height: "fit-content",
        maxHeight: "600px",
        "& .MuiDayCalendar-weekContainer": {
            margin: 0
        },
        "& .MuiDayCalendar-weekDayLabel": {
            width: "525px",
        },
        "& .MuiPickersSlideTransition-root": {
            overflow: "visible",
            height: "450px",
        },
        "& .MuiDayCalendar-monthContainer": {
            position: "relative",
        }
    },
}