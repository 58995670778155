import React from "react";
import { Stack, Typography } from "@mui/material";

import { getDollarValue } from "../../helpers/currency";
import HubHoverInfo from "../../components/HubHoverInfo/HubHoverInfo";

export const PoItemDetails = ({ item }) => {
  const label = (
    <Stack
      gap="5px"
      alignItems="start"
      sx={{ strong: { color: "hubColors.mainFocus" } }}
    >
      <Typography variant="body1">Build Data</Typography>
      <Typography variant="caption">
        Trees: <strong>{item?.trees}</strong>
      </Typography>
      <Typography variant="caption">
        Qty Required: <strong>{item?.patReq}</strong>
      </Typography>
      <Typography variant="caption">
        Last Sent Price:{" "}
        <strong>{getDollarValue(item?.part?.lastSentPrice)}</strong>
      </Typography>
    </Stack>
  );

  return (
    <HubHoverInfo
      label={label}
      icon="Info"
      iconProps={{ size: 12, color: "hubColors.greyDark" }}
      isCard
    />
  );
};

export default PoItemDetails;
