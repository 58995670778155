import React from "react";
import { Box } from "@mui/material";

import { StyledTab, StyleTabContainer } from "./styles";

export const HubTabs = ({
  tabs = [],
  value = "",
  setValue = () => {},
  borderless = false,
  sx,
  ...rest
}) => {
  const basicProps = { height: "35px" };
  const boxProps = borderless
    ? basicProps
    : { borderBottom: 1, borderColor: "hubColors.greyLight", ...basicProps };

  return (
    <Box sx={{ ...boxProps, ...sx }} {...rest}>
      <StyleTabContainer value={value} aria-label="hub tab panels">
        {tabs?.map((tab) => (
          <StyledTab
            label={tab.label}
            id={tab.id}
            aria-controls={"hub-tabpanel-" + tab.id}
            active={tab.id === value}
            onClick={() => setValue(tab.id)}
          />
        ))}
      </StyleTabContainer>
    </Box>
  );
};

export default HubTabs;
