import React from "react";
import { useNavigate } from "react-router-dom";
import {
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Typography,
  Chip,
} from "@mui/material";

import CompaniesContextMenu from "./CompaniesContextMenu";
import { UserDetailIcon } from "../../components";
import { stringToAlphabetSum } from "../../helpers/userImageColor";
import { connect } from "react-redux";
import { getCustomers } from "../../redux/customers";
import { getDollarValue } from "../../helpers/currency";

const CompaniesBody = ({ customers, setDeletingId = () => {} }) => {
  const navigate = useNavigate();

  const onClick = (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(`/customers/${id}`);
  };

  return (
    <TableBody>
      {customers?.map((c) => (
        <TableRow key={c?.id} onClick={(e) => onClick(e, c?.id)}>
          <TableCell>
            <Chip
              variant="colorTag"
              flow={c?.active ? "green" : " grey"}
              sx={{ fontSize: 10 }}
              label={`◉ ${c?.active ? "Active" : "Inactive"}`}
            />
          </TableCell>
          <TableCell>{c?.name}</TableCell>
          <TableCell>
            <PrimaryContact contact={c?.contact} />
          </TableCell>
          <TableCell>{c?.address}</TableCell>
          <TableCell>{c?.contact?.phone}</TableCell>
          <TableCell>{c?.industry}</TableCell>
          <TableCell>{c?.priority}</TableCell>
          <TableCell>{getDollarValue(c?.totalSpent)}</TableCell>
          <TableCell>{c?.location}</TableCell>
          <TableCell padding="checkbox">
            <CompaniesContextMenu
              id={c?.id}
              active={c?.active}
              setDeletingId={setDeletingId}
            />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export const PrimaryContact = ({ contact }) => {
  const { name = "", email = "" } = contact;
  return name || email ? (
    <UserDetailIcon
      user={{
        name,
        email,
        hueShift: stringToAlphabetSum(
          email && name ? email + name : name || email
        ),
      }}
      includeThumbnail={false}
      includeEmail={email}
      includeName={name}
      includeInital
      size={25}
    />
  ) : (
    <></>
  );
};

const mapStateToProps = (state) => ({
  customers: getCustomers(state),
});
export default connect(mapStateToProps)(CompaniesBody);
