export const SHIPPING = [
  {
    label: "IPC",
    id: 1,
  },
  {
    label: "IPC Keep",
    id: 2,
  },
  {
    label: "Best Way",
    id: 3,
  },
  {
    label: "CH Robinson - Domest",
    id: 4,
  },
  {
    label: "eShip",
    id: 5,
  },
  {
    label: "eshipping.biz",
    id: 6,
  },
  {
    label: "eshipping.biz",
    id: 7,
  },
  {
    label: "FedEx",
    id: 8,
  },
  {
    label: "FedEx Freight",
    id: 9,
  },
  {
    label: "FedEx Overnight",
    id: 10,
  },
  {
    label: "Freight",
    id: 11,
  },
  {
    label: "LFS",
    id: 12,
  },
  {
    label: "LTL",
    id: 13,
  },
  {
    label: "Motor Freight",
    id: 14,
  },
  {
    label: "Routing Guide",
    id: 15,
  },
  {
    label: "SAIA LTL Freight",
    id: 16,
  },
  {
    label: "Truck",
    id: 17,
  },
  {
    label: "Trucking",
    id: 18,
  },
  {
    label: "UPS",
    id: 19,
  },
  {
    label: "UPS 770156",
    id: 20,
  },
  {
    label: "Victaulic",
    id: 21,
  },
  {
    label: "Will Call",
    id: 22,
  },
  {
    label: "XPO",
    id: 23,
  },
  {
    label: "XPO-Clt",
    id: 24,
  },
  {
    label: "XPO-Collect",
    id: 25,
  },
];
