import React from "react";

import { HubTableHeader, InputLabel } from "../../components";

const required = true;

const headCells = [
  {
    id: "op-service",
    label: "Service",
    required,
  },
  {
    id: "op-partner",
    label: "Partner",
    required,
  },
  {
    id: "op-lead",
    label: "Lead Time",
    required,
  },
];

export const OutsideProcessingTableHeader = () => {
  const ITEMS = headCells.map((cell) => ({
    id: cell.id,
    label: cell?.required ? (
      <InputLabel label={cell.label} required disableMargin />
    ) : (
      cell.label
    ),
  }));

  return (
    <HubTableHeader
      items={ITEMS}
      includeCheckbox={false}
      sx={{ backgroundColor: "hubColors.greyLighter" }}
    />
  );
};

export default OutsideProcessingTableHeader;
