import React from "react";
import { Table, TableContainer } from "@mui/material";

import PreFoundryTableHeader from "./PreFoundryTableHeader";
import PreFoundryTableBody from "./PreFoundryTableBody";

export const PreFoundryTable = () => {
  return (
    <TableContainer>
      <Table id="PreFoundryTable">
        <PreFoundryTableHeader />
        <PreFoundryTableBody />
      </Table>
    </TableContainer>
  );
};

export default PreFoundryTable;
