import { Box, Stack, Typography } from "@mui/material";
import React from "react";

export const HubChartLabel = ({ color, label, LabelProps = {}, StackProps = {} }) => {
  return (
    <Stack
      direction="row"
      gap="10px"
      alignItems="center"
      justifyContent="start"
      {...StackProps}
    >
      <Box
        width="10px"
        height="10px"
        borderRadius="3px"
        backgroundColor={color}
      />
      <Typography {...LabelProps}>{label}</Typography>
    </Stack>
  );
};

export default HubChartLabel;
