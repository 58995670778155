import React from "react";
import { TableBody, TableRow, TableCell } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { getOrders } from "../../redux";
import { getDollarValue } from "../../helpers/currency";
import { getDateData } from "../../helpers/dateHelper";
import { FOUNDRY_NAMES } from "../../constants";

const JobsTableBody = ({ jobs = [] }) => {
  const navigate = useNavigate();

  const handleNav = (e, route, id) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/${route}/${id}`);
  };

  return (
    <TableBody>
      {jobs.map((job, index) => {
        return (
          <TableRow key={index}>
            <TableCell variant="blueBold">{job.woNumber}</TableCell>
            <TableCell
              variant="blueBold"
              onClick={(e) => handleNav(e, "customers", job?.customer?.id)}
            >
              {job?.customer?.name}
            </TableCell>
            <TableCell
              variant="blueBold"
              onClick={(e) => handleNav(e, "parts", job.partId)}
            >
              {job.partName}
            </TableCell>
            {/* <TableCell>{job.poNumber}</TableCell> */}
            <TableCell>{job.quantity}</TableCell>
            <TableCell>
              {getDollarValue(parseFloat(job.price) * job.quantity)}
            </TableCell>
            <TableCell>
              {job?.hours}
              {/* {Math.round(parseFloat(job.margin) * 10000) / 100} % */}
            </TableCell>
            <TableCell>
              {job.process} ({job.status})
            </TableCell>
            <TableCell>
              {job.startDate &&
                getDateData({
                  startDate: job.startDate,
                  includeYear: true,
                })}
            </TableCell>
            <TableCell>
              {job.endDate &&
                getDateData({
                  startDate: job.endDate,
                  includeYear: true,
                })}
            </TableCell>
            <TableCell>{FOUNDRY_NAMES[job.location]}</TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

const mapStateToProps = (state) => ({
  jobs: getOrders(state),
});

export default connect(mapStateToProps)(JobsTableBody);
