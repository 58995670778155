import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

export const StyledStack = styled(Stack, {
  shouldForwardProp: (props) =>
    !["open", "dispatch", "fullHeight", "white"]?.includes(props),
})(({ fullHeight, white, theme }) => ({
  flexDirection: "column",
  position: "relative",
  width: "100%",
  overflowX: "hidden",
  overflowY: "auto",
  height: fullHeight ? "100vh" : `calc(100vh - 51px)`,
  backgroundColor: theme.palette.hubColors[white ? "white" : "greyLightest"],
  // scrollbarGutter: "stable both-edges",
}));
