import React from "react";
import { Chip, Stack, Typography } from "@mui/material";
import { connect } from "react-redux";

import { DialogButton } from "../../components";
import { getCustomerPartsCount } from "../../redux/customerData";

export const CustomerPartsBar = ({ disabled = false, count = 0 }) => {
  return (
    <Stack justifyContent="space-between" alignItems="end" direction="row">
      <Stack direction="row" gap="10px" alignItems="center">
        <Typography variant="boldCaption">Parts</Typography>
        <Chip label={`${count} Parts`} variant="colorTag" />
      </Stack>
      <Stack direction="row" gap="15px">
        {/* <DialogButton
            name="adjust-columns"
            label="Adjust Table Columns"
            variant="outlined"
            size="small"
            disabled={disabled}
          /> */}
        <DialogButton
          name="create-customer-part"
          label="Create Part"
          variant="contained"
          size="xsmall"
          disabled={disabled}
        />
      </Stack>
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  count: getCustomerPartsCount(state),
});

export default connect(mapStateToProps)(CustomerPartsBar);
