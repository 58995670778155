import React from "react";
import {
  TableHead,
  TableCell,
  TableSortLabel,
  Stack,
  TableRow,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { HubIcon } from "../../HubIcon";
import { StyledTableCell } from "./styles";

const flippedSo = {
  asc: "desc",
  desc: "asc",
};

export const HubTableHeader = ({
  items = [],
  onChange = () => {},
  includeCheckbox = true,
  includeEnd = true,
  sx = {},
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const orderBy = query.get("orderBy");
  const sortOrder = query.get("sortOrder");

  const handleSort = (id) => {
    const isAsc = orderBy === id && sortOrder === "asc";
    const newSortOrder = isAsc ? "desc" : "asc";

    query.set("orderBy", id);
    query.set("sortOrder", newSortOrder);

    navigate({
      pathname: location.pathname,
      search: query.toString(),
    });

    onChange();
  };

  return (
    <TableHead sx={sx}>
      <TableRow>
        {includeCheckbox && <TableCell padding="checkbox"></TableCell>}
        {items?.map((item) => (
          <StyledTableCell key={item?.id}>
            <Stack direction="row" gap="7px" alignItems="center">
              {item?.icon && <HubIcon icon={item?.icon} size="12px" />}
              {item?.sortable ? (
                <TableSortLabel
                  active={orderBy === item?.id}
                  direction={orderBy === item?.id ? sortOrder : "desc"}
                  onClick={() => handleSort(item?.id)}
                >
                  {item?.label}
                </TableSortLabel>
              ) : (
                item?.label
              )}
            </Stack>
          </StyledTableCell>
        ))}
        {includeEnd && <TableCell padding="checkbox"></TableCell>}
      </TableRow>
    </TableHead>
  );
};

export default HubTableHeader;
