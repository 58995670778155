import React from "react";

import { HubContextMenu } from "../../components";
import { useDeletePartById } from "../../hooks";

const MENU_ITEMS = [
  // {
  //   id: "save",
  //   label: "Save",
  //   icon: "Share2",
  // },
  //   {
  //     id: "revert",
  //     label: "Revert",
  //     icon: "Share2",
  //   },
  //   {
  //     id: "quote",
  //     label: "Send Quote",
  //     icon: "Share2",
  //   },
  {
    id: "delete",
    label: "Delete",
    icon: "Trash2",
    color: "redError",
  },
];

export const PartContextMenu = ({ id, ...rest }) => {
  const { mutate: deletePartById } = useDeletePartById();

  const onSelect = (item) => {
    switch (item?.id) {
      case "quote":
        break;
      case "delete":
        deletePartById(id);
        break;
      default:
        break;
    }
  };

  return (
    <HubContextMenu
      id={"part-details"}
      loading={false}
      menuItems={MENU_ITEMS}
      onSelect={onSelect}
      {...rest}
    />
  );
};
export default PartContextMenu;
