import React from "react";
import { Stack, Typography } from "@mui/material";
import { connect } from "react-redux";

import { getPartRouteByName } from "../../../redux/routing";
import { HubHookFormInput, HubHookFormSwitch, HubHookFormTimePicker } from "../../../components";
import { StyledHubCardStack } from "../styles";

const gap = "10px";
const columnGap = "10px";

const PartFoundry = ({ details = {} }) => {

  return (
    <StyledHubCardStack gap="25px">
      <Stack gap={gap}>
        <Typography variant="boldCaptionMain">Foundry Details</Typography>
        <Stack gap="25px">
          <Stack direction="row" gap={columnGap}>
            <HubHookFormInput
              name="foundry.metalTemp"
              defaultValue={details.metalTemp || "0"}
              placeholder="Ex. 12"
              label="Metal Temp"
              endAdornment="°F"
              isNumber
            />
            <HubHookFormInput
              name="foundry.shellTemp"
              defaultValue={details.shellTemp || "0"}
              placeholder="Ex. 12"
              label="Shell Temp"
              endAdornment="°F"
              isNumber
            />
            <HubHookFormInput
              name="foundry.crucibleCapacity"
              label="Crucible Capactiy"
              defaultValue={details.crucibleCapacity || "0"}
              placeholder="Ex. 12"
              isNumber
            />
          </Stack>
          <Stack direction="row" gap={columnGap}>
            <HubHookFormInput
              name="foundry.poundsPerHeat"
              label="Pounds Per Heat"
              defaultValue={details.poundPerHeat}
              placeholder="Ex. 12"
              isNumber
            />
            <HubHookFormInput
              name="foundry.treesPerHeat"
              label="Trees Per Heat"
              defaultValue={details.treesPerHeat}
              placeholder="Ex. 12"
              isNumber
            />
            <HubHookFormInput
              name="foundry.numberHeats"
              label="Number Heats"
              placeholder="Ex. 12"
              defaultValue={details.numberHeats}
              isNumber
            />
          </Stack>
          <Stack direction="row" gap={columnGap}>
            <HubHookFormInput
              name="foundry.strainer"
              label="Strainer"
              defaultValue={details.strainer}
              placeholder="Ex. 12"
            />
            <HubHookFormTimePicker
              name="foundry.leadTime"
              label="Lead Time"
              placeholder="Ex. 12"
              defaultValue={details.leadTime}
              onlyDays
              required
            />
            <HubHookFormTimePicker
              name="foundry.cycleTime"
              label="Cycle Time"
              placeholder="Ex. 0.24"
              defaultValue={details.cycleTime}
              required
            />
          </Stack>
          <Stack direction="row" gap={columnGap}>
            <HubHookFormSwitch
              name="foundry.wrapTree"
              label="Wrap Tree"
              labelPlacement="end"
              defaultValue={details.wrapTree}
              disableHelperText
              isCheckbox
            />
            <HubHookFormSwitch
              name="foundry.hotTop"
              label="Hot Top"
              labelPlacement="end"
              defaultValue={details.hotTop}
              disableHelperText
              isCheckbox
            />
            <HubHookFormSwitch
              name="foundry.cover"
              label="Cover"
              labelPlacement="end"
              defaultValue={details.cover}
              disableHelperText
              isCheckbox
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack gap={gap}>
        <Typography variant="boldCaptionMain">Foundry Notes</Typography>
        <HubHookFormInput
          name="foundry.notes"
          label="Special Instructions"
          defaultValue={details.notes}
          placeholder="Dip notes..."
          rows={5}
          multiline
        />
      </Stack>
    </StyledHubCardStack>
  );
};

const mapStateToProps = (state) => ({
  details: getPartRouteByName(state, "foundry"),
});

export default connect(mapStateToProps)(PartFoundry);
