import { createSelector } from "reselect";

const UPDATE_OPEN_DIALOG = "UPDATE_OPEN_DIALOG";
const CLEAR_DIALOGS = "CLEAR_DIALOGS";

const initialState = {};

const dialogHelper = (dialogs = {}, name = "", open = false) => ({
  ...dialogs,
  [name]: open,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "FLUSH":
    case CLEAR_DIALOGS:
      return initialState;

    case UPDATE_OPEN_DIALOG:
      return {
        ...dialogHelper(state, action.name, action.open),
      };

    default:
      return state;
  }
}

export function setIsOpenDialog(name, open) {
  return {
    type: UPDATE_OPEN_DIALOG,
    name,
    open,
  };
}

export const getDialogState = (state) => state.dialogs;

export const getIsDialogOpen = createSelector(
  getDialogState,
  (_, dialogName) => dialogName,
  (dialogState, dialogName) => dialogState[dialogName] || false
);
