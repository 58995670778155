import React, { useEffect, useMemo, useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "@mui/material/Select";

import { InputLabel } from "../InputLabel";
import { HubIcon } from "../HubIcon";

import HubTimePicker from "./HubTimePicker";
import HubTimePickerRender from "./HubTimePickerRender";

import { StyledFormControl } from "./styles";

const convertMinutesToTime = (totalMinutes) => {
  const totalSeconds = totalMinutes * 60;
  const days = Math.floor(totalSeconds / (24 * 60 * 60));
  const hours = Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
  const seconds = Math.round((totalSeconds % 60) * 10000) / 10000;
  return { days, hours, minutes, seconds };
};

const convertTimeToMinutes = ({
  days = 0,
  hours = 0,
  minutes = 0,
  seconds = 0,
}) => {
  const period = days * 24 * 60 + hours * 60 + minutes + seconds / 60;
  return Math.round(period * 10000) / 10000;
};

const DefaultIcon = ({ ...rest }) => (
  <HubIcon
    icon="Clock"
    sx={{
      marginRight: "10px",
      marginTop: "6px",
      cursor: "pointer",
    }}
    size={12}
    {...rest}
  />
);

export const HubHookFormTimePicker = ({
  name = "",
  defaultValue = 0,
  required = false,
  rules = {},
  disabled = false,
  onChange = null,
  isLoading = false,
  label = "",
  helperText = "",
  disableHelperText = true,
  smallLabel = true,
  hidden = false,
  fullWidth = true,
  onlyDays = false,
  ...rest
}) => {
  const id = `hub-hook-time-picker-${name}`;
  const { setValue, register } = useFormContext();
  const {
    field: { onChange: onControllerChange, value, name: inputName, ref },
    fieldState: { error },
  } = useController({
    name,
    rules: { required, ...rules },
    defaultValue,
    shouldUnregister: true,
  });

  const initialTime = useMemo(
    () => convertMinutesToTime(defaultValue),
    [defaultValue]
  );

  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    setValue(name, convertTimeToMinutes(time)); // eslint-disable-next-line
  }, [time]);

  const handleTimeChange = (field, val) => {
    const newTime = { ...time, [field]: val };
    setTime(newTime);
    const newMinutes = convertTimeToMinutes(newTime);
    onControllerChange(newMinutes);
    onChange && onChange(newMinutes);
  };

  const errorMessage = error?.message;
  const helperTextMessage = disableHelperText
    ? null
    : errorMessage || helperText || null;

  const RenderProps = { time, onlyDays };
  const renderValue = () => <HubTimePickerRender {...RenderProps} />;

  return (
    <StyledFormControl
      hidden={hidden}
      fullWidth={fullWidth}
      variant="outlined"
      {...rest}
    >
      <InputLabel
        label={label}
        required={required}
        id={id}
        smallLabel={smallLabel}
      />
      <Select
        id={id}
        disabled={disabled}
        size="small"
        error={!!error}
        IconComponent={DefaultIcon}
        value={time}
        renderValue={renderValue}
        MenuProps={{
          sx: {
            "& .MuiMenu-paper": {
              maxWidth: "300px",
            },
          },
        }}
        {...rest}
      >
        <HubTimePicker handleTimeChange={handleTimeChange} {...RenderProps} />
      </Select>
      {isLoading && (
        <InputAdornment position="end">
          <CircularProgress size={24} />
        </InputAdornment>
      )}
      {helperTextMessage && (
        <FormHelperText error={!!error} id={`${id}-helper-text`}>
          {helperTextMessage}
        </FormHelperText>
      )}
      <input
        {...register(name)}
        type="hidden"
        value={convertTimeToMinutes(time)}
      />
    </StyledFormControl>
  );
};

HubHookFormTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  rules: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.string,
  disableHelperText: PropTypes.bool,
  smallLabel: PropTypes.bool,
  hidden: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default HubHookFormTimePicker;
