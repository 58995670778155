import React from "react";
import { Chip, Stack, Typography } from "@mui/material";
import { connect } from "react-redux";

import { HubButton } from "../../components";
import { getCustomerOrderCount } from "../../redux/customerData";

const CustomerOrdersBar = ({
  count = 0,
  createProps = {},
  onClick = () => {},
}) => {
  return (
    <Stack
      justifyContent="space-between"
      alignItems="end"
      direction="row"
      gap="15px"
    >
      <Stack direction="row" gap="10px" alignItems="center">
        <Typography variant="boldCaption">Orders</Typography>
        <Chip label={`${count} Orders`} variant="colorTag" />
      </Stack>
      <HubButton
        label="Create Order"
        variant="contained"
        size="xsmall"
        onClick={onClick}
        {...createProps}
      />
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  count: getCustomerOrderCount(state),
});

export default connect(mapStateToProps)(CustomerOrdersBar);
