import React from "react";
import { Table, TableContainer } from "@mui/material";

import RoutingTableHeader from "./RoutingTableHeader";
import RoutingTableBody from "./RoutingTableBody";

export const RoutingTable = () => {
  return (
    <TableContainer
      sx={{ ".MuiTableCell-root": { borderRight: "0 !important" } }}
    >
      <Table id="RoutingTable">
        <RoutingTableHeader />
        <RoutingTableBody />
      </Table>
    </TableContainer>
  );
};

export default RoutingTable;
