import React from "react";
import { Stack, Toolbar, Typography } from "@mui/material";

import { HubButton } from "../inputs";

export const HubToolbar = ({
  label = "",
  subtitle = "",
  onClose = () => {},
  buttonProps = {},
  stackProps = {},
  ...rest
}) => {
  return (
    <Toolbar {...rest}>
      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="end"
        gap="10px"
      >
        <Stack alignItems="end" {...stackProps}>
          {label && (
            <Typography variant="h6" sx={{ fontSize: 16 }} noWrap>
              {label}
            </Typography>
          )}
          {subtitle && (
            <Typography variant="caption" sx={{ color: "hubColors.grey" }}>
              {subtitle}
            </Typography>
          )}
        </Stack>
        <HubButton
          variant="text"
          color="neutral"
          icon="ChevronRight"
          onClick={onClose}
          sx={{ color: "hubColors.grey" }}
          {...buttonProps}
        />
      </Stack>
    </Toolbar>
  );
};

export default HubToolbar;
