import React from "react";

import { HubFilterMenu } from "../../components";

const statuses = [
  {
    id: "true",
    label: "Show Active Customers",
  },
  {
    id: "false",
    label: "Show Inactive Customers",
  },
];

const ContactsActiveFilter = () => {
  return (
    <HubFilterMenu
      id="active"
      options={statuses}
      menuLabel="Filter Contacts By Their Active Status"
      label="Status Filter"
      multiSelect={false}
    />
  );
};

export default ContactsActiveFilter;
