import React from "react";
import { useNavigate } from "react-router-dom";

// import { HubButton } from "../../";
import { StyledTopNavBar, ParentStack, StyledHubButton } from "./styles";

import { MENU_ITEMS } from "../../../constants/menu";
import { FoundrySoloLogo } from "../../../svgs";

// import SearchNavBar from "./SearchNavBar";
import UserProfileContextMenu from "../../ContextMenus/UserProfileContextMenu/UserProfileContextMenu";

const NavBar = ({ role }) => {
  const navigate = useNavigate();

  const NAV_ITEMS = MENU_ITEMS;

  return (
    <StyledTopNavBar>
      <ParentStack gap="30px">
        <FoundrySoloLogo size={30} fill="#252525" />
        <ParentStack gap="10px">
          {NAV_ITEMS?.map(({ label, path }) => (
            <StyledHubButton
              key={path}
              onClick={() => navigate(path)}
              label={label}
              {...(window.location.pathname?.includes(path) && {
                variant: "contained",
                color: "info",
              })}
              {...(!window.location.pathname?.includes(path) && {
                color: "neutral",
              })}
            />
          ))}
        </ParentStack>
      </ParentStack>
      {/* <SearchNavBar /> */}

      <UserProfileContextMenu />
    </StyledTopNavBar>
  );
};

export default NavBar;
