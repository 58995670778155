import React from "react";

import { HubDialogWrapper } from "../HubDialogWrapper";
import CreateCustomerPartContent from "./CreateCustomerPartContent";

export const CreateCustomerPartDialog = ({ requestCustomer = false }) => {
  const name = "create-customer-part";

  return (
    <HubDialogWrapper maxWidth="sm" name={name}>
      <CreateCustomerPartContent requestCustomer={requestCustomer} />
    </HubDialogWrapper>
  );
};

export default CreateCustomerPartDialog;
