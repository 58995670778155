import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

export const StyledStack = styled(Stack)(({ theme }) => ({
  width: "100%",
  flexDirection: "row",
  justifyContent: "space-between",
  "& .MuiSelect-select": {
    padding: "8px 15px",
    minWidth: "50px",
  },
  padding: "10px 25px",
  borderRadius: "5px",
  border: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  backgroundColor: theme.palette.hubColors.white,
  "& .MuiTypography-root, .MuiMenuItem-root": {
    fontSize: "12px",
  },
}));
