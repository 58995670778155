import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { connect } from "react-redux";

import { removePartPost } from "../../redux/routing";
import { POST_FOUNDRY_ROUTINGS as POSTS } from "../../constants";
import {
  HubButton,
  HubHookFormInput,
  HubHookFormSelect,
  HubHookFormTimePicker,
} from "../../components";

const PostFoundryTableRow = ({
  id = "",
  idx = 0,
  post = {},
  _removePartPost = () => {},
}) => {
  const handleRemove = () => _removePartPost(idx);
  const preset = `postFoundry.routes.${id}`;

  return (
    <TableRow
      sx={{
        ".DipRowButton": {
          display: "none",
        },
        "&:hover": {
          ".DipRowButton": {
            display: "inline",
          },
        },
      }}
    >
      <TableCell sx={{ minWidth: 250 }}>
        <HubHookFormSelect
          name={`${preset}.routingType`}
          defaultValue={post?.routingType}
          options={POSTS}
          required
        />
      </TableCell>
      <TableCell sx={{ minWidth: 50 }}>
        <HubHookFormTimePicker
          name={`${preset}.leadTime`}
          defaultValue={post?.leadTime || 1440}
          placeholder="124"
          onlyDays
          required
        />
      </TableCell>
      <TableCell sx={{ minWidth: 50 }}>
        <HubHookFormTimePicker
          name={`${preset}.cycleTime`}
          defaultValue={post?.cycleTime || "0"}
          placeholder="0.12"
          required
        />
      </TableCell>
      <TableCell sx={{ minWidth: 150 }}>
        <HubHookFormInput
          name={`${preset}.notes`}
          defaultValue={post?.notes}
        />
      </TableCell>
      <TableCell padding="checkbox">
        <HubButton
          className="DipRowButton"
          icon="Trash2"
          variant="text"
          iconProps={{ size: 14 }}
          sx={{ color: "hubColors.redMain" }}
          onClick={handleRemove}
        />
      </TableCell>
    </TableRow>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  _removePartPost: removePartPost,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostFoundryTableRow);
