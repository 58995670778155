import { styled } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";

export const StyledAccordion = styled(Accordion, {
  shouldForwardProp: (prop) => !["isOpen"].includes(prop),
})(({ theme, isOpen }) => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  "& .MuiAccordionSummary-root": {
    flexDirection: "row",
    gap: "5px",
    border: "1px solid",
    borderColor: theme.palette.hubColors.greyLight,
    borderRadius: "7px",
    minHeight: "min-content",
    padding: "15px 25px 15px 15px",
    marginBottom: isOpen ? "15px" : "0px",
    transition: "margin-bottom 80ms",
    transitionDelay: isOpen ? "0s" : "260ms",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
  "& .MuiAccordionDetails-root ": {
    padding: "5px 0",
  },
  //
}));
