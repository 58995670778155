import React from 'react'
import { HubCardStack } from '../../components'

export const WorkOrderProd = () => {
  return (
    <HubCardStack margin="15px 0px 15px 15px">

    </HubCardStack>
  )
}

export default WorkOrderProd