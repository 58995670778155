import React from "react";
import { shiftHue } from "../helpers/color";

export const UserImage = ({ fill, shift, ...rest }) => {
  const color = shift ? shiftHue("#A4DE59", shift / 100) : fill || "#A4DE59";
  return (
    <svg
      width="154"
      height="154"
      viewBox="0 0 154 154"
      fill="none"
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="154" height="154" rx="0" fill={color} />
      <path
        opacity="0.92"
        d="M77.1667 96.3333C95.4841 96.3333 110.333 81.4841 110.333 63.1667C110.333 44.8492 95.4841 30 77.1667 30C58.8492 30 44 44.8492 44 63.1667C44 81.4841 58.8492 96.3333 77.1667 96.3333Z"
        fill="white"
      />
      <path
        opacity="0.92"
        d="M143.667 154V137.167C143.667 128.37 140.172 119.934 133.952 113.714C127.732 107.494 119.296 104 110.5 104H44.1667C35.3703 104 26.9342 107.494 20.7143 113.714C14.4943 119.934 11 128.37 11 137.167V154"
        fill="white"
      />
    </svg>
  );
};
