let config = {};

const env = process.env.REACT_APP_ENV || "development";
console.log("@@@@@@ loading config for env:", env);

switch (env) {
  case "test":
    config = {
      apiHost: "http://localhost:8082",
      endpoints: {},
    };
    break;

  case "development":
    config = {
      apiHost: "http://localhost:8082",
      endpoints: {},
    };
    break;

  case "staging":
    config = {
      apiHost: "https://api.dev.ipcfoundrygroup.com",
      endpoints: {},
    };
    break;

  case "production":
    config = {
      apiHost: "https://api.ipcfoundrygroup.com",
      endpoints: {},
    };
    break;
  default:
    throw new Error(
      `Missing or unsupported REACT_APP_ENV enviroment variable: ${env}`
    );
}

export default config;
