import React from "react";
import { TableContainer, Table } from "@mui/material";

import OrderLineItemsHeader from "./OrderLineItemsHeader";
import OrderLineItemsBody from "./OrderLineItemsBody";

export const CreateOrderTable = () => {
  return (
    <TableContainer>
      <Table id="CustomerOrdersTable">
        <OrderLineItemsHeader />
        <OrderLineItemsBody />
      </Table>
    </TableContainer>
  );
};

export default CreateOrderTable;
