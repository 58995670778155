import React from "react";
import { connect } from "react-redux";

import { HubPagination } from "../../components";
import { getOrdersMeta } from "../../redux/orders";

const JobsTablePagination = ({ meta }) => {
  return <HubPagination {...meta} />;
};

const mapStateToProps = (state) => ({
  meta: getOrdersMeta(state),
});

export default connect(mapStateToProps)(JobsTablePagination);
