import React from "react";

import { StyledStack } from "./styles.js";
import HubCircularProgress from "../HubCircularProgress/HubCircularProgress.jsx";

export const PageContent = ({
  disablePadding,
  smallPadding,
  mediumPadding,
  isLoading,
  children,
  hideOverflow = false,
  ...rest
}) => {
  let padding = "35px";
  if (disablePadding) padding = "0px";
  else if (smallPadding) padding = "15px";
  else if (mediumPadding) padding = "25px";

  const sx = {
    padding,
    ...(hideOverflow ? {} : { overflow: "hidden" }),
  };

  return (
    <StyledStack {...sx} {...rest}>
      {isLoading ? <HubCircularProgress centerScreen /> : children}
    </StyledStack>
  );
};
