import React from "react";
import { Avatar, Stack, Typography } from "@mui/material";
import { ParentStack } from "./styles";
import { UserImage } from "../../svgs";
import { HubIcon } from "../HubIcon";
import { shiftHue } from "../../helpers/color";

const AvatarSize = 40;

export const UserDetailIcon = ({
  size = AvatarSize,
  user = {},
  sx = {},
  includeChevron = false,
  includeDetails = true,
  includeEmail = true,
  includeName = true,
  includeThumbnail = true,
  includeInital = false,
  variant="circle",
  ...rest
}) => {

  return (
    <ParentStack gap="10px" sx={{ cursor: "pointer", ...sx }} {...rest}>
      {includeThumbnail && (
        <Avatar sx={{ width: size, height: size }} variant={variant}>
          <UserImage width={size} height={size} shift={user?.hueShift || 0} />
        </Avatar>
      )}
      {includeInital && user?.name && (
        <Avatar
          sx={{
            width: size,
            height: size,
            fontSize: 12,
            fontWeight: 500,
            backgroundColor: shiftHue("#A4DE59", (user?.hueShift || 100) / 100, null, 0.75),
          }}
        >
          {user?.name.split(" ")?.map((n => n?.length ? n[0] : "")).slice(0, 2).join("")}
        </Avatar>
      )}
      {includeDetails && (
        <Stack alignItems="start" gap="0px">
          {includeName && (
            <Typography variant="darkBold12">{user?.name}</Typography>
          )}
          {includeEmail && (
            <Typography variant="caption11">{user?.email}</Typography>
          )}
        </Stack>
      )}
      {includeChevron && <HubIcon icon="ChevronDown" />}
    </ParentStack>
  );
};

export default UserDetailIcon;
