import React, { useEffect, useState } from "react";
import { Table, TableContainer } from "@mui/material";

import { useDeleteCustomerById } from "../../hooks";
import { WarningDeleteDialog } from "../../dialogs";
import CompaniesHeader from "./CompaniesHeader";
import CompaniesBody from "./CompaniesBody";
import CompaniesPagination from "./CompaniesPagination";

export const CompaniesTable = () => {
  const [deletingId, setDeletingId] = useState(false);
  const { mutate: deleteCustomerById, isSuccess } = useDeleteCustomerById();

  const handleClose = () => setDeletingId(false);
  const handleSubmit = () => deletingId && deleteCustomerById(deletingId);

  useEffect(() => {
    isSuccess && handleClose();
  }, [isSuccess]);

  return (
    <>
      <TableContainer>
        <Table id="CompaniesTable">
          <CompaniesHeader />
          <CompaniesBody setDeletingId={setDeletingId} />
        </Table>
      </TableContainer>
      <CompaniesPagination />
      <WarningDeleteDialog
        isOpen={!!deletingId}
        onClose={handleClose}
        onSubmit={handleSubmit}
        title="You are about to delete this customer!"
      />
    </>
  );
};

export default CompaniesTable;
