/* eslint-disable */
function hexToRGB(hex) {
  const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return rgb
    ? {
        r: parseInt(rgb[1], 16),
        g: parseInt(rgb[2], 16),
        b: parseInt(rgb[3], 16),
      }
    : null;
}

function rgbToHSL(r, g, b) {
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }
  return { h, s, l };
}

export const hslToRGB = (h, s, l, asString) => {
  function hueToRGB(p, q, t) {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
  }

  if (s === 0) {
    const r = l * 255;
    return { r, g: r, b: r };
  }

  const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
  const p = 2 * l - q;
  const r = hueToRGB(p, q, h + 1 / 3) * 255;
  const g = hueToRGB(p, q, h) * 255;
  const b = hueToRGB(p, q, h - 1 / 3) * 255;

  return asString ? `rgb(${r},${g},${b})` : { r, g, b };
};

//0.825

export const shiftHue = (hexColor, factor = 0.35, so, lo) => {
  const { r, g, b } = hexToRGB(hexColor);
  const { h, s, l } = rgbToHSL(r, g, b);
  const newH = (h + factor) % 1;
  const newS = so || s;
  const newL = lo || l;

  const { r: newR, g: newG, b: newB } = hslToRGB(newH, newS, newL);

  const newColor = `rgb(${Math.round(newR)}, ${Math.round(newG)}, ${Math.round(
    newB
  )})`;

  return newColor;
};
