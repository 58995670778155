import PartAssembly from "./forms/PartAssembly";
import PartDipDetails from "./forms/PartDipDetails";
import PartWaxDetails from "./forms/PartWaxDetails";
import PartFoundry from "./forms/PartFoundry";
import PartPostFoundry from "./forms/PartPostFoundry";
import PartOutsideProcessing from "./forms/PartOutsideProcessing";

export const PART_SECTIONS = {
  wax: <PartWaxDetails />,
  "assembly&autoclave": <PartAssembly />,
  dip: <PartDipDetails />,
  foundry: <PartFoundry />,
  postfoundry: <PartPostFoundry />,
  outsideprocessing: <PartOutsideProcessing />,
};
