import React from "react";
// sx={{ cursor: 'pointer', color: value >= starId ? '#FFCB46' : '#D9D9D9' }}
export const Star = ({ ...rest }) => {
  return (
    <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M11 1L14.09 7.26L21 8.27L16 13.14L17.18 20.02L11 16.77L4.82 20.02L6 13.14L1 8.27L7.91 7.26L11 1Z"
        fillRule="evenodd"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
