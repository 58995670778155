import qs from "qs-lite";
import { useQuery } from "react-query";
import api from "../../utils/api";

const TRAVELER = "/traveler";
const JOBS = "/jobs";

export const getTravelerData = (id) => api.get(`${TRAVELER}/${id}`);
export const useGetTravelerData = (id) => {
  return useQuery([TRAVELER, id], () => getTravelerData(id), {
    enabled: !!id,
  });
};

const getJobDashboard = ({ queryKey }) => {
  const queryString = qs.stringify(queryKey[1]);
  return api.get(`${JOBS}/dashboard?${queryString}`);
};

export const useGetJobDashboard = ({ per, start, end, defaultLocation }) => {
  const query = new URLSearchParams(window.location.search);

  const queryLocation = query.get("location");
  const location = queryLocation === "All" ? "" : queryLocation|| defaultLocation;
  const period = query.get("period") || per;
  const startDate = query.get("startDate") || start;
  const endDate = query.get("endDate") || end;

  const queryProps = {
    ...(location ? { location } : {}),
    period,
    startDate,
    endDate,
  };

  return useQuery([JOBS, { ...queryProps }], getJobDashboard, {
    enabled: !!defaultLocation,
  });
};

const getAllJobData = ({ queryKey }) => {
  const queryString = qs.stringify(queryKey[1]);
  return api.get(`${JOBS}?${queryString}`);
};
export const useGetAllJobData = ({ per, start, end, defaultLocation }) => {
  const query = new URLSearchParams(window.location.search);

  const queryLocation = query.get("location");
  const location = queryLocation === "All" ? "" : queryLocation|| defaultLocation;
  const status = query.get("status");

  const orderBy = query.get("orderBy") || "workOrder";
  const sortOrder = query.get("sortOrder") || "desc";
  const limit = query.get("limit") || 100;
  const offset = query.get("offset") || 0;

  const period = query.get("period") || per;
  const startDate = query.get("startDate") || start;
  const endDate = query.get("endDate") || end;

  const queryProps = {
    ...(location ? { location } : {}),
    ...(status != "All" && status ? { status } : {}),
    limit,
    offset,
    orderBy,
    sortOrder,
    period,
    startDate,
    endDate,
  };

  return useQuery([JOBS, { ...queryProps }], getAllJobData, {
    enabled: !!defaultLocation,
  });
};

export const getOrderJobs = (id) => api.get(`${JOBS}/order/${id}`);
export const useGetOrderJobs = (id, isOpen) => {
  return useQuery([`${JOBS}/order/${id}`], () => getOrderJobs(id), {
    enabled: !isNaN(id) && isOpen,
  });
};

export const getWorkOrderData = (id) => api.get(`${JOBS}/${id}`);
export const useGetWorkOrderData = (id) => {
  return useQuery([JOBS, id], () => getWorkOrderData(id), {
    enabled: !!id,
  });
};
