export const DIP_INGREDIENTS = [
  {
    id: 71,
    label: "FS 50/100",
  },
  {
    id: 72,
    label: "FS 30/50",
  },
];
