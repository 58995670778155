import React from "react";
import { Table, TableContainer } from "@mui/material";

import PartsTableHeader from "./PartsTableHeader";
import PartsTableBody from "./PartsTableBody";
import PartsTablePagination from "./PartsTablePagination";

export const PartsTable = () => {
  return (
    <>
      <TableContainer>
        <Table id="PartsTable">
          <PartsTableHeader />
          <PartsTableBody />
        </Table>
      </TableContainer>
      <PartsTablePagination />
    </>
  );
};

export default PartsTable;
