import { FOUNDRIES } from "../../constants";

export const formOrderMapper = (
  id,
  fd = {},
  customer = {},
  parts = [],
  num = 1
) => {
  const items = [];

  const location =
    fd?.location || FOUNDRIES?.find((f) => customer?.location === f?.label)?.id;

  while (num >= 0) {
    const partId = fd[`part${num}`];
    const price = fd[`price${num}`];
    const qtyReq = fd[`qty${num}`];

    const part = parts.find((p) => p.id === partId);

    const margin = price
      ? (price * part?.margin) / part?.lastSentPrice
      : part?.margin;

    const trees = Math.ceil(qtyReq / part?.partsPerTree);
    const patReq = trees * part?.partsPerTree;

    const itemId = fd[`id${num}`];

    items.push({
      id: itemId ? parseInt(itemId) : null,
      lineItem: fd[`line${num}`],
      partId,
      price,
      margin,
      qtyReq,
      trees,
      patReq,
      woNumber: fd[`workOrder${num}`],
      lotNum: fd[`lotNumber${num}`],
      startDate: new Date(fd[`start${num}`]).toISOString(),
      dueDate: new Date(fd[`due${num}`]).toISOString(),
    });
    num--;
  }

  const payload = {
    id,
    companyId: customer?.id,
    location,
    customerPO: fd?.orderNumber,
    overShippingAllowed: fd?.overShipping,
    shipVia: fd?.shipVia,
    shipTo: fd?.shipTo,
    freight: fd?.freight,
    items,
  };

  return payload;
};
