import React from "react";
import { StyledGridStack } from "./styles";
import { HubHookFormInput, HubHookFormSelect } from "../../components";
import { PERIOD_OPTIONS } from "../../constants";

const RepeatWorkOrderForm = () => {
  return (
    <StyledGridStack>
      <HubHookFormSelect
        name="freq"
        label="Frequency"
        options={PERIOD_OPTIONS}
        defaultValue="weekly"
        disableHelperText
        required
      />
      <HubHookFormInput
        name="occ"
        label="Occurances"
        placeholder="10"
        defaultValue={5}
        disableHelperText
        isNumber
        required
      />
    </StyledGridStack>
  );
};

export default RepeatWorkOrderForm;
