import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";

import { HubButtonGroup, HubCardStack, HubTabs } from "../../components";
import { JobsTable, PurchaseOrdersTable } from "../../tables";

import JobsFilters from "../../tables/JobsTable/JobsFilters";
import { useSearchParams } from "react-router-dom";

const tabs = [
  {
    id: 0,
    label: "Work Orders",
  },
  {
    id: 1,
    label: "Purchase Orders",
  },
];

const OrdersTableSwitch = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(tabs[0].id);
  const props = { value, setValue, tabs };

  useEffect(() => {
    searchParams.delete("orderBy");
    searchParams.delete("sortOrder");
    setSearchParams(searchParams);
  }, [value]);

  return (
    <HubCardStack gap="15px" overflow="hidden" position="relative">
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        gap="15px"
      >
        <HubTabs {...props} borderless />
        <JobsFilters />
      </Stack>
      {value ? <PurchaseOrdersTable /> : <JobsTable />}
    </HubCardStack>
  );
};

export default OrdersTableSwitch;
