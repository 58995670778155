import React, { useEffect } from "react";
import { Stack } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { connect } from "react-redux";

import {
  PERIODS,
  PERIOD_DEFAULTS,
  validatePeriodPayload,
} from "../../constants";
import { HubButton, HubChartLabel, HubContextMenu } from "../../components";
import {
  getOrdersEndDate,
  getOrdersPeriod,
  getOrdersStartDate,
  initOrdersPeriod,
} from "../../redux";

import JobsFilterMenu from "./JobsFilterMenu";

const LabelProps = {
  LabelProps: { variant: "caption" },
  StackProps: { gap: "5px" },
};

const MenuProps = {
  sx: { boxShadow: "0px 4px 12px rgba(0,0,0,0.3) !important" },
};

const JobsChartTitleBar = ({
  period = "",
  startDate = "",
  endDate = "",
  _initOrdersPeriod = () => {},
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const data = {
      period: searchParams.get("period"),
      startDate: searchParams.get("startDate"),
      endDate: searchParams.get("endDate"),
    };

    const isBlank = !data.period && !data.startDate && !data.endDate;
    const isSame =
      period === data.period &&
      startDate === data.startDate &&
      endDate === data.endDate;
    
      if (!(isBlank || isSame)) {
      const payload = validatePeriodPayload(data);
      _initOrdersPeriod(payload);
    }
  }, []);

  useEffect(() => {
    searchParams.set("period", period);
    searchParams.set("startDate", startDate);
    searchParams.set("endDate", endDate);

    setSearchParams(searchParams);
  }, [period, startDate, endDate]);

  const FilterButton = (
    <HubButton
      variant="tinyButton"
      label="Edit Selection"
      color="neutral"
      sx={{ textWrap: "nowrap" }}
    />
  );
  return (
    <Stack
      direction="row"
      width="100%"
      justifyContent="space-between"
      padding="0px 25px 0 15px"
    >
      <Stack direction="row" gap="5px">
        <HubContextMenu
          menuButton={FilterButton}
          IconButtonProps={{ sx: { padding: 0, minHeight: 0 } }}
          MenuProps={MenuProps}
        >
          <JobsFilterMenu />
        </HubContextMenu>
      </Stack>

      <Stack direction="row" gap="15px">
        <HubChartLabel color="#CD683E" label="Revenue" {...LabelProps} />
        <HubChartLabel color="#DF9E44" label="Hours" {...LabelProps} />
      </Stack>
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  period: getOrdersPeriod(state),
  startDate: getOrdersStartDate(state),
  endDate: getOrdersEndDate(state),
});
const mapDispatchToProps = {
  _initOrdersPeriod: initOrdersPeriod,
};
export default connect(mapStateToProps, mapDispatchToProps)(JobsChartTitleBar);
