import React from "react";
import { Divider, Stack } from "@mui/material";
import { connect } from "react-redux";

import { updateCustomerAddress } from "../../redux";
import { HubHookFormInput, HubHookFormSwitch } from "../../components";

const CustomerAddress = ({
  idx = 0,
  address = {},
  disabled = false,
  _updateCustomerAddress = () => {},
}) => {
  const updateLineItemAttr = (key, value) => {
    const data = address;
    data[key] = value;

    _updateCustomerAddress(idx, data);
  };

  return (
    <Stack gap="15px">
      <HubHookFormInput
        name={"addressName" + idx}
        label="Address Name"
        placeholder="ex. IPC Building A"
        defaultValue={address?.name}
        onBlur={(e, value) => updateLineItemAttr("name", value)}
        disabled={disabled}
        disableHelperText
      />
      <HubHookFormInput
        name={"address" + idx}
        label="Address"
        placeholder="ex. 115 W 460 N"
        defaultValue={address?.street}
        onBlur={(e, value) => updateLineItemAttr("street", value)}
        disabled={disabled}
        disableHelperText
        required
      />
      <HubHookFormInput
        name={"unit" + idx}
        label="Address 2"
        placeholder="ex. Unit 115"
        defaultValue={address?.unit}
        onBlur={(e, value) => updateLineItemAttr("unit", value)}
        disabled={disabled}
        disableHelperText
      />
      <Stack direction="row" gap="10px">
        <HubHookFormInput
          name={"city" + idx}
          label="City"
          placeholder="ex. Lindon"
          defaultValue={address?.city}
          onBlur={(e, value) => updateLineItemAttr("city", value)}
          disabled={disabled}
          disableHelperText
          required
        />
        <HubHookFormInput
          name={"state" + idx}
          label="State"
          placeholder="ex. UT"
          defaultValue={address?.state}
          onBlur={(e, value) => updateLineItemAttr("state", value)}
          disabled={disabled}
          disableHelperText
          required
        />
      </Stack>
      <Stack direction="row" gap="10px">
        <HubHookFormInput
          name={"country" + idx}
          label="Country"
          placeholder="ex. United States of America"
          sx={{ flexGrow: "1" }}
          defaultValue={address?.country}
          onBlur={(e, value) => updateLineItemAttr("country", value)}
          disabled={disabled}
          disableHelperText
          required
        />
        <HubHookFormInput
          name={"zip" + idx}
          label="Zip Code"
          placeholder="84056"
          sx={{ maxWidth: "125px" }}
          fullWidth={false}
          defaultValue={address?.zip}
          onBlur={(e, value) => updateLineItemAttr("zip", value)}
          disabled={disabled}
          disableHelperText
          required
        />
      </Stack>
      <Stack direction="row" gap="10px">
        <HubHookFormSwitch
          name={"billing" + idx}
          label="Use as Billing"
          labelPlacement="end"
          defaultValue={address.billing}
          onChange={(value) => updateLineItemAttr("billing", value)}
          disabled={disabled}
          isCheckbox
        />
        <HubHookFormSwitch
          name={"shipping" + idx}
          label="Use as Shipping"
          labelPlacement="end"
          defaultValue={address.shipping}
          onChange={(value) => updateLineItemAttr("shipping", value)}
          disabled={disabled}
          isCheckbox
        />
      </Stack>
      <Divider orientation="horizontal" flexItem sx={{ marginTop: "10px" }} />
    </Stack>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  _updateCustomerAddress: updateCustomerAddress,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerAddress);
