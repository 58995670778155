import React, { forwardRef, useCallback } from "react";
import PropTypes from "prop-types";

import { useSnackbar, SnackbarContent } from "notistack";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { Stack } from "@mui/material";

import { HubIcon } from "../HubIcon";
import { HubButton } from "../inputs";
import { StyledStack } from "./styles";

const IconMapLabel = {
  error: "XCircle",
  success: "CheckCircle",
  info: "Info",
  warning: "AlertTriangle",
};

const ColorMap = {
  error: "redMain",
  success: "greenMain",
  info: "blueMain",
  warning: "yellowMain",
};

const titlesx = { fontWeight: 600, fontSize: 14, lineHeight: 1 };
const subsx = { lineHeight: 1, fontSize: 14 };

export const HubSnackBar = forwardRef(
  (
    { id, message = "", title = "", severity = "", disableClose = false },
    ref
  ) => {
    const { closeSnackbar } = useSnackbar();

    const onClick = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    const color = ColorMap[severity];
    const cardsx = {
      color: "hubColors." + color,
      backgroundColor: "hubColors.white",
      border: "none",
      boxShadow: "0 0 15px rgba(0, 0, 0, 0.4)",
    };

    return (
      <SnackbarContent ref={ref}>
        <Card sx={cardsx}>
          <StyledStack color={color}>
            <HubIcon icon={IconMapLabel[severity]} size="14px" />
            <Stack gap="5px">
              {title && <Typography sx={titlesx}>{title}</Typography>}
              <Typography sx={subsx}>{message}</Typography>
            </Stack>
            {!disableClose && (
              <HubButton
                iconProps={{ size: 12 }}
                icon="X"
                onClick={onClick}
                sx={{ height: "fit-content" }}
                variant="text"
                color="neutral"
              />
            )}
          </StyledStack>
        </Card>
      </SnackbarContent>
    );
  }
);

HubSnackBar.propTypes = {
  id: PropTypes.number,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.string,
  disableClose: PropTypes.bool,
  severity: PropTypes.oneOf(["error", "success", "warning", "info"]),
};

// react will show displayName in console if errors occur in component
HubSnackBar.displayName = "HubSnackBar";

export default HubSnackBar;
