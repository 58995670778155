import { createSelector } from "reselect";
import { createAction } from "redux-api-middleware";
import config from "../config/config";

import { generateUniqueRandomString } from "../helpers/string";

const ADD_LINE_ITEM = "ADD_LINE_ITEM";
const UPDATE_LINE_ITEM = "UPDATE_LINE_ITEM";
const REMOVE_LINE_ITEM = "REMOVE_LINE_ITEM";
const UPDATE_ORDER_DATA = "UPDATE_ORDER_DATA";

const UPDATE_JOB_PART = "UPDATE_JOB_PART";

const SET_LOADING = "SET_LOADING";
const SET_ERROR = "SET_ERROR";

const CLEAR_PURCHASE_ORDER = "CLEAR_PURCHASE_ORDER";

const itemTemplate = {
  line: "1",
  partId: 0,
  overrideWo: "",
  workOrder: "",
  price: 0.0,
  waxHours: 0,
  trees: 0,
  patReq: 0,
  qtyRequired: 0,
  quantity: 0,
};

const initialState = {
  items: [itemTemplate],
  startWorkOrder: "",
  poNumber: "PO-" + generateUniqueRandomString()?.toUpperCase(),
  location: null,
  overShipping: true,
  isUpdating: false,
  shipVia: 1,
  shipTo: null,
  freight: "FOB Lindon",
};

export const fetchPartJobData = (partId, idx) => {
  const endpoint = `${config?.apiHost}/order/part/${partId}`;

  return createAction({
    endpoint,
    method: "GET",
    credentials: "include",
    types: [
      SET_LOADING,
      {
        type: UPDATE_JOB_PART,
        meta: { idx },
        payload: (action, state, res) => {
          const contentType = res?.headers?.get("Content-Type");
          if (contentType?.indexOf("json")) {
            return res.json();
          }
        },
      },
      SET_ERROR,
    ],
  });
};

const findItemByIndex = ({ items = [], idx }) => items.length >= idx ? idx : -1;

const updateItemHelper = ({ idx, items, data = {}, startWorkOrder }) => {
  const index = findItemByIndex({ items, idx });

  if (index === -1) return items;

  const updatedItem = {
    ...items[index],
    ...data,
  };

  const newItems = [
    ...items.slice(0, index),
    updatedItem,
    ...items.slice(index + 1),
  ];

  return newItems?.map((item, index) => ({
    ...item,
    workOrder: startWorkOrder + index,
  }));
};

const updateJobHelper = ({ idx, payload, items = [], startWorkOrder }) => {
  const index = findItemByIndex({ items, idx });
  if (index === -1) return items;

  const data = {
    ...items[index],
    lotNumber: payload?.lotNumber,
  };

  return updateItemHelper({
    idx: idx,
    items,
    data,
    startWorkOrder,
  });
};

const removeItemHelper = ({ idx, items, startWorkOrder }) => {
  const index = findItemByIndex({ items, idx });

  if (index === -1) return items;

  const newItems = [...items.slice(0, index), ...items.slice(index + 1)];

  return newItems?.map((item, index) => ({
    ...item,
    line: `${index + 1}`,
    workOrder: startWorkOrder + index,
  }));
};

const addItemHelper = ({ items, startWorkOrder }) => {
  const newItem = {
    ...itemTemplate,
  };

  const newItems = [...items, newItem];

  return newItems?.map((item, index) => ({
    ...item,
    line: `${index + 1}`,
    workOrder: startWorkOrder + index,
  }));
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "FLUSH":
    case CLEAR_PURCHASE_ORDER: {
      return initialState;
    }

    case ADD_LINE_ITEM: {
      const newState = {
        ...state,
        items: addItemHelper({
          items: state?.items,
          startWorkOrder: state?.startWorkOrder,
        }),
      };
      return newState;
    }

    case UPDATE_LINE_ITEM: {
      const newState = {
        ...state,
        items: updateItemHelper({
          idx: action.idx,
          data: action.data,
          items: state.items,
          startWorkOrder: state?.startWorkOrder,
        }),
      };
      return newState;
    }

    case UPDATE_JOB_PART: {
      const newState = {
        ...state,
        startWorkOrder: action?.payload?.woNumber,
        items: updateJobHelper({
          idx: action.meta.idx,
          payload: action.payload,
          items: state.items,
          startWorkOrder: state?.startWorkOrder || action?.payload?.woNumber,
        }),
      };
      return newState;
    }

    case REMOVE_LINE_ITEM: {
      const newState = {
        ...state,
        items: removeItemHelper({
          idx: action.idx,
          items: state.items,
          startWorkOrder: state?.startWorkOrder,
        }),
      };
      return newState;
    }

    case UPDATE_ORDER_DATA: {
      const newState = state;
      newState[action.key] = action.value;
      return newState;
    }

    case SET_LOADING:
    case SET_ERROR:
    default:
      return state;
  }
}

export function flushPurchaseOrder() {
  return {
    type: CLEAR_PURCHASE_ORDER,
  };
}

export function addPoLineItem() {
  return {
    type: ADD_LINE_ITEM,
  };
}

export function removePoLineItem(idx) {
  return {
    type: REMOVE_LINE_ITEM,
    idx,
  };
}

export function updatePoLineItem(idx, data) {
  return {
    type: UPDATE_LINE_ITEM,
    idx,
    data,
  };
}

export function updateOrderData(key, value) {
  return {
    type: UPDATE_ORDER_DATA,
    key,
    value,
  };
}

export const getPurchaseOrdersState = createSelector(
  (state) => state,
  (state) => state?.purchaseOrderData
);

export const getPoIsUpdate = createSelector(
  getPurchaseOrdersState,
  (state) => state.isUpdating || false
);

export const getPurchaseOrderItems = createSelector(
  getPurchaseOrdersState,
  (poState) => poState?.items || []
);

export const getTotalPurchaseOrderCost = createSelector(
  getPurchaseOrderItems,
  (poItems) => {
    const total =
      poItems?.reduce(
        (total, curr) =>
          total + curr.quantity * (curr?.price || curr?.part?.lastSentPrice),
        0
      ) || 0.0;
    return Math.round(total * 100) / 100;
  }
);

export const getPurchasseOrderAverageMargin = createSelector(
  getPurchaseOrderItems,
  (poItems) => {
    const total =
      poItems?.reduce((total, curr) => {
        const margin = curr?.price
          ? (curr?.price * curr?.part?.margin) / curr?.part?.lastSentPrice
          : curr?.part?.margin;
        return total + margin * curr?.quantity;
      }, 0) || 0;
    const quantity =
      poItems?.reduce((total, curr) => total + curr?.quantity, 0) || 0;
    const average = quantity ? total / quantity : 0.0;
    return Math.round(average * 10000) / 100;
  }
);

export const getPurchaseOrderDetails = createSelector(
  getPurchaseOrdersState,
  (poState) => ({
    poNumber: poState.poNumber,
    location: poState.location,
    overShipping: poState.overShipping,
    id: poState.id,
    freight: poState?.freight,
    shipTo: poState?.shipTo,
    shipVia: poState?.shipVia
  })
);
