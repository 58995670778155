// import PartQuoting from "./forms/PartQuoting";
import PartRouting from "./forms/PartRouting";

export const tabs = [
  // {
  //   id: "QUOTE",
  //   icon: "DollarSign",
  //   label: "Quoting",
  // },
  {
    id: "ROUTING",
    icon: "Settings",
    label: "Routing",
  },
  {
    id: "WORKS",
    icon: "Layout",
    label: "Work Orders",
  },
  // {
  //   id: "HISTORY",
  //   icon: "Calendar",
  //   label: "History and Files",
  // },
];

export const PART_SECTIONS = {
  ROUTING: <PartRouting />,
  QUOTE: <></>,
  HISTORY: <></>,
};

export const UpdateMapper = (formData, id) => {
  return {
    id,
    ...formData,
    ...(formData?.dip
      ? {
          dip: {
            ...formData?.dip,
            dips: Object.values(formData?.dip?.dips || []),
          },
        }
      : {}),
    ...(formData?.postFoundry
      ? {
          postFoundry: {
            ...formData?.postFoundry,
            routes: Object.values(formData?.postFoundry?.routes || []),
          },
        }
      : {}),
    ...(formData?.outside
      ? {
          outside: {
            ...formData?.outside,
            routes: Object.values(formData?.outside?.routes || []),
          },
        }
      : {}),
  };
};
