import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { HubButton } from "../inputs";
import { HubHookFormSwitch } from "../HubHookFormSwitch";
import { HubContextMenu } from "../HubContextMenu";

import { StyledTypography, StyledStack } from "./styles";

export const HubFilterMenu = ({
  id = "filter",
  options = [],
  menuLabel = "",
  label = "",
  joinBy = "-",
  multiSelect = true,
  onChange = () => {},
  useDefault = true,
  defaultValue,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const property = query.get(id);

  const first = multiSelect ? [options[0]?.id] : options[0]?.id;
  const startValue = defaultValue ? defaultValue : first;
  const [filters, setFilters] = useState(startValue);

  useEffect(() => {
    if (!useDefault) return;
    if (multiSelect) {
      if (property === "All") {
        const filtered = options?.map((l) => l?.id);
        setFilters(filtered);
      } else {
        const data = property
          ? property?.split(joinBy)
          : defaultValue
          ? defaultValue
          : [options[0]?.id];
        const filtered = data?.filter(
          (i) => !!options?.find((l) => l?.id === i)
        );
        setFilters(filtered);
      }
    } else {
      const selected =
        property || (defaultValue ? defaultValue : options[0]?.id);
      setFilters(selected);
    }
  }, []);

  useEffect(() => {
    if (filters.length) {
      if (filters.length === options.length) {
        query.set(id, "All");
      } else {
        multiSelect
          ? query.set(id, filters?.join(joinBy))
          : query.set(id, filters);
      }
    } else {
      query.delete(id);
    }

    navigate({
      pathname: location.pathname,
      search: query.toString(),
    });

    onChange();
  }, [filters]);

  const handleSwitchClick = (id) => {
    if (multiSelect) {
      filters?.includes(id)
        ? setFilters(filters?.filter((f) => f !== id))
        : setFilters([...filters, id]);
    } else {
      filters === id ? setFilters() : setFilters(id);
    }
  };

  const handleOnlyClick = (id) => {
    setFilters(multiSelect ? [id] : id);
  };

  const MenuOptions = (
    <Stack gap="10px" padding="15px">
      <Typography variant="caption">{menuLabel}</Typography>
      {options?.map((l) => (
        <StyledStack key={l?.id}>
          <HubHookFormSwitch
            key={l?.id}
            onChange={() => handleSwitchClick(l?.id)}
            name={l?.label}
            label={l?.label}
            labelPlacement="end"
            fullWidth={false}
            defaultValue={filters?.includes(l?.id)}
            isCheckbox
          />
          {multiSelect && (
            <StyledTypography
              id="OnlyButton"
              onClick={() => handleOnlyClick(l?.id)}
            >
              Only
            </StyledTypography>
          )}
        </StyledStack>
      ))}
    </Stack>
  );

  const length = filters?.length ? `(${filters?.length})` : "";
  const num = filters ? "(1)" : "";
  const filterNum = multiSelect ? length : num;

  const MenuButton = (
    <HubButton
      variant="tinyButton"
      label={`${label} ${filterNum}`}
      color="neutral"
      // icon="ChevronDown"
      // sx={{ ".MuiStack-root": { flexDirection: "row-reverse" } }}
    />
  );
  return (
    <HubContextMenu
      id={`filter-${id}`}
      menuButton={MenuButton}
      IconButtonProps={{ sx: { padding: 0, minHeight: 0 } }}
    >
      {MenuOptions}
    </HubContextMenu>
  );
};

export default HubFilterMenu;
