import React from "react";
import { Typography } from "@mui/material";

import { getDollarValue } from "../../helpers/currency";
import { StyledDataStack } from "./styles";

const sx = {
  fontSize: 12,
  color: "hubColors.greyDark",
};

const valueSx = {
  fontSize: 16,
  fontWeight: 800,
};

const formatHours = (value) => `${(value / 100).toFixed(2)} hrs`;
const JobsBarTooltipDataPoint = ({ value, goal, isMoney }) => {
  const multiplier = isMoney ? 100 : 1;
  const comp = (value * goal) / multiplier;
  const formattedValue = isMoney ? getDollarValue(comp) : formatHours(comp);
  const formattedGoal = isMoney ? getDollarValue(goal) : `${goal} hrs`;

  return (
    <StyledDataStack>
      <Typography sx={valueSx}>{formattedValue}</Typography>
      <Typography sx={sx}>/ {formattedGoal}</Typography>
    </StyledDataStack>
  );
};

export default JobsBarTooltipDataPoint;
