import React from "react";
import { Stack, Typography } from "@mui/material";
import { HubHookFormInput } from "../../components";

const CreateContactPart = ({ disabled }) => {
  return (
    <Stack gap="15px" width="100%">
      <Typography variant="boldCaptionMain">Main Contact</Typography>
      <HubHookFormInput
        name="contactName"
        label="Contact Name"
        placeholder="ex. John Barker"
        disabled={disabled}
        disableHelperText
        required
      />
      <Stack direction="row" gap="10px" maxWidth="100%">
        <HubHookFormInput
          name="email"
          label="Contact Email"
          placeholder="ex. john.barker@foundry.com"
          disabled={disabled}
          disableHelperText
          required
          isEmail
        />
        <HubHookFormInput
          name="phone"
          label="Contact Phone"
          placeholder="ex. (800) 000-0000"
          disabled={disabled}
          disableHelperText
          required
        />
      </Stack>
    </Stack>
  );
};

export default CreateContactPart;
