import React from "react";
import { Stack } from "@mui/material";
import { connect } from "react-redux";

import { getCustomerPartsCount } from "../../redux/customerData";
import { CustomerPartsTable } from "../../tables";

import { CreateCustomerPartDialog } from "../../dialogs";
import CustomerPartsBar from "./CustomerPartsBar";
import CustomerPartsEmpty from "./CustomerPartsEmpty";

const CustomerPartsUnwrapped = ({ count }) => {
  return (
    <>
      {count ? (
        <Stack gap="25px">
          <CustomerPartsBar />
          <CustomerPartsTable />
        </Stack>
      ) : (
        <CustomerPartsEmpty />
      )}
      <CreateCustomerPartDialog />
    </>
  );
};

const mapStateToProps = (state) => ({
  count: getCustomerPartsCount(state),
});

export const CustomerParts = connect(mapStateToProps)(CustomerPartsUnwrapped);
