import { formatDateMmDdYyyy } from "../../helpers/dateHelper";

export const onUpdateDateHelper = (value, isStart = false, totalDays = [0]) => {
  let currentDate = new Date(value);

  totalDays.forEach((daysToAdd) => {
    const startAdd = isStart ? 1 : -1;
    let inDays = daysToAdd * startAdd;
    // Use plus one to check if next date is a weekend
    let date = new Date(currentDate.setDate(currentDate.getDate() + startAdd));
    let it = Math.abs(inDays);

    while (it > 0) {
      let adjustWeekend = false;
      // Wait till weekend is over
      while (date.getDay() % 6 === 0) {
        currentDate.setDate(currentDate.getDate() + startAdd);
        date = new Date(currentDate);
        adjustWeekend = true;
      }

      // Then add number of days if not a weekend adjust
      if (!adjustWeekend) {
        const setDate = currentDate.setDate(currentDate.getDate() + startAdd);
        currentDate = new Date(setDate);
      }
      it--;
    }
  });

  const second = formatDateMmDdYyyy(currentDate);

  const data = {
    startDate: isStart ? value : second,
    dueDate: isStart ? second : value,
  };

  return data;
};
