import React, { useEffect, useRef, useState } from "react";

import { StyledAnimateStackX, StyledAnimateStackY } from "./styles";

const Directions = {
  horizontal: StyledAnimateStackX,
  vertical: StyledAnimateStackY,
};

export const HubAnimationStack = ({
  reverse = false,
  StackProps = {},
  direction = "horizontal",
  threshold = 0,
  delay = 0,
  children,
  ...rest
}) => {
  const [view, setView] = useState(false);
  const DirectionComponent = Directions[direction] || Directions["horizontal"];

  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) =>
          setView((prevState) => prevState || entry?.isIntersecting)
        ),
      { threshold }
    );

    if (ref.current) observer.observe(ref.current);

    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, [ref, threshold]);

  return (
    <DirectionComponent
      ref={ref}
      delay={delay}
      reverse={reverse}
      view={view}
      {...rest}
      {...StackProps}
    >
      {children}
    </DirectionComponent>
  );
};

export default HubAnimationStack;
