import React, { useEffect } from "react";
import { Stack } from "@mui/material";
import { connect } from "react-redux";

import { HubDialogWrapper } from "../HubDialogWrapper";
import {
  HubCancelSubmitGroup,
  HubDialogContent,
  HubHookFormWrapper,
} from "../../components";
import { usePostUpdateContact, usePutUpdateContact } from "../../hooks";
import { setIsOpenDialog } from "../../redux/dialogs";
import EditCustomerForm from "./EditCustomerForm";

export const EditCustomerContactDialog = ({
  contact = {},
  isUpdate = true,
  customerId,
  _setIsOpenDialog = () => {},
}) => {
  const {
    mutate: putUpdateContact,
    isLoading: isPutLoading,
    isSuccess: isPutSuccess,
  } = usePutUpdateContact();
  const {
    mutate: postUpdateContact,
    isLoading: isPostLoading,
    isSuccess: isPostSuccess,
  } = usePostUpdateContact();

  const isLoading = isPutLoading || isPostLoading;
  const isSuccess = isPutSuccess || isPostSuccess;

  const onSubmit = (formData) => {
    const payload = {
      id: isUpdate ? contact?.id : customerId,
      name: formData.name,
      title: formData.title,
      email: formData.email,
      cellNumber: formData.cellNumber,
      workNumber: formData.workNumber,
      isPrimary: formData.isPrimary,
    };

    isUpdate ? putUpdateContact(payload) : postUpdateContact(payload);
  };

  const name = "edit-contact";

  useEffect(() => {
    isSuccess && _setIsOpenDialog(name, false);
  }, [isSuccess]);

  return (
    <HubDialogWrapper maxWidth="sm" name={name}>
      <HubDialogContent largePadding>
        <HubHookFormWrapper onSubmit={onSubmit}>
          <Stack gap="35px">
            <EditCustomerForm contact={contact} isUpdate={isUpdate} />
            <HubCancelSubmitGroup
              disabled={isLoading}
              isLoading={isLoading}
              label={isUpdate ? "Update Contact" : "Create Contact"}
            />
          </Stack>
        </HubHookFormWrapper>
      </HubDialogContent>
    </HubDialogWrapper>
  );
};

const mapStateToProps = () => {};
const mapDispatchToProps = {
  _setIsOpenDialog: setIsOpenDialog,
};

export const EditCustomerContact = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCustomerContactDialog);

export default EditCustomerContact;
