/**
 * Fetches organization info and user auth token from local storage and builds
 * the headers object to be used to authenticate outgoing request to videopeel-web-api
 * @return {Object} organization-identification (if that exists in local storage as well).
 */
function buildHeaders() {
  const headers = {
    "Content-Type": "application/json",
  };

  return headers;
}

module.exports = { buildHeaders };
