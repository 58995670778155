import React from "react";
import { TableRow, TableCell, TableBody } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { getParts } from "../../redux/partsTable";
import { getDollarValue } from "../../helpers/currency";

const PartsTableBody = ({ parts = [] }) => {
  const navigate = useNavigate();

  const onClick = (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(`/parts/${id}`);
  };

  return (
    <TableBody>
      {parts?.map((p) => (
        <TableRow key={p?.id} onClick={(e) => onClick(e, p?.id)}>
          <TableCell variant="blueBold">
            {p?.partNumber} - {p?.partName}
          </TableCell>
          <TableCell>{getDollarValue(p?.lastSentPrice)}</TableCell>
          <TableCell variant="blueBold">{p?.material?.name}</TableCell>
          <TableCell variant="blueBold">{p?.tree?.name}</TableCell>
          <TableCell>{p?.weight}</TableCell>
          <TableCell>{p?.partsPerTree}</TableCell>
          {/* <TableCell>{p?.handBlasting}</TableCell>
          <TableCell>{p?.gates}</TableCell> */}
          <TableCell padding="checkbox">
            {/* <CompaniesContextMenu id={p?.id} active={p?.active} /> */}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

const mapStateToProps = (state) => ({
  parts: getParts(state),
});
export default connect(mapStateToProps)(PartsTableBody);
