import React, { useEffect, useState } from "react";
import { TableCell, Typography } from "@mui/material";
import { connect } from "react-redux";

import {
  updatePoLineItem,
  fetchPartJobData,
  getPurchaseOrderDetails,
  getPurchaseOrderCustomerParts,
} from "../../redux/editPurchaseOrder";

import { PART_STATUS } from "../../constants/parts";
import { getDollarValue } from "../../helpers/currency";
import { HubHookFormInput, HubHookFormSelect } from "../../components";
import { onUpdateDateHelper } from "./helper";
import { StyledHubHookFormDatePicker, StyledTableRow } from "./styles";

import LineItemContextMenu from "./LineItemContextMenu";
import OrderLineItemChip from "./OrderLineItemChip";
import OrderSublineRow from "./OrderSublineRow";

const OrderLineItemRow = ({
  idx,
  item = {},
  parts = [],
  _updateLineItem = () => {},
  _fetchPartJobData = () => {},
  disabled = false,
  complete = false,
}) => {
  const [open, setOpen] = useState(!item?.id);

  const partItems = parts?.map((p) => ({ id: p.id, label: p.name }));

  const updateLineItemAttr = (key, value) => {
    const data = item;
    data[key] = value;

    _updateLineItem(idx, data);
  };

  const onUpdatePart = (partId) => {
    const part = parts?.find((p) => p?.id === partId);
    if (part?.id) {
      const data = {
        ...item,
        price: undefined,
        part,
      };

      _updateLineItem(idx, data);
      _fetchPartJobData(part?.id, idx);
    }
  };

  const onUpdateQuantity = (quantity) => {
    const trees = Math.ceil(quantity / item?.part?.partsPerTree);
    const patReq = trees * item?.part?.partsPerTree;

    const data = {
      ...item,
      quantity: parseInt(quantity),
      trees,
      patReq,
    };

    _updateLineItem(idx, data);
  };

  const onUpdateDate = (value, isStart = false) => {
    const newDates = onUpdateDateHelper(value, isStart, item?.part?.totalDays);

    const data = {
      ...item,
      ...newDates,
    };
    _updateLineItem(idx, data);
  };

  useEffect(() => {
    !item?.part?.id && partItems?.length && onUpdatePart(partItems[0]?.id);
  }, [item?.part?.id]);

  useEffect(() => {
    item.quantity && onUpdateQuantity(item.quantity);
  }, [item.quantity]);

  const WorkOrder =
    item?.overrideWo || `W${(item?.workOrder).toString().padStart(5, "0")}`;

  const onClick = () => setOpen(!open);

  return (
    <>
      <StyledTableRow key={item.id} onClick={onClick} bgDisabled>
        <TableCell>
          <OrderLineItemChip state={item?.state} />
        </TableCell>
        <TableCell sx={{ width: 100, minWidth: 100 }}>
          {complete ? (
            <Typography variant="caption11">{item?.line}</Typography>
          ) : (
            <HubHookFormInput
              name={"line" + idx}
              placeholder="Line"
              defaultValue={item?.line || "1"}
              onBlur={(e, value) => updateLineItemAttr("line", value)}
              disableHelperText
              required
            />
          )}
        </TableCell>
        <TableCell sx={{ width: 250, minWidth: 250 }}>
          {disabled ? (
            <Typography variant="caption11">{item?.part?.name}</Typography>
          ) : (
            <HubHookFormSelect
              name={"part" + idx}
              onChange={(e) => onUpdatePart(e?.target?.value)}
              disabled={!partItems?.length}
              defaultValue={item?.part?.id}
              options={partItems}
              disableHelperText
              required
            />
          )}
        </TableCell>
        <TableCell sx={{ minWidth: 100 }}>
          {disabled ? (
            <Typography variant="caption11">{WorkOrder}</Typography>
          ) : (
            <HubHookFormInput
              name={"workOrder" + idx}
              placeholder="W00000"
              defaultValue={WorkOrder}
              onBlur={(e, value) => updateLineItemAttr("overrideWo", value)}
              disableHelperText
              required
            />
          )}
        </TableCell>
        <TableCell sx={{ minWidth: 100 }}>
          {complete ? (
            <Typography variant="caption11">{item?.lotNumber}</Typography>
          ) : (
            <HubHookFormInput
              name={"lotNumber" + idx}
              placeholder="UT240000"
              defaultValue={item?.lotNumber}
              onBlur={(e, value) => updateLineItemAttr("lotNumber", value)}
              disableHelperText
              required
            />
          )}
        </TableCell>
        <TableCell sx={{ width: 100, minWidth: 100 }}>
          {disabled ? (
            <Typography variant="caption11">{item?.quantity}</Typography>
          ) : (
            <HubHookFormInput
              name={"qty" + idx}
              placeholder="1000"
              defaultValue={item?.quantity}
              onBlur={(e, value) => onUpdateQuantity(value)}
              disableHelperText
              isNumber
              required
            />
          )}
        </TableCell>
        <TableCell sx={{ width: 125, minWidth: 125 }}>
          {disabled ? (
            <Typography variant="caption11">
              {getDollarValue(item?.price || item?.part?.lastSentPrice)}
            </Typography>
          ) : (
            <HubHookFormInput
              name={"price" + idx}
              placeholder="10.00"
              defaultValue={item?.price || item?.part?.lastSentPrice}
              onBlur={(e, value) => updateLineItemAttr("price", value)}
              disabled={disabled}
              disableHelperText
              isCurrency
              required
            />
          )}
        </TableCell>
        <TableCell sx={{ minWidth: 125 }}>
          {disabled ? (
            <Typography variant="caption11">{item?.startDate}</Typography>
          ) : (
            <StyledHubHookFormDatePicker
              name={"start" + idx}
              placeholder="MM/DD/YYYY"
              defaultValue={item?.startDate}
              onChange={(value) => onUpdateDate(value, true)}
              disabled={!item?.part?.id}
              required
            />
          )}
        </TableCell>
        <TableCell sx={{ minWidth: 125 }}>
          {disabled ? (
            <Typography variant="caption11">{item?.dueDate}</Typography>
          ) : (
            <StyledHubHookFormDatePicker
              name={"due" + idx}
              placeholder="MM/DD/YYYY"
              defaultValue={item?.dueDate}
              onChange={(value) => onUpdateDate(value)}
              disabled={!item?.part?.id}
              required
            />
          )}
        </TableCell>
        <TableCell sx={{ maxWidth: "35px" }}>
          <HubHookFormInput
            name={"id" + idx}
            defaultValue={item?.id}
            disableHelperText
            hidden
          />
          <LineItemContextMenu id={idx} disabled={disabled} />
        </TableCell>
      </StyledTableRow>
      <OrderSublineRow item={item} isOpen={open} />
    </>
  );
};

const mapStateToProps = (state) => ({
  parts: getPurchaseOrderCustomerParts(state),
  details: getPurchaseOrderDetails(state),
});

const mapDispatchToProps = {
  _updateLineItem: updatePoLineItem,
  _fetchPartJobData: fetchPartJobData,
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderLineItemRow);
