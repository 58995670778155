import React, { useEffect } from "react";
import { Stack } from "@mui/material";
import { connect } from "react-redux";

import {
  flushPurchaseOrder,
  getPurchaseOrderDetails,
  getPurchaseOrderItems,
  getCustomerData,
  getCustomerDataParts,
} from "../../redux";

import { usePostOrderData } from "../../hooks";
import { HubHookFormWrapper, HubToolbar } from "../../components";
import { StyledDrawer, StyledDrawerStack } from "./styles";
import { formOrderMapper } from "./helper";

import PoList from "./PoList";
import PoDetails from "./PoDetails";

const CreatePurchase = ({
  customer,
  parts = [],
  items = [],
  open = true,
  onClose = () => {},
  _flushPurchaseOrder = () => {},
}) => {
  const { mutate: postOrderData, isLoading, isSuccess } = usePostOrderData();

  const onSubmit = (fd) => {
    const length = items?.length - 1;
    const payload = formOrderMapper(fd, customer, parts, length);

    postOrderData(payload);
  };

  const handleClose = () => {
    onClose();
    _flushPurchaseOrder();
  };

  useEffect(() => {
    isSuccess && handleClose();
  }, [isSuccess]);

  return (
    <StyledDrawer
      anchor="bottom"
      open={open}
      onClose={handleClose}
      keepMounted={false}
    >
      <HubToolbar
        label="New Purchase Order"
        subtitle="Add line items for parts needed along with needed details"
        variant="transparent"
        onClose={handleClose}
        stackProps={{ sx: { alignItems: "start", flexGrow: 0 } }}
        buttonProps={{ icon: "X" }}
        sx={{
          padding: "10px 20px !important",
          margin: 0,
        }}
      />
      <HubHookFormWrapper onSubmit={onSubmit} sx={{ height: "100%" }}>
        <StyledDrawerStack>
          <PoList open={open} />
          <PoDetails isLoading={isLoading} />
        </StyledDrawerStack>
      </HubHookFormWrapper>
    </StyledDrawer>
  );
};

const mapStateToProps = (state) => ({
  customer: getCustomerData(state),
  items: getPurchaseOrderItems(state),
  parts: getCustomerDataParts(state),
});

const mapDispatchToProps = {
  _flushPurchaseOrder: flushPurchaseOrder,
};

export const CreatePurchaseOrder = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatePurchase);
