import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";

export const StyledCard = styled(Stack)(({ theme }) => ({
    border: "1px solid",
    borderColor: theme.palette.hubColors.greyLight,
    backgroundColor: theme.palette.hubColors.white,
    boxSizing: "border-box",
    flexGrow: 1,
    // height: "100%",
  }));