import React from "react";
import { connect } from "react-redux";

import { getOrdersData } from "../../redux";
import { DataRateContainer } from "../../components";
import { StyledDataStack } from "./styles";

export const OrdersBar = ({ stats = [] }) => {
  return (
    <StyledDataStack>
      {stats?.map((data, i) => (
        <DataRateContainer {...data} key={i} />
      ))}
    </StyledDataStack>
  );
};

const mapStateToProps = (state) => ({
  stats: getOrdersData(state),
});

export default connect(mapStateToProps)(OrdersBar);
