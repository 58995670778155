import { styled } from "@mui/material/styles";
import { HubHookFormSelect } from "../../components";

export const StyledHubHookFormSelect = styled(HubHookFormSelect)(
  ({ theme }) => ({
    "& .MuiSelect-select": {
      "& .MuiTypography-root": {
        fontWeight: 400,
        fontSize: "10px !important",
      },
    },
    "& .MuiChip-root": {
      display: "none",
    },
  })
);
