import { createSelector } from "reselect";

const LOAD_PART_DETAILS = "LOAD_PART_DETAILS";
const LOAD_MATERIALS = "LOAD_MATERIALS";
const CLEAR_PARTS = "CLEAR_PARTS";

const initialState = {
  main: {},
  details: {},
  cost: {},
  history: {},
  trees: [],
  materials: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "FLUSH":
    case CLEAR_PARTS: {
      return initialState;
    }

    case LOAD_PART_DETAILS: {
      const newState = {
        ...state,
        main: action?.data?.main,
        details: action?.data?.details,
        cost: action?.data?.cost,
        history: action?.data?.history,
      };
      return newState;
    }

    case LOAD_MATERIALS: {
      const newState = {
        ...state,
        trees: action?.data?.trees,
        materials: action?.data?.materials,
      };
      return newState;
    }

    default:
      return state;
  }
}

export function setMaterials(data = {}) {
  return {
    type: LOAD_MATERIALS,
    data,
  };
}

export function setPartDetails(data = {}) {
  return {
    type: LOAD_PART_DETAILS,
    data,
  };
}

export const getPartState = createSelector(
  (state) => state,
  (state) => state?.part
);

export const getPartMain = createSelector(
  getPartState,
  (partState) => partState?.main || {}
);

export const getPartDetails = createSelector(
  getPartState,
  (partState) => partState?.details || {}
);

export const getPartName = createSelector(
  getPartMain,
  (details) => details?.partName || ""
);

export const getPartNumber = createSelector(
  getPartMain,
  (details) => details?.partNumber || ""
);

export const getPartStatus = createSelector(
  getPartMain,
  (details) => details?.status || ""
);

export const getPartCost = createSelector(
  getPartState,
  (partState) => partState?.cost || {}
);

export const getPartHistory = createSelector(
  getPartState,
  (partState) => partState?.history || {}
);

export const getPartTrees = createSelector(
  getPartState,
  (partState) => partState?.trees || []
);

export const getPartMaterials = createSelector(
  getPartState,
  (partState) => partState?.materials || []
);

export const getPartTotalCost = createSelector(
  getPartCost,
  (partCost) => partCost?.suggestedPrice
);
