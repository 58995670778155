import React from "react";
import { StyledCard } from "./styles";

export const HubCardStack = ({ children, ...rest }) => {
  return (
    <StyledCard padding="25px" gap="5px" borderRadius="10px" {...rest}>
      {children}
    </StyledCard>
  );
};
