import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

export const StyledStack = styled(Stack)(({ theme }) => ({
  justifyContent: "start",
  alignItems: "center",
  gap: "25px",
  padding: "25px",
  borderRadius: "20px",
  // border: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  backgroundColor: theme.palette.hubColors.white,
}));

export const StyledText = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 14,
}));
