import { Typography } from "@mui/material";
import React from "react";

export const InputLabel = ({
  id = "",
  smallLabel = true,
  label,
  required,
  disableMargin = false,
  ...rest
}) => {
  if (!label) {
    return <></>;
  }

  const sx = disableMargin ? { margin: "0 !important" } : {};

  return (
    <Typography
      variant={smallLabel ? "inputLabelSmall" : "inputLabel"}
      htmlFor={id}
      sx={sx}
      {...rest}
    >
      {label}{" "}
      {required && (
        <Typography
          variant={smallLabel ? "inputLabelSmall" : "inputLabel"}
          sx={{ color: "hubColors.redMain" }}
        >
          *
        </Typography>
      )}
    </Typography>
  );
};

export default InputLabel;
