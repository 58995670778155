import React from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getAuthUser, logout } from "../../../redux/auth";
import { usePostSignOut } from "../../../hooks";
import { stringToAlphabetSum } from "../../../helpers/userImageColor";
import HubContextMenu from "../../HubContextMenu/HubContextMenu";
import { UserDetailIcon } from "../../UserDetailIcon";

const MENU_ITEMS = [
  {
    id: "log_out",
    label: "Log out",
  },
];

const UserProfileContextMenu = ({ user = {}, _setViewMode, ...rest }) => {
  const { mutate: postSignOutData } = usePostSignOut();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSelect = (item) => {
    switch (item?.id) {
      case "log_out":
        postSignOutData();
        dispatch(logout());
        navigate("/signin");
        break;
      default:
        break;
    }
  };

  const USER = {
    hueShift: stringToAlphabetSum(`${user?.email}${user?.name}`),
    name: user?.name,
    email: user?.email,
  };

  const MenuButton = (
    <UserDetailIcon
      user={USER}
      // includeDetails={false}
      variant="rounded"
      size={30}
    />
  );

  return (
    <HubContextMenu
      id={"project"}
      loading={false}
      menuItems={MENU_ITEMS}
      menuButton={MenuButton}
      onSelect={onSelect}
      MenuProps={{ sx: { margin: 0, padding: 0 } }}
      IconButtonProps={{ sx: { margin: 0, padding: 0 } }}
      {...rest}
    />
  );
};

const mapStateToProps = (state) => ({
  user: getAuthUser(state),
});

export default connect(mapStateToProps)(UserProfileContextMenu);
