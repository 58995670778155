import React from "react";
import { Table, TableContainer } from "@mui/material";
import PostFoundryTableHeader from "./PostFoundryTableHeader";
import PostFoundryTableBody from "./PostFoundryTableBody";

export const PostFoundryTable = () => {
  return (
    <TableContainer>
      <Table id="PostFoundryTable">
        <PostFoundryTableHeader />
        <PostFoundryTableBody />
      </Table>
    </TableContainer>
  );
};

export default PostFoundryTable;
