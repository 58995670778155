import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { connect } from "react-redux";

import {
  getCustomerDataParts,
  getCustomerOrderCount,
} from "../../redux/customerData";
import { getIsDialogOpen, setIsOpenDialog } from "../../redux/dialogs";

import { CustomerOrdersTable } from "../../tables";
import { CreatePurchaseOrder, RepeatWorkOrderDialog } from "../../dialogs";
import { HubButton } from "../../components";
import { EmptyOrders } from "../../svgs";

import CustomerOrdersBar from "./CustomerOrdersBar";

const name = "purchase-order-slide";

export const CustomerOrdersUnwrapped = ({
  count = 0,
  parts = [],
  open = false,
  _setIsOpenDialog = () => {},
}) => {
  const hasParts = !!parts?.length;
  const createProps = !hasParts
    ? {
        disabled: true,
        hoverTitle: "Customer Has No Parts",
        hoverSubtitle: "Cannot create order for customer without parts",
      }
    : {};

  const handleSetOpen = (value) => {
    _setIsOpenDialog(name, value);
  };

  return (
    <>
      {count ? (
        <Stack gap="25px">
          <CustomerOrdersBar
            onClick={() => handleSetOpen(true)}
            createProps={createProps}
          />
          <CustomerOrdersTable />
        </Stack>
      ) : (
        <Stack gap="25px" margin="auto" alignItems="center">
          <Box component="img" src={EmptyOrders} sx={{ height: "150px" }} />
          <Stack gap="10px" alignItems="center" textAlign="center">
            <Typography variant="boldCaption">No Orders Found</Typography>
            <Typography variant="caption" width="250px">
              Click "Create Order" button to get started in adding this
              customer's new order
            </Typography>
            <Stack direction="row" gap="10px">
              <HubButton
                variant="outlined"
                size="small"
                color="neutral"
                label="Learn More"
              />
              <HubButton
                label="Create Order"
                variant="contained"
                size="small"
                onClick={() => handleSetOpen(true)}
                {...createProps}
              />
            </Stack>
          </Stack>
        </Stack>
      )}
      <CreatePurchaseOrder open={open} onClose={() => handleSetOpen(false)} />
      <RepeatWorkOrderDialog />
    </>
  );
};

const mapStateToProps = (state) => ({
  count: getCustomerOrderCount(state),
  parts: getCustomerDataParts(state),
  open: getIsDialogOpen(state, name),
});

const mapDispatchToProps = {
  _setIsOpenDialog: setIsOpenDialog,
};

export const CustomerOrders = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerOrdersUnwrapped);
