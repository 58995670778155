import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { StyledTooltip } from "./styles";

export const TooltipTemplates = {
  restricted: {
    title: "Restricted Permissions",
    subtitle: "Your role cannot access this feature",
  },
};

export const HoverInfo = ({
  children,
  hoverTitle,
  hoverSubtitle,
  hoverContents,
  template,
}) => {
  const title = hoverTitle || template?.title;
  const subtitle = hoverSubtitle || template?.subtitle;

  const hoverData = (title || subtitle) && (
    <Stack sx={{ fontSize: 16 }}>
      {title && <Typography variant="boldCaption">{title}</Typography>}
      {subtitle && <Typography variant="caption">{subtitle}</Typography>}
    </Stack>
  );

  return hoverData ? (
    <StyledTooltip title={hoverData} interactive>
      <Box>{children}</Box>
    </StyledTooltip>
  ) : (
    children
  );
};
