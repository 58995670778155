import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import { apiMiddleware } from "redux-api-middleware";
import createSagaMiddleware from "redux-saga";

import moduleReducers from "./reducers";
import routerReducer from "./reactRouterReducer";
import rootSaga from "./sagas";

export default function createStore(history, initialState) {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [
    thunk,
    apiMiddleware,
    routerMiddleware(history),
    sagaMiddleware,
  ];

  const reducer = combineReducers({
    router: routerReducer,
    ...moduleReducers,
  });

  const preLoadedState = initialState || {};
  const store = configureStore({
    reducer,
    middleware: middlewares,
    preLoadedState,
  });

  sagaMiddleware.run(rootSaga);

  return store;
}
