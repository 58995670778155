import { styled } from "@mui/material/styles";
import { Drawer, FormControlLabel, Stack, Typography } from "@mui/material";
import { HubButton } from "../../components";

export const StyledDrawer = styled(Drawer)({
  "& .MuiDrawer-paper": {
    width: "100%",
    maxWidth: "550px",
    // borderRadius: "0px 25px 25px 0",
    overflow: "hidden",
  },
});

export const StyledSideBar = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  borderRight: "1px solid",
  maxWidth: "350px",
  width: "100%",
  borderColor: theme.palette.hubColors.greyLight,
  backgroundColor: theme.palette.hubColors.white,
  overflowY: "auto",
  "::-webkit-scrollbar": {
    display: "none",
  },
}));

export const StyledTitleStack = styled(Stack)(({ theme }) => ({
  width: "100%",
  alignItems: "center",
  gap: "25px",
  padding: "50px",
  borderBottom: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  textAlign: "center",
}));

export const StyledAddressStack = styled(Stack)(() => ({
  width: "100%",
  gap: "25px",
  padding: "40px",
}));

export const StyledContactButton = styled(HubButton)(() => ({
  padding: "12px",
  gap: "8px",
  width: "42px",
  height: "42px",
  borderRadius: "25px",
  minWidth: 0,
}));

export const StyledEditButton = styled(HubButton)(() => ({
  padding: "6px 16px",
  width: "fit-content",
  height: "37px",
  minWidth: 0,
}));

export const StyledDetailStack = styled(Stack)({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  alignItems: "start",
  justifyContent: "center",
  columnGap: "25px",
  rowGap: "15px",
});

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  justifyContent: "start",
  margin: 0,
  gap: "7px",
  "& .MuiCheckbox-root, .Mui-disabled": {
    padding: 0,
    color: `${theme.palette.hubColors.mainFocus} !important`,
  },
}));

export const StyledCardContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  height: "100%",
  minHeight: "fit-content",
  //   overflow: "auto",
  //   maxHeight: "max-content",
  padding: "25px",
  gap: "25px",
  borderRadius: "10px",
  border: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  backgroundColor: theme.palette.hubColors.white,
  boxSizing: "border-box",
  "> .MuiStack-root": {
    // overflow: "auto",
  },
  "& .MuiTableContainer-root": {
    // overflow: "auto",
  },
}));

export const StyledCard = styled(Stack)(({ theme }) => ({
  padding: "25px",
  gap: "5px",
  borderRadius: "10px",
  border: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  backgroundColor: theme.palette.hubColors.white,
  boxSizing: "border-box",
  flexGrow: 1,
}));

export const StyledDataStack = styled(Stack)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gap: "10px",
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(1, 1fr)",
  },
}));

export const StyledPercentStack = styled(Stack, {
  shouldForwardProp: (prop) => !["color"].includes(prop),
})(({ theme, color }) => ({
  flexDirection: "row",
  gap: "5px",
  alignItems: "center",
  color: theme.palette.hubColors[color],
}));

export const StyledPercentText = styled(Typography, {
  shouldForwardProp: (prop) => !["color", "heavy"].includes(prop),
})(({ theme, color, heavy }) => ({
  fontSize: 11,
  color: theme.palette.hubColors[color],
  fontWeight: heavy ? 600 : 300,
}));
