import React from "react";

import { HubTableHeader } from "../../components";

const headCells = [
  {
    id: "primary",
    label: "Primary",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "title",
    label: "Title",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "phone",
    label: "Phone Number",
  },
  {
    id: "workPhone",
    label: "Work Number",
  },
];

export const CustomerContactsHeader = () => {
  return (
    <HubTableHeader
      items={headCells}
      includeCheckbox={false}
      sx={{ backgroundColor: "hubColors.greyLighter" }}
    />
  );
};

export default CustomerContactsHeader;
