import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { connect } from "react-redux";

import { removePartOutside } from "../../redux/routing";
import { OUTSIDE_ROUTINGS as OUTSIDE, PARTNERS } from "../../constants";
import {
  HubButton,
  HubHookFormSelect,
  HubHookFormTimePicker,
} from "../../components";

const OutsideProcessingTableRow = ({
  id = "",
  idx = 0,
  route = {},
  _removePartOutside = () => {},
}) => {
  const handleRemove = () => _removePartOutside(idx);
  const preset = `outside.routes.${id}`;

  return (
    <TableRow
      sx={{
        ".DipRowButton": {
          display: "none",
        },
        "&:hover": {
          ".DipRowButton": {
            display: "inline",
          },
        },
      }}
    >
      <TableCell sx={{ minWidth: 250 }}>
        <HubHookFormSelect
          name={`${preset}.processId`}
          defaultValue={route?.routingType}
          options={OUTSIDE}
          required
        />
      </TableCell>
      <TableCell sx={{ minWidth: 250 }}>
        <HubHookFormSelect
          name={`${preset}.partnerId`}
          defaultValue={route?.partnerId}
          options={PARTNERS}
          required
        />
      </TableCell>
      <TableCell sx={{ minWidth: 50 }}>
        <HubHookFormTimePicker
          name={`${preset}.leadTime`}
          defaultValue={route?.leadTime || 1440}
          placeholder="124"
          onlyDays
          required
        />
      </TableCell>
      <TableCell padding="checkbox">
        <HubButton
          className="DipRowButton"
          icon="Trash2"
          variant="text"
          iconProps={{ size: 14 }}
          sx={{ color: "hubColors.redMain" }}
          onClick={handleRemove}
        />
      </TableCell>
    </TableRow>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  _removePartOutside: removePartOutside,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OutsideProcessingTableRow);
