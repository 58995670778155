import React from "react";
import { HubActionDialog } from "../HubActionDialog";

export const WarningDeleteDialog = ({
  isOpen = false,
  onClose = () => {},
  onSubmit = () => {},
  ...rest
}) => {
  const description = `This action cannot be reversed and will be permanent,
  if you are sure and wish to continue please click 'Confirm Delete'`;

  return (
    <HubActionDialog
      onClose={onClose}
      open={isOpen}
      submitProps={{ size: "large" }}
      title="You are about to delete this item!"
      description={description}
      handleSubmit={onSubmit}
      submitLabel="Confirm Delete"
      includeCancel={false}
      action="warning"
      {...rest}
    />
  );
};

export default WarningDeleteDialog;
