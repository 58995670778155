import { styled } from "@mui/material/styles";
import { Drawer, Stack } from "@mui/material";

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    minHeight: "200px",
    height: "calc(100% - 25px)",
    borderRadius: "15px 15px 0 0",
    backgroundColor: theme.palette.hubColors.greyLighter,
  },
}));

export const StyledDrawerStack = styled(Stack)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "calc(100vw - 495px) 450px",
  gap: "15px",
  padding: "15px",
  height: "100%",
  boxSizing: "border-box",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "calc(100vw - 45px)",
  },
}));

export const StyledLineItemInputs = styled(Stack)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "5.75fr 4.5fr",
  gap: "10px",
  [theme.breakpoints.down(1430)]: {
    gridTemplateColumns: "1fr",
  },
}));

export const StyledFirstGrid = styled(Stack)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "0.75fr 3.5fr 1.5fr 1.5fr",
  gap: "10px",
  [theme.breakpoints.down(1000)]: {
    gridTemplateColumns: "1fr 3fr",
  },
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "0.75fr 3.5fr 1.5fr 1.5fr",
  },
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "1fr 3fr",
  },
  [theme.breakpoints.down(380)]: {
    gridTemplateColumns: "1fr",
  },
}));

export const StyledSecondGrid = styled(Stack)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1.25fr 1.25fr",
  gap: "10px",
  [theme.breakpoints.down(1000)]: {
    gridTemplateColumns: "1fr 1fr",
  },
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr 1fr 1.25fr 1.25fr",
  },
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "1fr 1fr",
  },
  [theme.breakpoints.down(380)]: {
    gridTemplateColumns: "1fr",
  },
}));

export const StyledSubStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}));
