import React from "react";

import { HubTableHeader, InputLabel } from "../../components";

const required = true;

const headCells = [
  {
    id: "di-prewet",
    label: "Prewet",
  },
  {
    id: "di-dip",
    label: "Dip",
    required,
  },
  {
    id: "di-cycle",
    label: "Cycle Time",
    required,
  },
  {
    id: "di-lead",
    label: "Lead Time",
    required,
  },
  {
    id: "di-day",
    label: "Day",
  },
];

export const DipsTableHeader = () => {
  const ITEMS = headCells.map((cell) => ({
    id: cell.id,
    label: cell?.required ? (
      <InputLabel label={cell.label} required disableMargin />
    ) : (
      cell.label
    ),
  }));

  return (
    <HubTableHeader
      items={ITEMS}
      includeCheckbox={false}
      sx={{ backgroundColor: "hubColors.greyLighter" }}
    />
  );
};

export default DipsTableHeader;
