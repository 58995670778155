import React from "react";
import PropTypes from "prop-types";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";

import { inputLabelStyles, StyledHubInput } from "./styles";
import { Stack, Typography } from "@mui/material";
import { HubIcon } from "../../HubIcon";
// import UserImages from "../../UserImages/UserImages";
import { InputLabel } from "../../InputLabel";

const filter = createFilterOptions();

// a lot of this functionality was based off these two  MUI <Autocomplete /> examples -> links for reference
// https://mui.com/material-ui/react-autocomplete/#creatable
// https://mui.com/material-ui/react-autocomplete/#checkboxes

export const HubTagInput = ({
  onChange = () => {},
  options = [],
  value,
  label,
  LabelComponent,
  placeholder,
  hideHelperText = false,
  freeSolo = true,
  optionValueKey = "id",
  optionLabelKey = "label",
  includeCheck = true,
  startAdornmentIcon = "Search",
  customNoTag = "Invite",
  disableUnderline = false,
  defaultValue = [],
  smallLabel = true,
  disableHelperText,
  required,
  InputBoxProps,
  ...rest
}) => {
  const filterOptions = (options, params) => {
    const filtered = filter(options, params);

    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some(
      (option) => inputValue === option[optionLabelKey]
    );
    if (inputValue !== "" && !isExisting) {
      filtered.push({
        inputValue,
        name: `${customNoTag} "${inputValue}"`,
      });
    }

    return filtered;
  };

  const getOptionLabel = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === "string") {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option[optionLabelKey] || "";
  };

  const renderTags = (value, getTagProps) =>
    value.map(
      (option, index) =>
        option && (
          <Chip
            key={option[optionValueKey] || index}
            label={option?.inputValue || option[optionLabelKey]}
            sx={{ gap: "5px", svg: { width: "15px" } }}
            {...getTagProps({ index })}
          />
        )
    );

  const renderOption = (props, option, { selected }) => (
    <li {...props}>
      <Stack
        direction="row"
        gap="10px"
        alignItems="center"
        justifyContent="center"
      >
        {includeCheck && (
          <Checkbox
            checked={selected}
            size="15px"
            sx={{ padding: 0, margin: 0 }}
          />
        )}
        <Typography variant="caption" sx={{ fontWeight: 400 }}>
          {option[optionLabelKey]}
        </Typography>
      </Stack>
    </li>
  );

  const renderInput = (params) => {
    const props = {
      placeholder: !value?.length ? placeholder : "",
      helperText: !hideHelperText ? <span>&nbsp;</span> : undefined,
      InputLabelProps: {
        shrink: false,
        sx: inputLabelStyles,
      },
      InputProps: {
        ...params?.InputProps,
        startAdornment:
          !value?.length && startAdornmentIcon ? (
            <HubIcon
              icon={startAdornmentIcon}
              sx={{
                color: "hubColors.darkGray1",
                width: "15px",
                padding: "0 5px",
              }}
            />
          ) : (
            params?.InputProps?.startAdornment
          ),
        ...(disableUnderline ? { disableUnderline } : {}),
      },
    };
    return !disableUnderline ? (
      <TextField {...params} variant="outlined" {...props} />
    ) : (
      <StyledHubInput {...params} variant="filled" {...props} />
    );
  };

  const handleOnChange = (value, newValue) => {
    onChange(newValue);
  };

  return (
    <Stack
      gap="10px"
      sx={{
        fontSize: "20px",
        width: "100%",
        "& .MuiFormHelperText-root": {
          display: disableHelperText ? "none" : "block",
        },
      }}
      {...InputBoxProps}
    >
      <InputLabel label={label} required={required} smallLabel={smallLabel} />
      {LabelComponent && <LabelComponent />}
      <Autocomplete
        autoHighlight
        multiple
        disableCloseOnSelect
        options={options}
        size="small"
        freeSolo={freeSolo}
        onChange={handleOnChange}
        filterOptions={freeSolo ? filterOptions : undefined}
        getOptionLabel={getOptionLabel}
        renderTags={renderTags}
        renderInput={renderInput}
        renderOption={renderOption}
        {...rest}
      />
    </Stack>
  );
};

HubTagInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  optionValueKey: PropTypes.string,
  optionLabelKey: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.arrayOf(PropTypes.object),
  freeSolo: PropTypes.bool,
  hideHelperText: PropTypes.bool,
};

export default HubTagInput;
