import React from "react";
import { connect } from "react-redux";

import { HubPagination } from "../../components";
import { getOrdersMeta } from "../../redux/orders";

const PurchaseOrdersPagination = ({ meta }) => {
  return meta?.count > 25 ? <HubPagination {...meta} /> : <></>;
};

const mapStateToProps = (state) => ({
  meta: getOrdersMeta(state),
});

export default connect(mapStateToProps)(PurchaseOrdersPagination);
