import { createSelector } from "reselect";
import {
  PERIODS,
  PERIOD_DEFAULTS,
  PERIOD_LABEL,
  PERIOD_STEPS,
} from "../constants";
import dayjs from "dayjs";

const SET_JOB_ORDERS = "SET_JOB_ORDERS";
const CLEAR_ORDERS = "CLEAR_ORDERS";

const SET_PERIOD = "SET_PERIOD";
const SET_START_DATE = "SET_START_DATE";
const SET_END_DATE = "SET_END_DATE";

const INIT_FILTER_PERIOD = "INIT_FILTER_PERIOD";
const SET_JOB_DASHBOARD = 'SET_JOB_DASHBOARD';

const initialState = {
  orders: [],
  meta: {},
  data: [],
  calendar: [],
  period: PERIODS.weekly,
  startDate: PERIOD_DEFAULTS.weekly,
  endDate: dayjs(PERIOD_DEFAULTS.weekly)
    .add(PERIOD_STEPS.weekly, PERIOD_LABEL.weekly)
    .format("MM/DD/YYYY"),
};

const periodChangeHelper = (per) => {
  const period = PERIODS[per] || PERIODS.weekly;
  const steps = PERIOD_STEPS[period];
  const inType = PERIOD_LABEL[period];

  const startDate = PERIOD_DEFAULTS[period];
  const endDate = dayjs(startDate).add(steps, inType).format("MM/DD/YYYY");

  return {
    steps,
    period,
    startDate,
    endDate,
  };
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "FLUSH":
    case CLEAR_ORDERS: {
      return initialState;
    }

    case SET_JOB_ORDERS: {
      const newState = {
        ...state,
        orders: action?.data?.orders,
        meta: action?.data?.meta,
      };
      return newState;
    }

    case SET_JOB_DASHBOARD: {
      const newState = {
        ...state,
        data: action?.data?.data,
        calendar: action?.data?.graph,
      }
      return newState;
    }

    case SET_PERIOD: {
      const newState = {
        ...state,
        ...periodChangeHelper(action.period),
      };
      return newState;
    }

    case SET_START_DATE: {
      const newState = {
        ...state,
        startDate: action.date,
      };
      return newState;
    }

    case SET_END_DATE: {
      const newState = {
        ...state,
        steps: action.steps,
        endDate: action.date,
      };
      return newState;
    }

    case INIT_FILTER_PERIOD: {
      const newState = {
        ...state,
        ...action.data,
      };
      return newState;
    }

    default:
      return state;
  }
}

export const setJobOrders = (data = {}) => ({
  type: SET_JOB_ORDERS,
  data,
});

export const setJobsDashboard = (data) => ({
  type: SET_JOB_DASHBOARD,
  data
})

export const setOrdersPeriod = (period) => ({
  type: SET_PERIOD,
  period,
});

export const setOrdersStartDate = (date) => ({
  type: SET_START_DATE,
  date,
});

export const setOrdersEndDate = (date, steps) => ({
  type: SET_END_DATE,
  steps,
  date,
});

export const initOrdersPeriod = (data) => ({
  type: INIT_FILTER_PERIOD,
  data,
});

export const getOrdersState = createSelector(
  (state) => state,
  (state) => state?.orders
);

export const getOrders = createSelector(
  getOrdersState,
  (ordersState) => ordersState?.orders || []
);

export const getOrdersMeta = createSelector(
  getOrdersState,
  (ordersState) => ordersState?.meta || {}
);

export const getOrdersData = createSelector(
  getOrdersState,
  (ordersState) => ordersState?.data || {}
);

export const getOrdersCalendar = createSelector(
  getOrdersState,
  (ordersState) => ordersState?.calendar || []
);

export const getOrdersStatuses = createSelector(
  getOrdersState,
  (ordersState) => ordersState?.statuses || []
);

export const getOrdersPeriod = createSelector(
  getOrdersState,
  (ordersState) => ordersState.period || PERIODS.weekly
);

export const getOrdersStartDate = createSelector(
  getOrdersState,
  (ordersState) => ordersState.startDate || ""
);

export const getOrdersEndDate = createSelector(
  getOrdersState,
  (ordersState) => ordersState.endDate || ""
);

export const getOrdersPeriodSteps = createSelector(
  getOrdersState,
  (ordersState) => ordersState.steps || PERIOD_STEPS.weekly
);
