import React from "react";
//import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from "prop-types";
import { TextField, Stack, Typography } from "@mui/material/";
import { InputLabel } from "../../InputLabel";

export const HubInput = ({
  loading = false,
  label = "",
  required,
  placeholder = "",
  InputBoxProps = {},
  onChange = () => {},
  id,
  filled = false,
  InputProps,
  ...rest
}) => {
  const style = {
    fontSize: "20px",
    width: "100%",
    ...InputBoxProps?.sx,
  };

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <Stack gap="10px" sx={style} {...InputBoxProps}>
      <InputLabel label={label} required={required} />
      {filled ? (
        <TextField
          id={id}
          onInput={handleChange}
          placeholder={placeholder}
          InputProps={{
            ...InputProps,
            disableUnderline: true,
            sx: { input: { padding: 0 } },
          }}
          variant="filled"
          disableHelperText
          {...rest}
        />
      ) : (
        <TextField
          id={id}
          onInput={handleChange}
          placeholder={placeholder}
          variant="outlined"
          InputProps={InputProps}
          {...rest}
        />
      )}
    </Stack>
  );
};

const HelperText = ({ error }) => (
  <Typography variant="inputErrorHelp">
    {error || <span>&nbsp;</span>}
  </Typography>
);

HubInput.propTypes = {
  id: PropTypes.string,
  loading: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

export default HubInput;
