import React from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { HubIcon } from "../HubIcon";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

export const StyledTooltip = styled(
  ({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
  {
    shouldForwardProp: (prop) => prop !== "isCard",
  }
)(({ theme, isCard }) => ({
  "& a": {
    color: theme?.palette?.hubColors?.white,
    textDecoration: "underline",
  },
  ...(isCard && {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.hubColors.white,
      color: theme.palette.hubColors.darkGray,
      boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.2)",
      border: `1px solid ${theme.palette.hubColors.greyLight}`,
      padding: "25px",
    },
  }),
}));

export const StyledStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "fullWidth" && prop !== "disableFlex",
})(({ fullWidth, disableFlex }) => ({
  display: disableFlex ? "block" : "flex",
  flexDirection: "row",
  gap: "5px",
  marginBottom: "5px",
  alignItems: "center",
  width: fullWidth ? "100%" : "fit-content",
}));

export const StyledBox = styled(Stack, {
  shouldForwardProp: (prop) =>
    prop !== "fullWidth" && prop !== "disablePointer" && prop !== "disableFlex",
})(({ disablePointer, fullWidth, disableFlex }) => ({
  flexDirection: "row",
  display: disableFlex ? "block" : "flex",
  cursor: disablePointer ? "default" : "pointer",
  ...(fullWidth && {
    width: "100%",
  }),
}));
export const StyledHubIcon = styled(HubIcon)(({ theme }) => ({
  position: "static !important", //Need to look into more
  color: theme.palette.hubColors.darkGray,
}));
