import React from "react";

import { Collapse, TableRow, TableCell } from "@mui/material";

import { StyledSubStack } from "./styles";
import { PART_SECTIONS } from "./helper";

const RoutingSubPage = ({ isOpen, id = "" }) => {
  const sx = isOpen
    ? { ":hover": { backgroundColor: "transparent !important" } }
    : { border: "none" };

  const TAB = PART_SECTIONS[id] || PART_SECTIONS.wax;
  return (
    <TableRow sx={sx}>
      <TableCell colSpan={9} sx={{ padding: "0px !important" }}>
        <Collapse in={isOpen}>
          <StyledSubStack>{TAB}</StyledSubStack>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default RoutingSubPage;
