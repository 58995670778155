import React from "react";
import { Stack, Typography } from "@mui/material";
import { connect } from "react-redux";

import { addPartDips, getPartRouteByName } from "../../../redux/routing";
import { HubButton, HubHookFormInput } from "../../../components";
import { DipsTable } from "../../../tables";

import { StyledHubCardStack } from "../styles";

const gap = "10px";
const columnGap = "10px";

export const PartDipDetails = ({ details, _addPartDips = () => {} }) => {
  return (
    <StyledHubCardStack>
      <Stack gap={gap}>
        <Typography variant="boldCaptionMain">Dip Recipe</Typography>
        <DipsTable />
        <HubButton
          label="+ Add Dip"
          variant="text"
          sx={{ fontSize: 10, ml: "auto", mb: -5 }}
          onClick={_addPartDips}
        />
      </Stack>
      <Stack gap={gap}>
        <Typography variant="boldCaptionMain">Dewax</Typography>
        <Stack direction="row" gap={columnGap}>
          <HubHookFormInput
            name="dip.treesPerLoad"
            label="Trees / Load"
            defaultValue={details.treesPerLoad}
            placeholder="Ex. 12"
            isNumber
          />
          <HubHookFormInput
            name="dip.dewaxedShellWeight"
            label="Dewaxed Shell Weight"
            defaultValue={details.dewaxedShellWeight}
            placeholder="Ex. 12"
            isNumber
          />
        </Stack>
      </Stack>
      <Stack gap={gap}>
        <Typography variant="boldCaptionMain">Dip Notes</Typography>
        <HubHookFormInput
          name="dip.notes"
          label="Special Instructions"
          defaultValue={details.notes}
          placeholder="Dip notes..."
          rows={5}
          multiline
        />
      </Stack>
    </StyledHubCardStack>
  );
};

const mapStateToProps = (state) => ({
  details: getPartRouteByName(state, "dip"),
});
const mapDispatchToProps = {
  _addPartDips: addPartDips,
};

export default connect(mapStateToProps, mapDispatchToProps)(PartDipDetails);
