import { styled } from "@mui/material/styles";
import { Stack, TableRow } from "@mui/material";
import { HubHookFormDatePicker } from "../../components";

export const StyledSubStack = styled(Stack)(({ theme }) => ({
  padding: "10px 15px",
  flexDirection: "row",
  justifyContent: "space-between",
}));

export const StyledTableRow = styled(TableRow)({
  "& .MuiTableCell-root": {
    height: "52px",
    padding: "5px 8px",
    // padding: "0px",
    ">.MuiTypography-root": {
      marginLeft: "13px",
    },
    // ".MuiInputBase-root": {
    //   borderRadius: 0,
    // },
    // fieldset: {
    //   borderWidth: "0px",
    // },
  },
});

export const StyledHubHookFormDatePicker = styled(HubHookFormDatePicker)({
  input: {
    width: 75,
  },
  "& .MuiIconButton-root": {
    paddingLeft: 0,
  },
});
