import { FOUNDRIES } from "../../constants";

export const formOrderMapper = (
  fd = {},
  customer = {},
  parts = [],
  num = 1
) => {
  const items = [];

  const location =
    fd?.location || FOUNDRIES?.find((f) => customer?.location === f?.label)?.id;

  while (num >= 0) {
    const partId = fd[`part${num}`];
    const price = fd[`price${num}`];
    const qtyReq = fd[`qty${num}`];

    const part = parts.find((p) => p.id === partId);

    const margin = price
      ? (price * part?.margin) / part?.lastSentPrice
      : part?.margin;

    const trees = Math.ceil(qtyReq / part?.partsPerTree);
    const patReq = trees * part?.partsPerTree;

    items.push({
      lineItem: fd[`line${num}`],
      partId,
      price,
      margin: margin || 0.33,
      qtyReq,
      trees,
      patReq,
      woNumber: fd[`workOrder${num}`],
      lotNum: fd[`lotNumber${num}`],
      startDate: new Date(fd[`start${num}`]).toISOString(),
      dueDate: new Date(fd[`due${num}`]).toISOString(),
    });
    num--;
  }

  const payload = {
    companyId: customer?.id,
    location,
    customerPO: fd?.orderNumber,
    overShippingAllowed: fd?.overShipping,
    shipVia: fd?.shipVia,
    shipTo: fd?.shipTo,
    freight: fd?.freight,
    items,
  };

  return payload;
};

export const orderMapper = ({ items, customer, details, isUpdating, shipTo }) => {
  const location =
    details?.location ||
    FOUNDRIES?.find((f) => customer?.location === f?.label)?.id;

  const itemPayload = items?.map((i) => {
    const margin = i?.price
      ? (i?.price * i?.part?.margin) / i?.part?.lastSentPrice
      : i?.part?.margin;

    return {
      lineItem: i?.line,
      partId: i?.part?.id,
      price: i?.price || i?.part?.lastSentPrice,
      margin,
      woNumber:
        i?.overrideWo || `W${(i?.workOrder).toString().padStart(5, "0")}`,
      lotNum: i?.lotNumber,
      qtyReq: i?.quantity,
      trees: i?.trees,
      patReq: i?.patReq,
      startDate: i?.startDate && new Date(i?.startDate).toISOString(),
      dueDate: i?.dueDate && new Date(i?.dueDate).toISOString(),
    };
  });

  const payload = {
    companyId: customer?.id,
    customerPO: details?.poNumber,
    location: location,
    overShippingAllowed: details?.overShipping,
    shipVia: details?.shipVia,
    shipTo,
    freight: details?.freight,
    items: itemPayload,
    ...(isUpdating ? { id: details.id } : {}),
  };

  return payload;
};
