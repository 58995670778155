import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';

export const StyledSubStack = styled(Stack)(({ theme }) => ({
    padding: "15px",
    backgroundColor: theme.palette.hubColors.greyLightest,
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
    justifyContent: "center",
    alignItems: "start",
    padding: "25px",
    gap: "15px",
    borderRadius: "0px",
    border: "1px solid",
    borderColor: theme.palette.hubColors.greyLight,
    backgroundColor: theme.palette.hubColors.white,
}));