import React from "react";

import { HubTableHeader, InputLabel } from "../../components";

const required = true;

const headCells = [
  {
    id: "line",
    label: "Line",
    required,
  },
  {
    id: "part",
    label: "Part",
    required,
  },
  {
    id: "wo",
    label: "WO #",
    required,
  },
  {
    id: "lot",
    label: "Lot #",
    required,
  },
  {
    id: "qty",
    label: "Qty",
    required,
  },
  {
    id: "partPrice",
    label: "Part Price",
    required,
  },
  {
    id: "startDate",
    label: "Start Date",
    required,
  },
  {
    id: "dueDate",
    label: "Due Date",
    required,
  },
  {
    id: "checkbox",
  },
];

export const OrderLineItemsHeader = () => {
  const ITEMS = headCells.map((cell) => ({
    id: cell.id,
    label: cell?.required ? (
      <InputLabel label={cell.label} required disableMargin />
    ) : (
      cell.label
    ),
  }));

  return (
    <HubTableHeader
      items={ITEMS}
      includeCheckbox={false}
      includeEnd={false}
      sx={{ backgroundColor: "hubColors.greyLighter" }}
    />
  );
};

export default OrderLineItemsHeader;
