import React from "react";
import { Stack, Typography } from "@mui/material";
import { connect } from "react-redux";

import { getUserLocation } from "../../redux/auth";
import { INDUSTRIES, FOUNDRIES } from "../../constants/industry";

import { HubHookFormInput, HubHookFormSelect } from "../../components";

const CreateCompanyDetails = ({ disabled, userLocation = 1 }) => {
  return (
    <Stack gap="15px" width="100%">
      <Typography variant="boldCaptionMain">Customer Details</Typography>
      <HubHookFormInput
        name="customerName"
        label="Company Name"
        placeholder="ex. Foundry LLC"
        disabled={disabled}
        disableHelperText
        required
      />
      <Stack direction="row" gap="10px" maxWidth="100%">
        <HubHookFormSelect
          name="industry"
          label="Industry"
          placeholder="ex. Oil"
          options={INDUSTRIES}
          sx={{ flexGrow: "1" }}
          disabled={disabled}
          disableHelperText
          required
        />
        <HubHookFormSelect
          name="location"
          label="Foundry"
          defaultValue={userLocation}
          options={FOUNDRIES}
          sx={{ width: "125px" }}
          formControlProps={{ sx: { minWidth: "125px" } }}
          disabled={disabled}
          fullWidth={false}
          disableHelperText
          required
        />
      </Stack>
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  userLocation: getUserLocation(state),
});
export default connect(mapStateToProps)(CreateCompanyDetails);
