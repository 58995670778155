import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { connect } from "react-redux";

import { HubHookFormInput, HubHookFormTimePicker } from "../../components";
import { getPartRouteByName } from "../../redux/routing";

const AutoclaveTableRow = ({ details = {} }) => {
  const preset = `autoclave`;

  return (
    <TableRow
      sx={{
        ".DipRowButton": {
          display: "none",
        },
        "&:hover": {
          ".DipRowButton": {
            display: "inline",
          },
        },
      }}
    >
      <TableCell padding="checkbox">Autoclave</TableCell>
      <TableCell sx={{ minWidth: 50 }}>
        <HubHookFormTimePicker
          name={`${preset}.leadTime`}
          defaultValue={details?.leadTime || "0"}
          placeholder="0.12"
          required
        />
      </TableCell>
      <TableCell sx={{ minWidth: 150 }}>
        <HubHookFormInput
          name={`${preset}.notes`}
          defaultValue={details?.notes}
        />
      </TableCell>
    </TableRow>
  );
};

const mapStateToProps = (state) => ({
  details: getPartRouteByName(state, "autoclave"),
});

export default connect(mapStateToProps)(AutoclaveTableRow);
