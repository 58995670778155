import React from "react";
import { connect } from "react-redux";

import { getPartsMeta } from "../../redux/partsTable";
import { HubPagination } from "../../components";

const PartsTablePagination = ({ meta }) => {
  return meta?.count > 25 ? <HubPagination {...meta} /> : <></>;
};

const mapStateToProps = (state) => ({
  meta: getPartsMeta(state),
});
export default connect(mapStateToProps)(PartsTablePagination);