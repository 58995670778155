import React, { useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { usePostCustomerData } from "../../hooks";

import {
  HubCancelSubmitGroup,
  HubDialogContent,
  HubHookFormWrapper,
} from "../../components";
import CreateCompanyForm from "./CreateCompanyForm";
import { useDialogContext } from "../HubDialogWrapper";

const CreateCompanyContent = () => {
  const navigate = useNavigate();
  const {
    mutate: postCustomerData,
    isLoading,
    isSuccess,
    data,
  } = usePostCustomerData();

  const { onClose = () => {} } = useDialogContext();

  useEffect(() => {
    if (isSuccess && data?.id) {
      onClose();
      navigate(`/customers/${data?.id}`);
    }
  }, [isSuccess]);

  const onSubmit = (formData) => {
    const payload = {
      name: formData.customerName,
      industry: formData.industry,
      location: formData.location,
      active: true,
      contacts: [
        {
          name: formData.contactName,
          cellNumber: formData.phone,
          email: formData.email,
        },
      ],
    };
    postCustomerData(payload);
  };

  return (
    <HubDialogContent largePadding>
      <HubHookFormWrapper onSubmit={onSubmit}>
        <Stack gap="35px">
          <Stack gap="10px" alignItems="center">
            <Typography variant="h6">Create a New Customer</Typography>
            <Typography
              variant="caption"
              sx={{ textAlign: "center", maxWidth: "450px" }}
            >
              Creating a new customer will start the quoting process
            </Typography>
          </Stack>
          <CreateCompanyForm disabled={isLoading} />
          <HubCancelSubmitGroup
            disabled={isLoading}
            isLoading={isLoading}
            label="Create Customer"
          />
        </Stack>
      </HubHookFormWrapper>
    </HubDialogContent>
  );
};

export default CreateCompanyContent;
