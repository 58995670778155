import auth from "./auth";
import dialogs from "./dialogs";
import customers from "./customers";
import part from "./part";
import customerData from "./customerData";
import partsTable from "./partsTable";
import purchaseOrderData from "./purchaseOrder";
import orders from "./orders";
import editPurchaseOrder from "./editPurchaseOrder";
import partRoutings from "./routing";

const moduleReducers = {
  auth,
  dialogs,
  customers,
  part,
  partsTable,
  customerData,
  purchaseOrderData,
  orders,
  editPurchaseOrder,
  partRoutings,
};

export default moduleReducers;
