import React from "react";
import PropTypes from "prop-types";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

import { HubIcon } from "../HubIcon";
import { StyledMenuItem } from "./styles";
import { Divider, Typography } from "@mui/material";

export const HubContextMenuItem = ({
  item,
  onSelect,
  optionalLabelValue = "label",
  ...rest
}) => {
  const onItemSelect = (e) => {
    onSelect && onSelect(e, item);
  };
  if (item?.divider) return <Divider orientation="horizontal" flexItem />;
  if (item?.title)
    return (
      <Typography
        variant="body2"
        sx={{
          fontWeight: 600,
          color: item?.color || "hubColors.mainText",
          padding: "8px 15px",
        }}
      >
        {item?.title}
      </Typography>
    );

  return (
    <StyledMenuItem
      key={item?.id}
      color={item?.color}
      onClick={onItemSelect}
      {...rest}
    >
      {item?.icon && (
        <ListItemIcon>
          <HubIcon icon={item?.icon} />
        </ListItemIcon>
      )}
      <ListItemText>{item[optionalLabelValue]}</ListItemText>
    </StyledMenuItem>
  );
};

HubContextMenuItem.propTypes = {
  item: PropTypes.object,
  onSelect: PropTypes.func,
  optionalLabelValue: PropTypes.string,
};

export default HubContextMenuItem;
