import React from "react";
import { Stack } from "@mui/material";
import { connect } from "react-redux";

import { HubHookFormInput, HubHookFormSelect } from "../../components";
import { getPartMaterials } from "../../redux/part";
import HubHookCustomersSelect from "../../components/HubHookCustomersSelect/HubHookCustomersSelect";

const columnGap = "10px";
const rowGap = "20px";

const CreateCustomerPartFromFields = ({
  requestCustomer = false,
  materials = [],
  weight = 0,
  required = true,
  direction = "row",
  sx = {},
}) => {
  return (
    <Stack gap={rowGap} width="100%" sx={sx}>
      {requestCustomer && <HubHookCustomersSelect required />}
      <Stack direction={direction} gap={columnGap}>
        <HubHookFormInput
          name="partName"
          label="Part Name"
          placeholder="ex. Pocket"
          required={required}
        />
        <HubHookFormInput
          name="partNumber"
          label="Part Number"
          placeholder="ex. 29-100C"
          required={required}
        />
      </Stack>
      <Stack direction={direction} gap={columnGap} sx={{ display: "none" }}>
        <HubHookFormSelect
          name="materialId"
          label="Metal Alloy"
          options={materials}
          defaultValue={materials[0]?.id}
          required={required}
        />
        <HubHookFormInput
          name="weight"
          label="Part Weight"
          placeholder="ex. 4.09"
          defaultValue={weight}
        />
      </Stack>
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  materials: getPartMaterials(state),
});

export const CreateCustomerPartFrom = connect(mapStateToProps)(
  CreateCustomerPartFromFields
);
