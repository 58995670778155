import React from "react";
import PropTypes from "prop-types";
import DialogContent from "@mui/material/DialogContent";

export const HubDialogContent = ({
  disablePadding,
  largePadding,
  smPadding,
  sx,
  children,
  ...rest
}) => {
  const disabledPadding = {
    padding: "0px !important",
  };
  const smallPadding = {
    padding: "15px !important",
  };
  const midPadding = {
    padding: "35px !important",
  };
  const largedPadding = {
    padding: "50px !important",
  };

  const styles = {
    ...midPadding,
    ...(disablePadding ? disabledPadding : {}),
    ...(smPadding ? smallPadding : {}),
    ...(largePadding ? largedPadding : {}),
    ...sx,
  };

  return (
    <DialogContent sx={styles} {...rest}>
      {children}
    </DialogContent>
  );
};

HubDialogContent.propTypes = {
  sx: PropTypes.shape({}),
  disablePadding: PropTypes.bool,
  children: PropTypes.node,
};

export default HubDialogContent;
