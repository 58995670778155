import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";

export const StyledPageStack = styled(Stack)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "calc(100vw - 445px) 400px",
  gap: "15px",
  height: "100%",
  boxSizing: "border-box",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "calc(100vw - 45px)",
  },
}));

export const StyledSubStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}));
