import React from "react";
import { connect } from "react-redux";

import { getPartMaterials, getPartTrees } from "../../redux/part";
import { HubHookFormSelect } from "../HubHookFormSelect";

const HubMaterialHookSelectField = ({
  trees = [],
  materials = [],
  type = "materials",
  name = "material",
  label = "Material",
  defaultValue,
  hideLabel = false,
  dispatch = () => {},
  ...rest
}) => {
  const options = {
    materials,
    trees,
  };

  const list = options[type] || materials;
  const defaultInput = defaultValue || list[0]?.id;

  return (
    <HubHookFormSelect
      name={name}
      defaultValue={defaultInput}
      options={options[type] || materials}
      disableHelperText
      required
      {...(!hideLabel ? { label } : {})}
      {...rest}
    />
  );
};

const mapStateToProps = (state) => ({
  trees: getPartTrees(state),
  materials: getPartMaterials(state),
});
export const HubMaterialHookSelect = connect(mapStateToProps)(
  HubMaterialHookSelectField
);
