import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { connect } from "react-redux";

import { DIP_INGREDIENTS as DIPS } from "../../constants";
import {
  HubButton,
  HubHookFormInput,
  HubHookFormSwitch,
  HubHookFormTimePicker,
  HubMaterialHookSelect,
} from "../../components";
import { removePartDip } from "../../redux/routing";

const DipsTableRow = ({
  id = "",
  idx = 0,
  dip = {},
  _removePartDip = () => {},
}) => {
  const handleRemove = () => _removePartDip(idx);
  const preset = `dip.dips.${id}`;

  return (
    <TableRow
      sx={{
        ".DipRowButton": {
          display: "none",
        },
        "&:hover": {
          ".DipRowButton": {
            display: "inline",
          },
        },
      }}
    >
      <TableCell padding="checkbox">
        <HubHookFormSwitch name={`${preset}.prewet`} isCheckbox />
      </TableCell>
      <TableCell sx={{ minWidth: 150 }}>
        <HubMaterialHookSelect
          defaultValue={dip?.materialId || DIPS[0].id}
          name={`${preset}.materialId`}
          hideLabel
          required
        />
      </TableCell>
      <TableCell sx={{ minWidth: 50 }}>
        <HubHookFormTimePicker
          name={`${preset}.cycleTime`}
          defaultValue={dip?.cycleTime}
          required
        />
      </TableCell>
      <TableCell sx={{ minWidth: 50 }}>
        <HubHookFormTimePicker
          name={`${preset}.leadTime`}
          defaultValue={dip?.leadTime || 1442}
          onlyDays
          required
        />
      </TableCell>
      <TableCell sx={{ minWidth: 50 }}>
        <HubHookFormInput
          name={`${preset}.order`}
          defaultValue={dip?.order}
          placeholder="124"
          isNumber
        />
      </TableCell>
      <TableCell padding="checkbox">
        <HubButton
          className="DipRowButton"
          icon="Trash2"
          variant="text"
          iconProps={{ size: 14 }}
          sx={{ color: "hubColors.redMain" }}
          onClick={handleRemove}
        />
      </TableCell>
    </TableRow>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  _removePartDip: removePartDip,
};
export default connect(mapStateToProps, mapDispatchToProps)(DipsTableRow);
