import React from "react";
import { Box, Stack, Typography } from "@mui/material";

import { DataRateChange, HubIcon } from "../../components";
import { StyledChip, StyledPaper } from "./styles";
import { getOrdersPeriod } from "../../redux";
import { connect } from "react-redux";
import { PERIODS, PERIOD_LABEL } from "../../constants";

const getFlow = (value) =>
  value > 75 ? "green" : value > 50 ? "yellow" : "red";

const JobsBarChartTooltip = ({
  axisValue: title = "",
  dataIndex: index = 0,
  series = [],
  period = PERIODS.weekly,
}) => {
  const items = series?.map((i) => ({
    color: i?.color,
    label: i?.label,
    value: i.valueFormatter(i.data[index]),
    percent: Math.floor(i.data[index] * 100) / 100,
    rate:
      index != 0
        ? Math.floor((i.data[index] - i.data[index - 1]) * 100) / 100
        : undefined,
  }));

  const since = PERIOD_LABEL[period.toLowerCase()];

  return (
    <StyledPaper>
      <Stack gap="20px">
        <Stack direction="row" gap="5px" alignItems="center">
          <HubIcon icon="Calendar" size={12} />
          <Typography variant="boldCaption">{title}</Typography>
        </Stack>
        <Stack gap="20px">
          {items?.map(({ color, label, value, percent, rate }, index) => (
            <Stack
              direction="row"
              gap="10px"
              alignItems="center"
              height="100%"
              key={index}
            >
              <Box
                width="4px"
                height="50px"
                borderRadius="3px"
                backgroundColor={color}
              />
              <Stack gap="2px">
                <Stack gap="5px" direction="row" alignItems="center">
                  <Typography
                    variant="boldCaption"
                    sx={{ fontWeight: 400, fontSize: 12 }}
                  >
                    {label}
                  </Typography>
                  <StyledChip
                    variant="outlinedTag"
                    flow={getFlow(percent)}
                    label={`${percent} %`}
                  />
                </Stack>
                <Typography>{value}</Typography>
                {!isNaN(rate) && (
                  <DataRateChange rate={rate} label={`Since Last ${since}`} />
                )}
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </StyledPaper>
  );
};

const mapStateToProps = (state) => ({
  period: getOrdersPeriod(state),
});
export default connect(mapStateToProps)(JobsBarChartTooltip);
