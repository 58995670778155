import dayjs from "dayjs";

export const PERIOD_OPTIONS = [
  {
    id: "weekly",
    label: "Weekly",
  },
  {
    id: "monthly",
    label: "Monthly",
  },
  // {
  //   id: "quarterly",
  //   label: "Quarterly",
  // },
  {
    id: "yearly",
    label: "Yearly",
  },
];

export const PERIOD_DEFAULTS = {
  weekly: dayjs().startOf("week").subtract("1", "week").format("MM/DD/YYYY"),
  monthly: dayjs().startOf("month").subtract("2", "month").format("MM/DD/YYYY"),
  quarterly: dayjs().format("MM/DD/YYYY"),
  yearly: dayjs().startOf("year").subtract("1", "year").format("MM/DD/YYYY"),
};

export const PERIODS = {
  weekly: "weekly",
  monthly: "monthly",
  quarterly: "quarterly",
  yearly: "yearly",
};

export const PERIOD_LABEL = {
  weekly: "week",
  monthly: "month",
  quarterly: "quarter",
  yearly: "year",
};

export const PERIOD_STEPS = {
  weekly: 6,
  monthly: 5,
  quarterly: 4,
  yearly: 3,
};

export const MAX_STEPS = 12;

export const validatePeriodPayload = ({ period, startDate, endDate }) => {
  // Correct period key if not valid
  const key = PERIODS[period] || PERIODS.weekly;
  const label = PERIOD_LABEL[key];

  // Check if days are valid
  if (!dayjs(startDate).isValid()) startDate = PERIOD_DEFAULTS[key];
  if (!endDate || !dayjs(endDate).isValid()) {
    endDate = dayjs(startDate)
      .add(PERIOD_STEPS[key], label)
      .format("MM/DD/YYYY");
  }

  // Ensure the start and end dates are at the start of the specified period
  let start = dayjs(startDate).startOf(label);
  let end = dayjs(endDate).startOf(label);

  // Check if the end date is before the start date and correct if necessary
  // Add default to start to make end
  if (end.isBefore(start)) end = start.add(PERIOD_STEPS[key], label);

  // Calculate the interval and adjust the end date if it exceeds 12 periods
  // or is less/equal to one interval.
  const diff = end.diff(start, label);
  if (diff > MAX_STEPS) end = start.add(MAX_STEPS, label);
  else if (diff <= 1) end = start.add(PERIOD_STEPS[key], label);

  const steps = end.diff(start, label);

  return {
    period: PERIODS[key],
    startDate: start.format("MM/DD/YYYY"),
    endDate: end.format("MM/DD/YYYY"),
    steps,
  };
};
