import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  HubCardStack,
  HubHookFormInput,
  HubTabs,
  HubButton,
} from "../../components";

export const StyledNavStack = styled(Stack)(({ theme }) => ({
  justifyContent: "space-between",
  gap: "15px",
  padding: "25px",
  borderRight: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  backgroundColor: theme.palette.hubColors.white,
}));

export const StyledHubButton = styled(HubButton, {
  shouldForwardProp: (prop) => !["active"].includes(prop),
})(({ active, theme }) => ({
  fontSize: 12,
  textWrap: "nowrap",
  justifyContent: "start",
  padding: "5px 15px",
  "& .MuiStack-root": {
    gap: "5px",
  },
  marginLeft: "-12px",
  ...(active
    ? {
        borderRadius: 0,
        borderLeft: "3px solid",
        borderColor: theme.palette.hubColors.mainFocus,
        paddingLeft: "12px",
      }
    : {
        color: theme.palette.hubColors.greyShade,
      }),
}));

export const StyledCheckboxStack = styled(Stack)({
  display: "grid",
  gridTemplateColumns: "1fr",
});

export const StyledHubHookFormInput = styled(HubHookFormInput)(({ theme }) => ({
  backgroundColor: theme.palette.hubColors.mainFocusLightest,
  borderColor: theme.palette.hubColors.mainFocusLight,
  fieldset: {
    borderColor: theme.palette.hubColors.mainFocusLight,
  },
}));

export const StyledTitleStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: "25px",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px",
  borderBottom: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  backgroundColor: theme.palette.hubColors.white,
}));

export const StyledHubTabs = styled(HubTabs)({
  "&.MuiBox-root": {
    display: "flex",
    justifyContent: "center",
    width: "50%",
    margin: "0 auto",
  },
});

export const StyledHubCardStack = styled(HubCardStack, {
  shouldForwardProp: (prop) => !["disableMx"].includes(prop),
})(({ disableMx }) => ({
  gap: "25px",
  width: "100%",
  minWidth: "700px",
  height: "fit-content",
  // minHeight: "100%",
  ...(disableMx ? {} : { margin: "auto" }),
}));

export const StyledDisplayStack = styled(Stack)({
  gap: "25px",
  padding: "25px",
  width: "100%",
  overflow: "auto",
  scrollbarGutter: "stable",
});

export const StyledCardAndMenuStack = styled(Stack)({
  flexDirection: "row",
  width: "100%",
  height: "calc(100% - 51px);",
  overflow: "hidden",
});

export const StyledDisplayGrid = styled(Stack)(({ theme }) => ({
  height: "100%",
  width: "100%",
  gap: "25px",

  display: "grid",
  gridTemplateColumns: "1fr minmax(700px, auto) 1fr",

  [theme.breakpoints.down(1200)]: {
    height: "fit-content",
    display: "flex",
    flexDirection: "column-reverse",
    justifyContent: "start",
    "& .space-div": {
      display: "none",
    },
  },
}));
