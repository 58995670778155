import React from "react";
import { TableBody } from "@mui/material";
import { connect } from "react-redux";

import RoutingTableRow from "./RoutingTableRow";
import { getPartRoutings } from "../../redux/routing";
import { ROUTINGS as OPTIONS } from "../../constants";
import RoutingHiddenRows from "./RoutingHiddenRows";

const RoutingTableBody = ({ routings = [] }) => {
  const shown = OPTIONS?.filter((route) => {
    if (routings?.includes(route.id)) return true;
    if (route?.id === 2 && (routings?.includes(2) || routings?.includes(4)))
      return true;
  });
  const hasHidden = shown?.length !== OPTIONS?.length;

  return (
    <TableBody>
      {shown?.map((route) => (
        <RoutingTableRow key={route.id + "shown"} route={route} />
      ))}
      {hasHidden && <RoutingHiddenRows />}
    </TableBody>
  );
};

const mapStateToProps = (state) => ({
  routings: getPartRoutings(state),
});
export default connect(mapStateToProps)(RoutingTableBody);
