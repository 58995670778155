import React, { useEffect, useState } from "react";
import { Table, TableContainer } from "@mui/material";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

import {
  getOrdersEndDate,
  getOrdersPeriod,
  getOrdersStartDate,
  getUserLocation,
  setJobOrders,
} from "../../redux";
import { useGetAllJobData } from "../../hooks";
import { HubCircularProgress } from "../../components";

import JobsTableHeader from "./JobsTableHeader";
import JobsTableBody from "./JobsTableBody";
import JobsTablePagination from "./JobsTablePagination";

const Jobs = ({
  defaultLocation,
  per,
  start,
  end,
  _setJobOrders = () => {},
}) => {
  const { search } = useLocation();
  const [hasFetched, setHasFetched] = useState(false);

  const {
    data,
    isSuccess,
    isLoading = true,
  } = useGetAllJobData({
    defaultLocation,
    per,
    start,
    end,
  });

  useEffect(() => {
    if (isSuccess) {
      _setJobOrders(data);
      setHasFetched(true);
    }
  }, [data, isSuccess, search?.toString()]);

  return isLoading && !hasFetched ? (
    <HubCircularProgress centerScreen />
  ) : (
    <>
      <TableContainer>
        <Table id="JobsTable">
          <JobsTableHeader />
          <JobsTableBody />
        </Table>
      </TableContainer>
      <JobsTablePagination />
    </>
  );
};

const mapStateToProps = (state) => ({
  defaultLocation: getUserLocation(state),
  per: getOrdersPeriod(state),
  start: getOrdersStartDate(state),
  end: getOrdersEndDate(state),
});

const mapDispatchToProps = {
  _setJobOrders: setJobOrders,
};

export const JobsTable = connect(mapStateToProps, mapDispatchToProps)(Jobs);
