export const TravelerHtml = ({
  hours,
  dueDate,
  orderDate,
  shipDate,
  heatDate,
  quoteName,
  poNumber,
  lineItem,
  woNumber,
  customerName,
  partName,
  partNumber,
  alloyName,
  quantity,
  cavitiesPerMold,
  lotNumber,
  shellTemp,
  metalTemp,
  qtyInStock,
  strainer,
  waxCycleTime,
  shelfNumber,
  waxInjectionTemp,
  bottomPlatenTemp,
  waxInjectionPressure,
  minCycleIncludingGate,
  partsPerHour,
  crucibleCapacity,
  treesPerHeat,
  numberHeats,
  cupSize,
  setup,
  partsPerTree,
  treesReq,
  treesPerCluster,
  numClusters,
  zirconDips,
  sandADips,
  sandBDips,
  partsPerLot,
  day1Dips,
  day2Dips,
  day3Dips,
  day4Dips,
  day5Dips,
  day6Dips,
  day7Dips,
  day8Dips,
  day9Dips,
  weight,
  shouldHeatTreat,
  heatTreatPartner,
  topPlatenTemp,
  flowRate,
  injectionTime,
  holdTime,
  barcode
}) => {
  return `
<html>
  <head>
    <style>
      @media print {
        .no-print {
          display: none !important;
          visibility: visible !important;
        }

        .print-area,
        .print-area * {
          visibility: visible !important;
          width: 100% !important;
          height: auto !important;
          z-index: 999;
        }

        .print-area {
          position: absolute;
          left: 0 !important;
          top: 0 !important;
          width: 100%;
          height: 100%;
          padding: 15px 15px 15px 0;
        }

        @page {
          margin: 0 1cm !important;
          size: auto;
        }
      }

      .main {
        height: 11in;
        width: 8.5in;
        position: fixed;
        margin: auto;
        z-index: 99;
        transform: scale(1.1);
      }

      tr {
        height: 16px;
        padding-left: 10px;
      }

      td {
        padding: 0px 10px;
        color: black;
        font-size: 8pt !important;
        font-weight: 400;
        font-style: normal;
        text-decoration: none;
        font-family: Courier, monospace;
        text-align: left;
        vertical-align: middle;
        border: none;
        white-space: nowrap;
        line-height: 16px;
      }

      .new-mono {
        font-family: "Courier New", monospace;
      }

      .border-half-b {
        border-bottom: 0.5pt solid black;
      }
      .margin-r {
        padding-left: 2pt !important;
      }
      .border-half-t {
        border-top: 0.5pt solid black;
      }

      .border-half-l {
        border-left: 0.5pt solid black;
      }

      .border-half {
        border: 0.5pt solid black;
      }

      .hrs-box {
        font-weight: 700;
        font-family: "Times New Roman", serif;
        border-top: 0.5pt solid black;
        border-left: 0.5pt solid black;
        text-align: center;
      }

      .customer-name {
        font-size: 10pt;
      }

      .w-700 {
        font-weight: 700;
      }

      .grey-box {
        border: 0.5pt solid black;
        background: #d9d9d9;
      }

      .red-text {
        color: red;
      }

      .changes-header {
        font-size: 8pt;
        font-family: Courier;
        border-bottom: 2px solid black;
        text-align: center;
        font-style: italic;
        padding-left: 0;
        margin-left: 0;
      }
    </style>
  </head>
  <body class="main print-area">
    <table
      border="0"
      cellpadding="0"
      cellspacing="0"
      width="1708"
      style="border-collapse: collapse; table-layout: fixed; width: 11pt"
    >
      <col class="new-mono" style="width: 41pt" />
      <col class="new-mono" style="width: 25pt" />
      <col class="new-mono" style="width: 18pt" />
      <col class="new-mono" style="width: 32pt" />
      <col class="new-mono" style="width: 38pt" />
      <col class="new-mono" style="width: 32pt" />
      <col class="new-mono" style="width: 18pt" />
      <col class="new-mono" style="width: 26pt" />
      <col class="new-mono" style="width: 18pt" />
      <col class="new-mono" style="width: 47pt" />
      <col class="new-mono" style="width: 26pt" />
      <col class="new-mono" style="width: 106pt" />
      <col class="new-mono" style="width: 18pt" />
      <col class="new-mono" style="width: 38pt" />
      <col class="new-mono" style="width: 33pt" />
      <col class="new-mono" style="width: 38pt" />
      <col class="new-mono" style="width: 25pt" />
      <tr>
        <td class="red-text hrs-box" align="center" style="width: 41pt">
          ${hours}
        </td>
        <td class="hrs-box" style="width: 25pt">Hrs</td>
        <td class="border-half-l" style="width: 18pt">&gt;</td>
        <td style="width: 32pt">RT</td>
        <td style="width: 38pt"></td>
        <td class="text-decoration-underline" colspan="6" style="width: 273pt">
          INTERMOUNTAIN PRECISION CASTING, INC.
        </td>
        <td style="width: 18pt"></td>
        <td style="width: 38pt"></td>
        <td style="width: 33pt"></td>
        <td style="width: 38pt"></td>
        <td style="width: 25pt"></td>
      </tr>
      <tr>
        <td class="border-half-t new-mono" colspan="2">ORDER DATE</td>
        <td colspan="3"></td>
        <td><span>&nbsp;</span></td>
        <td colspan="8"></td>
        <td class="new-mono margin-r">DUE</td>
        <td colspan="2">${dueDate}</td>
        <td></td>
      </tr>
      <tr>
        <td class="new-mono" colspan="2">${orderDate}</td>
        <td colspan="5"></td>
        <td class="text-decoration-underline" align="center">WORK SHEET</td>
        <td colspan="6"></td>
        <td class="new-mono margin-r">SHIP</td>
        <td class="border-half-l border-half-t">${shipDate}</td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td colspan="9"></td>
        <td><span>&nbsp;</span></td>
        <td colspan="4"></td>
        <td class="new-mono margin-r">H.T.</td>
        <td class="border-half-l" colspan="2">${heatDate}</td>
        <td></td>
      </tr>
      <tr>
        <td class="new-mono" colspan="3">START DATE</td>
        <td colspan="4"></td>
        <td class="new-mono text-center" colspan="2">${quoteName}</td>
        <td colspan="5"></td>
        <td class="new-mono border-half-t margin-r">P.O.#</td>
        <td class="border-half-t border-half-l" colspan="2">${poNumber}</td>
      </tr>
      <tr>
        <td class="new-mono" colspan="3">__________</td>
        <td class="new-mono" colspan="5">BY_________</td>
        <td><span>&nbsp;</span>|</td>
        <td colspan="5"></td>
        <td class="new-mono margin-r">LINE:</td>
        <td class="border-half-l new-mono text-center">${lineItem}</td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td colspan="8"></td>
        <td class="new-mono"><span>&nbsp;</span>|</td>
        <td colspan="5"></td>
        <td class="new-mono margin-r">W.O.#</td>
        <td colspan="2">${woNumber}</td>
        <td></td>
      </tr>
      <tr>
        <td class="new-mono" colspan="4">CUSTOMER------:</td>
        <td class="customer-name new-mono" colspan="4">${customerName}</td>
        <td><span>&nbsp;</span>|</td>
        <td class="fst-italic text-decoration-underline" colspan="6">
          POURING DEPARTMENT
        </td>
      </tr>
      <tr>
        <td class="new-mono" colspan="4">PART NAME-----:</td>
        <td class="new-mono" colspan="4">${partName}</td>
        <td class="new-mono"><span>&nbsp;</span>|</td>
        <td class="new-mono" colspan="6">
          Poured BY _________________Date______________
        </td>
      </tr>
      <tr>
        <td class="new-mono" colspan="4">PART NUMBER---:</td>
        <td colspan="4" class="new-mono">${partNumber}</td>
        <td class="new-mono"><span>&nbsp;</span>|</td>
        <td class="new-mono" colspan="2">
          METAL-------------:__________________________
        </td>
        <td class="new-mono"></td>
        <td class="new-mono text-center" colspan="4">${alloyName}</td>
      </tr>
      <tr>
        <td class="new-mono" colspan="4">TOTAL ORDER---:</td>
        <td>${quantity}</td>
        <td colspan="2" class="new-mono text-right">Cav. Mold:</td>
        <td class="grey-box new-mono text-center">${cavitiesPerMold}</td>
        <td class="new-mono"><span>&nbsp;</span>|</td>
        <td class="new-mono" colspan="7">
          HEAT NUMBER-------:__________________________
        </td>
      </tr>
      <tr>
        <td class="new-mono" colspan="4">LOT NUMBER----:</td>
        <td colspan="4">${lotNumber}</td>
        <td class="new-mono"><span>&nbsp;</span>|</td>
        <td class="new-mono" colspan="7">
          SUPPLIER----------:__________________________
        </td>
      </tr>
      <tr>
        <td class="new-mono" colspan="4">PARTS PER LOT-:</td>
        <td colspan="4">${partsPerLot}</td>
        <td class="new-mono"><span>&nbsp;</span>|</td>
        <td class="new-mono" colspan="2">
          BURN OUT TEMP-----:__________________________
        </td>
        <td class="new-mono text-center" colspan="4">${shellTemp}</td>
      </tr>
      <tr>
        <td class="new-mono" colspan="4">BARCODE-------:</td>
        <td colspan="4" class="mono-new">${barcode}</td>
        <td class="new-mono"><span>&nbsp;</span>|</td>
        <td class="new-mono" colspan="2">
          POURING TEMP------:__________________________
        </td>
        <td class="new-mono text-center" colspan="4">${metalTemp}</td>
      </tr>
      <tr>
        <td colspan="4"></td>
        <td
          class="border-half-b text-center border-top-0 border-right-0 border-left-0 new-mono"
        >
          ${qtyInStock}
        </td>
        <td class="new-mono" colspan="3">Pcs. on Stock</td>
        <td class="new-mono"><span>&nbsp;</span>|</td>
        <td class="new-mono" colspan="2">
          STRAINER USED-----:__________________________
        </td>
        <td class="new-mono text-center" colspan="4">${strainer}</td>
      </tr>
      <tr>
        <td><span>&nbsp;</span></td>
        <td colspan="7"></td>
        <td class="new-mono"><span>&nbsp;</span>|</td>
        <td class="fst-italic" colspan="3">ALLOYING ELEMENTS ADDED:</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td colspan="3" class="fst-italic text-decoration-underline">
          WAX DEPARTMENT
        </td>
        <td class="grey-box new-mono" colspan="2">
          ${waxCycleTime}
        </td>
        <td class="new-mono" colspan="3">Min. IPC CYCLE TIME</td>
        <td class="new-mono"><span>&nbsp;</span>|</td>
        <td class="new-mono" colspan="6">
          Fe Si-------------:________________
        </td>
        <td class="text-center">gr.</td>
      </tr>
      <tr>
        <td class="w-700 new-mono" colspan="3">Shelf Number--:</td>
        <td class="border-half new-mono text-center" colspan="2">
          ${shelfNumber}
        </td>
        <td></td>
        <td class="changes-header w-100">Changes</td>
        <td></td>
        <td class="new-mono"><span>&nbsp;</span>|</td>
        <td class="new-mono" colspan="6">
          Fe Mn-------------:________________
        </td>
        <td class="text-center">gr.</td>
      </tr>
      <tr>
        <td colspan="2" class="border-half-b new-mono">
          ${waxInjectionTemp}
        </td>
        <td class="new-mono" colspan="5">Wax Temp</td>
        <td class="border-half-b">&nbsp;</td>
        <td class="new-mono"><span>&nbsp;</span>|</td>
        <td class="new-mono" colspan="6">
          Cr----------------:________________
        </td>
        <td class="text-center">gr.</td>
      </tr>
      <tr>
        <td colspan="2" class="border-half-b new-mono">${topPlatenTemp}</td>
        <td class="new-mono" colspan="5">Top Platten Temp</td>
        <td class="border-half-b">&nbsp;</td>
        <td class="new-mono"><span>&nbsp;</span>|</td>
        <td class="new-mono" colspan="6">
          Ni----------------:________________
        </td>
        <td class="text-center">gr.</td>
      </tr>
      <tr>
        <td colspan="2" class="border-half-b new-mono">
          ${bottomPlatenTemp}
        </td>
        <td class="new-mono" colspan="5">Bottom Platten Temp</td>
        <td class="border-half-b">&nbsp;</td>
        <td class="new-mono"><span>&nbsp;</span>|</td>
        <td class="new-mono" colspan="6">
          Mo----------------:________________
        </td>
        <td class="text-center">gr.</td>
      </tr>
      <tr>
        <td colspan="2" class="border-half-b new-mono">${flowRate}</td>
        <td class="new-mono" colspan="5">Flow Rate</td>
        <td class="border-half-b">&nbsp;</td>
        <td class="new-mono"><span>&nbsp;</span>|</td>
        <td class="new-mono" colspan="6">
          Carbon------------:________________
        </td>
        <td class="text-center">gr.</td>
      </tr>
      <tr>
        <td colspan="2" class="border-half-b new-mono">
          ${waxInjectionPressure}
        </td>
        <td class="new-mono" colspan="5">Injection Pressure</td>
        <td class="border-half-b">&nbsp;</td>
        <td class="new-mono"><span>&nbsp;</span>|</td>
        <td class="new-mono" colspan="6">
          Fe Ti-------------:________________
        </td>
        <td class="text-center">gr.</td>
      </tr>
      <tr>
        <td colspan="2" class="border-half-b new-mono">${injectionTime}</td>
        <td class="new-mono" colspan="5">Injection Time</td>
        <td class="border-half-b">&nbsp;</td>
        <td class="new-mono"><span>&nbsp;</span>|</td>
        <td class="new-mono" colspan="6">
          Fe V--------------:________________
        </td>
        <td class="text-center">gr.</td>
      </tr>
      <tr>
        <td colspan="2" class="border-half-b new-mono">${holdTime}</td>
        <td class="new-mono" colspan="5">Hold Time</td>
        <td class="border-half-b">&nbsp;</td>
        <td class="new-mono"><span>&nbsp;</span>|</td>
        <td class="new-mono" colspan="6">
          Al----------------:________________
        </td>
        <td class="text-center">gr.</td>
      </tr>
      <tr>
        <td colspan="2" class="border-half-b new-mono">
          ${minCycleIncludingGate}
        </td>
        <td class="new-mono" colspan="5">Min. CYCLE INCLUDING GATE</td>
        <td class="border-half-b">&nbsp;</td>
        <td class="new-mono"><span>&nbsp;</span>|</td>
        <td class="new-mono" colspan="6">
          Graphadox---------:________________
        </td>
        <td class="text-center">gr.</td>
      </tr>
      <tr>
        <td colspan="2" class="border-half-b border-half-t new-mono">
          ${partsPerHour}
        </td>
        <td class="new-mono" colspan="5">PARTS PER HOUR</td>
        <td>&nbsp;</td>
        <td class="new-mono"><span>&nbsp;</span>|</td>
        <td class="new-mono" colspan="4">
          CRUCIBLE CAPACITY-:__________________________
        </td>
        <td class="new-mono">${crucibleCapacity}</td>
        <td></td>
      </tr>
      <tr>
        <td colspan="8"></td>
        <td class="new-mono"><span>&nbsp;</span>|</td>
        <td class="new-mono" colspan="5">
          POUNDS PER HEAT---:__________________________
        </td>
        <td></td>
      </tr>
      <tr>
        <td class="new-mono" colspan="8">
          COMMENTS:_______________________________
        </td>
        <td class="new-mono"><span>&nbsp;</span>|</td>
        <td class="new-mono" colspan="4">
          TREES PER HEAT----:__________________________
        </td>
        <td class="new-mono">${treesPerHeat}</td>
        <td></td>
      </tr>
      <tr>
        <td class="new-mono" colspan="8">
          ________________________________________
        </td>
        <td class="new-mono"><span>&nbsp;</span>|</td>
        <td class="new-mono" colspan="4">
          NUMBER OF HEATS---:__________________________
        </td>
        <td class="red-text">${numberHeats}</td>
        <td></td>
      </tr>
      <tr>
        <td class="fst-italic text-decoration-underline" colspan="4">
          SET UP DEPARTMENT
        </td>
        <td colspan="4"></td>
        <td><span>&nbsp;</span>|</td>
        <td class="new-mono" colspan="6">
          COLOR-------------:Green____Yellow____Red____
        </td>
      </tr>
      <tr>
        <td class="new-mono" colspan="3">ORIENTATION:</td>
        <td colspan="5">&nbsp;</td>
        <td class="new-mono"><span>&nbsp;</span>|</td>
        <td class="new-mono" colspan="6">
          CYCLE TIME--------:_______TO__________Min.
        </td>
      </tr>
      <tr>
        <td class="new-mono" colspan="2">${cupSize}</td>
        <td class="new-mono" colspan="4">SIZE CUP USED</td>
        <td colspan="2"></td>
        <td class="new-mono"><span>&nbsp;</span>|</td>
        <td colspan="6">NUMBER TREES FIT IN KILN:______</td>
      </tr>
      <tr>
        <td class="new-mono" colspan="2">${setup}</td>
        <td class="new-mono" colspan="5">SIZE OF SPRUE</td>
        <td></td>
        <td><span>&nbsp;</span>|</td>
        <td colspan="5">COMMENTS: _______________________________</td>
        <td></td>
      </tr>
      <tr>
        <td class="new-mono">${partsPerTree}</td>
        <td></td>
        <td class="new-mono" colspan="6">PARTS PER TREE</td>
        <td><span>&nbsp;</span>|</td>
        <td colspan="5">________________________________________</td>
        <td></td>
      </tr>
      <tr>
        <td class="red-text">${treesReq}</td>
        <td></td>
        <td class="new-mono" colspan="6">NUMBER OF TREES</td>
        <td><span>&nbsp;</span>|</td>
        <td colspan="5">________________________________________</td>
        <td></td>
      </tr>
      <tr>
        <td class="new-mono">${treesPerCluster}</td>
        <td></td>
        <td class="new-mono" colspan="6">NUMBER OF TREES PER CLUSTERS</td>
        <td><span>&nbsp;</span>|</td>
        <td class="fst-italic text-decoration-underline" colspan="4">
          CLEANUP AND FINISH DEPARTMENT
        </td>
        <td></td>
      </tr>
      <tr>
        <td class="red-text new-mono">${numClusters}</td>
        <td></td>
        <td class="new-mono" colspan="6">NUMBER OF CLUSTERS</td>
        <td><span>&nbsp;</span>|</td>
        <td colspan="5">KNOCK OUT---------:__________________________</td>
        <td></td>
      </tr>
      <tr>
        <td colspan="2">_______</td>
        <td class="new-mono" colspan="6">TIME TO SET UP ONE TREE</td>
        <td><span>&nbsp;</span>|</td>
        <td colspan="5">CUT OFF-----------:_______________per________</td>
        <td></td>
      </tr>
      <tr>
        <td colspan="2">_______</td>
        <td class="new-mono" colspan="6">TIME SPENT CLEANING PARTS</td>
        <td><span>&nbsp;</span>|</td>
        <td colspan="5">SANDBLAST---------:__________________________</td>
        <td></td>
      </tr>
      <tr>
        <td class="new-mono" colspan="8">
          COMMENTS:_______________________________
        </td>
        <td><span>&nbsp;</span>|</td>
        <td colspan="5">GRIND__Small------:__________________________</td>
        <td></td>
      </tr>
      <tr>
        <td class="new-mono" colspan="8">
          ________________________________________
        </td>
        <td><span>&nbsp;</span>|</td>
        <td colspan="5">STRAIGHTEN--------:__________________________</td>
        <td></td>
      </tr>
      <tr>
        <td colspan="7">DATE_________BY______#TREES______</td>
        <td></td>
        <td><span>&nbsp;</span>|</td>
        <td colspan="5">WELD--------------:__________________________</td>
        <td></td>
      </tr>
      <tr>
        <td class="fst-italic text-decoration-underline" colspan="4">
          DIP DEPARTMENT
        </td>
        <td colspan="4">#TREES______BY______</td>
        <td><span>&nbsp;</span>|</td>
        <td colspan="5">DETAIL------------:__________________________</td>
        <td></td>
      </tr>
      <tr>
        <td colspan="8">NUMBER OF DIPS:</td>

        <td><span>&nbsp;</span>|</td>
        <td colspan="5">RE-BLAST----------:__________________________</td>
        <td></td>
      </tr>
      <tr>
        <td></td>
        <td class="text-right"><span>&nbsp;</span>ZIR:</td>
        <td class="text-center text-decoration-underline">
          &nbsp;${zirconDips}
        </td>
        <td class="text-right"><span>&nbsp;</span>A:</td>
        <td class="text-center text-decoration-underline">
          <span>&nbsp;</span>${sandADips}
        </td>
        <td></td>
        <td class="text-right"><span>&nbsp; </span>B:</td>
        <td class="text-center text-decoration-underline">
          <span>&nbsp;</span>${sandBDips}
        </td>
        <td><span>&nbsp;</span>|</td>
        <td colspan="3">SCRAP BUTTOMS?----:</td>
        <td colspan="2">&nbsp;Yes____</td>
        <td colspan="2">No____</td>
      </tr>
      <tr>
        <td></td>
        <td class="text-right">DRY TIME:</td>
        <td class="text-center"><span>&nbsp;&nbsp;&nbsp;&nbsp;</span></td>
        <td></td>
        <td class="text-center text-decoration-underline">
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </td>
        <td></td>
        <td></td>
        <td class="text-center text-decoration-underline">
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </td>
        <td><span>&nbsp;</span>|</td>
        <td class="fst-italic text-decoration-underline" colspan="6">
          INSPECTION DEPARTMENT
        </td>
      </tr>
      <tr>
        <td colspan="8">NUMBER OF DIPS PER DAY:</td>
        <td><span>&nbsp;</span>|</td>
        <td colspan="3">PARTS PER LOT---------:______________________</td>
        <td class="red-text text-center" colspan="3">${partsPerLot}</td>
      </tr>
      <tr>
        <td class="text-right"><span>&nbsp;</span>(1)<span>&nbsp;</span></td>
        <td class="new-mono">${day1Dips}</td>
        <td></td>
        <td><span>&nbsp;</span>(2)</td>
        <td class="new-mono">${day2Dips}</td>
        <td></td>
        <td class="text-right">(3)<span>&nbsp;</span></td>
        <td class="new-mono">&nbsp;${day3Dips}</td>
        <td><span>&nbsp;</span>|</td>
        <td colspan="5">PARTS BROKEN IN DIP---:______________________</td>
        <td></td>
      </tr>
      <tr>
        <td class="text-right"><span>&nbsp;</span>(4)<span>&nbsp;</span></td>
        <td class="new-mono">${day4Dips}</td>
        <td></td>
        <td><span>&nbsp;</span>(5)</td>
        <td class="new-mono">${day5Dips}</td>
        <td></td>
        <td class="text-right">(6)<span>&nbsp;</span></td>
        <td class="new-mono">&nbsp;${day6Dips}</td>
        <td><span>&nbsp;</span>|</td>
        <td colspan="5">NUMBER PARTS REJECTED-:______________________</td>
        <td></td>
      </tr>
      <tr>
        <td class="text-right"><span>&nbsp;</span>(7)<span>&nbsp;</span></td>
        <td class="new-mono" colspan="2">${day7Dips}</td>
        <td><span>&nbsp;</span>(8)</td>
        <td class="new-mono">${day8Dips}</td>
        <td></td>
        <td class="text-right">(9)<span>&nbsp;</span></td>
        <td class="new-mono">&nbsp;${day9Dips}</td>
        <td><span>&nbsp;</span>|</td>
        <td colspan="5">NUMBER PARTS SHIPPED--:______________________</td>
        <td></td>
      </tr>
      <tr>
        <td colspan="8"></td>
        <td><span>&nbsp;</span>|</td>
        <td colspan="3">PART Pcs. WEIGHT------:______________________</td>
        <td colspan="3" class="new-mono text-center">${weight}</td>
        <td></td>
      </tr>
      <tr>
        <td colspan="8">DATE DIPPED:</td>
        <td><span>&nbsp;</span>|</td>
        <td colspan="3">HEAT TREAT --:_______________________________</td>
        <td class="new-mono w-700" colspan="2">&nbsp;${shouldHeatTreat}</td>
      </tr>
      <tr>
        <td colspan="2"><span>&nbsp;</span>#1____</td>
        <td>#2</td>
        <td>____</td>
        <td>#3____</td>
        <td colspan="2">#4____</td>
        <td>#5_____</td>
        <td><span>&nbsp;</span>|</td>
        <td>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>H.T.
          by:_____________________________
        </td>
        <td class="new-mono w-700" colspan="2">
          &nbsp;${heatTreatPartner}
        </td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td colspan="2"><span>&nbsp;</span>#6____</td>
        <td>#7</td>
        <td>____</td>
        <td>#8____</td>
        <td colspan="2">#9____</td>
        <td>#10____</td>
        <td><span>&nbsp;</span>|</td>
        <td colspan="4">TESTED AT------:_____________________________</td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td colspan="2"><span>&nbsp;</span>#11____</td>
        <td colspan="2">#12____</td>
        <td colspan="4"></td>
        <td><span>&nbsp;</span>|</td>
        <td colspan="5">COMMENTS:____________________________________</td>
        <td></td>
      </tr>
      <tr>
        <td class="w-700" colspan="8">SHELL WEIGHT Autoclaved:_____ lbs</td>

        <td><span>&nbsp;</span>|</td>
        <td colspan="5">_____________________________________________</td>
        <td></td>
      </tr>
      <tr>
        <td><span>&nbsp;</span></td>
        <td colspan="7"></td>
        <td><span>&nbsp;</span>|</td>
        <td colspan="5">_____________________________________________</td>
        <td></td>
      </tr>
      <tr>
        <td class="new-mono" colspan="8">
          COMMENTS:_______________________________
        </td>
        <td><span>&nbsp;</span>|</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td class="new-mono" colspan="8">
          ________________________________________
        </td>
        <td><span>&nbsp;</span>|</td>
        <td colspan="5">INSPECTED BY_________________ DATE __________</td>
        <td></td>
      </tr>
      <tr>
        <td class="new-mono" colspan="8">
          ________________________________________
        </td>
        <td><span>&nbsp;</span>|</td>
        <td colspan="5">SHIP WEIGHT________________ # BOXES _________</td>
        <td></td>
      </tr>
      <tr>
        <td class="new-mono" colspan="8">
          ________________________________________
        </td>
        <td><span>&nbsp;</span>|</td>
        <td colspan="5">DATE SHIPPED______________ PACK.SLIP# _______</td>
        <td></td>
      </tr>
      <tr>
        <td class="new-mono" colspan="8">
          ________________________________________
        </td>
        <td colspan="5"></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td colspan="9"></td>
        <td colspan="5">Number pcs. now on inventory_________________</td>
        <td colspan="10"></td>
      </tr>
    </table>
  </body>
</html>`
}