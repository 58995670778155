import React from "react";
import Typography from "@mui/material/Typography";

import SignInForm from "./SignInForm";
import SignInLargeLogo from "./SignInLargeLogo";

import { StyledStack, StyledStackTwo } from "./style.js";
import { FoundryLogo } from "../../svgs";

export const SignIn = ({ signUp = false }) => {
  return (
    <StyledStack>
      <StyledStackTwo>
        <FoundryLogo fill="#525252" size={125} />
        <SignInForm signUp={signUp} />
        <Typography variant="subtitle2" sx={{ fontSize: 12 }}>
          Foundry Group 2023, All Rights Reserved
        </Typography>
      </StyledStackTwo>
      <SignInLargeLogo />
    </StyledStack>
  );
};

export default SignIn;
