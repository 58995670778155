import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  getOrdersEndDate,
  getOrdersPeriod,
  getOrdersStartDate,
  getUserLocation,
  setJobsDashboard,
} from "../../redux";
import { PageContent } from "../../components";
import { useGetJobDashboard } from "../../hooks";
import { JobsBarChart } from "../../containers";

import { StyledChartStack, StyledStack } from "./styles";

import OrdersBar from "./OrdersBar";
import OrdersTableSwitch from "./OrdersTableSwitch";

const Orders = ({
  per,
  start,
  end,
  defaultLocation,
  _setJobsDashboard = () => {},
}) => {
  const { search } = useLocation();
  const [hasFetched, setHasFetched] = useState(false);

  const { data, isSuccess, isLoading } = useGetJobDashboard({
    defaultLocation,
    per,
    start,
    end,
  });

  useEffect(() => {
    if (isSuccess) {
      _setJobsDashboard(data);
      setHasFetched(true);
    }
  }, [data, isSuccess, search?.toString()]);

  return (
    <PageContent gap="15px" isLoading={isLoading && !hasFetched} mediumPadding>
      <OrdersBar />
      <StyledStack>
        <StyledChartStack>
          <JobsBarChart />
        </StyledChartStack>
        <OrdersTableSwitch />
      </StyledStack>
    </PageContent>
  );
};

const mapStateToProps = (state) => ({
  defaultLocation: getUserLocation(state),
  per: getOrdersPeriod(state),
  start: getOrdersStartDate(state),
  end: getOrdersEndDate(state),
});
const mapDispatchToProps = {
  _setJobsDashboard: setJobsDashboard,
};

export const OrdersPage = connect(mapStateToProps, mapDispatchToProps)(Orders);
