import React from "react";
import { Stack, Typography } from "@mui/material";

import { DialogButton } from "../../components";

export const PartsBar = ({ disabled = false }) => {
  return (
    <Stack justifyContent="space-between" alignItems="end" direction="row">
      <Typography variant="boldCaption">Parts</Typography>
      <Stack direction="row" gap="15px">
        <DialogButton
          name="create-customer-part"
          label="Create Part"
          variant="contained"
          size="xsmall"
          disabled={disabled}
        />
      </Stack>
    </Stack>
  );
};

export default PartsBar;
