import React from "react";
import { Stack, Typography } from "@mui/material";

import { DialogButton } from "../../components";

const CustomerContactsBar = ({ disabled = false, onClick = () => {} }) => {
  return (
    <Stack justifyContent="space-between" alignItems="end" direction="row">
      <Typography variant="boldCaption">Contacts</Typography>
      <DialogButton
        name="edit-contact"
        label="Create Company Contact"
        variant="contained"
        size="xsmall"
        disabled={disabled}
        onClick={onClick}
      />
    </Stack>
  );
};

export default CustomerContactsBar;
