import React from "react";
import { Stack, Typography } from "@mui/material";

import { HubDialogWrapper } from "../HubDialogWrapper";
import {
  HubCancelSubmitGroup,
  HubDialogContent,
  HubHookFormWrapper,
} from "../../components";

import RepeatWorkOrderForm from "./RepeatWorkOrderForm";

const name = "repeat-work-order";

export const RepeatWorkOrderDialog = () => {
  const onSubmit = () => {};

  return (
    <HubDialogWrapper maxWidth="xs" name={name}>
      <HubDialogContent>
        <HubHookFormWrapper onSubmit={onSubmit}>
          <Stack gap="35px" alignItems="center">
            <Stack gap="5px">
              <Typography variant="h6">Work Order Recurrence</Typography>
              <Typography variant="caption">
                This work order's quantity, part, and price, will repeat for
                every occruance.
              </Typography>
            </Stack>
            <RepeatWorkOrderForm />
            <HubCancelSubmitGroup label="Repeat Work Order" />
          </Stack>
        </HubHookFormWrapper>
      </HubDialogContent>
    </HubDialogWrapper>
  );
};

export default RepeatWorkOrderDialog;
