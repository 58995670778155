import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

import { useGetAccountDetails } from "../../hooks";
import { getAuthUser, setUser } from "../../redux/auth";
import { useGetMaterialData } from "../../hooks/react-query/useMaterialData";
import { setMaterials } from "../../redux/part";
import { HubCircularProgress } from "../HubCircularProgress";

const AuthenticatedUserComponent = ({
  location,
  children,
  _setUser = () => {},
  _setMaterials = () => {},
}) => {
  const { data, isSuccess, isError } = useGetAccountDetails();
  const { data: materialData } = useGetMaterialData();

  useEffect(() => {
    materialData?.materials?.length && _setMaterials(materialData);
    data?.id && _setUser(data);
  }, [materialData, data]);

  if (isSuccess) return children;
  if (isError) return <Navigate to={{ pathname: "/signin", state: { from: location } }} />;

  return <HubCircularProgress centerScreen />;
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  _setUser: setUser,
  _setMaterials: setMaterials,
};

export const AuthenticatedUser = connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(AuthenticatedUserComponent));
