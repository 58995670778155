import React from "react";

export const Checkbox = ({ color = "#C0C0C0", size = 18 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="22"
        height="22"
        rx="4"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};

export const CheckboxChecked = ({ color = "#DF9E44", size = 18 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="5" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5213 6.85118C19.8458 7.17569 19.8458 7.70184 19.5213 8.02635L10.3806 17.1671C10.0561 17.4916 9.52992 17.4916 9.2054 17.1671L5.05052 13.0122C4.726 12.6877 4.726 12.1616 5.05052 11.837C5.37503 11.5125 5.90118 11.5125 6.2257 11.837L9.79299 15.4043L18.3461 6.85118C18.6707 6.52666 19.1968 6.52666 19.5213 6.85118Z"
        fill="white"
      />
    </svg>
  );
};
