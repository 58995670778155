import React from "react";

import { HubContextMenu } from "../../components";

const MENU_ITEMS = [
  {
    id: "delete",
    label: "Delete",
    icon: "Trash2",
    color: "redError",
  },
];

export const CustomerPartsContextMenu = ({
  id,
  setDeletingId = () => {},
  ...rest
}) => {
  const onSelect = (item) => {
    switch (item?.id) {
      case "delete":
        setDeletingId(id);
        break;
      default:
        break;
    }
  };

  return (
    <HubContextMenu
      id={"parts"}
      loading={false}
      menuItems={MENU_ITEMS}
      onSelect={onSelect}
      IconButtonProps={{
        sx: {
          paddingTop: "0px !important",
          paddingBottom: "0px !important",
        },
      }}
      {...rest}
    />
  );
};

export default CustomerPartsContextMenu;
