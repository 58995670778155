import React from "react";
import { Typography, Stack } from "@mui/material";

const HubTimePickerRender = ({ time, onlyDays }) => {
  return (
    <Stack
      direction="row"
      gap="7px"
      sx={{
        "& .MuiTypography-root": {
          fontSize: 11,
        },
        "&:nth-of-type(odd)": {
          fontWeight: 600,
        },
      }}
    >
      <Typography varian="caption">{time.days || 0}d</Typography>
      {!onlyDays && (
        <>
          <Typography varian="caption">{time.hours || 0}h</Typography>
          <Typography varian="caption">{time.minutes || 0}m</Typography>
          <Typography varian="caption">{time.seconds || 0}s</Typography>
        </>
      )}
    </Stack>
  );
};

export default HubTimePickerRender;
