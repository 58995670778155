import React from "react";
import { Stack } from "@mui/material";

import CreateCompanyDetails from "./CreateCompanyDetails";
import CreateContactPart from "./CreateContactPart";

const CreateCompanyForm = ({ disabled = false }) => {
  return (
    <Stack gap="25px" width="100%">
      <CreateCompanyDetails disabled={disabled} />
      <CreateContactPart disabled={disabled} />
    </Stack>
  );
};

export default CreateCompanyForm;
