import { useMutation, useQuery } from "react-query";

import api from "../../utils/api";
import { useHubSnackBar } from "../useSnackBar";

const ACCOUNT = "/user";

const postSignUpData = (data) => api.post(`${ACCOUNT}/signup`, data);
export const usePostSignUp = () => useMutation(postSignUpData);

const postSignInData = (data) => api.post(`${ACCOUNT}/signin`, data);
export const usePostSignIn = () => {
  const { addSnackbarSuccess } = useHubSnackBar();
  return useMutation(postSignInData, {
    onSuccess: (data) => {
      addSnackbarSuccess({ message: data?.message });
    },
  });
};

const postSignOutData = () => api.post(`${ACCOUNT}/signout`);
export const usePostSignOut = () => {
  const { addSnackbarSuccess } = useHubSnackBar();
  return useMutation(postSignOutData, {
    onSuccess: (data) => {
      addSnackbarSuccess({ message: data?.message });
    },
  });
};

const postCheckEmail = (data) => api.post(`${ACCOUNT}/email`, data);
export const usePostCheckEmail = () =>
  useMutation(postCheckEmail, {
    onSuccess: (data) => {
      return data;
    },
  });

const getAccountDetails = () => api.get(ACCOUNT);
export const useGetAccountDetails = () => {
  return useQuery([ACCOUNT], getAccountDetails);
};
