import { useQuery } from "react-query";
import api from "../../utils/api";

const MATERIALS = "/materials";

const getMaterialData = () => api.get(MATERIALS);
export const useGetMaterialData = () => {
    return useQuery([MATERIALS], getMaterialData, {
        keepPreviousData: true
    });
};
