import { useMutation, useQueryClient } from "react-query";
import api from "../../utils/api";
import { useHubSnackBar } from "../useSnackBar";
import { CUSTOMERS } from "./useCustomerData";

const CONTACTS = "/contacts";

const putUpdateContact = (payload) => {
    const id = payload.id;
    delete payload.id;
    return api.put(`${CONTACTS}/${id}`, payload)
}
export const usePutUpdateContact = () => {
    const { addSnackbarSuccess } = useHubSnackBar();
    const queryClient = useQueryClient();

    return useMutation(putUpdateContact, {
        onSuccess: () => {
            queryClient.invalidateQueries(CUSTOMERS);
            addSnackbarSuccess({ message: "Successfully Updated Contact" });
        },
    });
}

const putUpdateContactPrimary = (payload) => {
    const id = payload.id;
    delete payload.id;
    return api.put(`${CONTACTS}/primary/${id}`, payload)
}
export const usePutUpdateContactPrimary = () => {
    const { addSnackbarSuccess } = useHubSnackBar();
    const queryClient = useQueryClient();

    return useMutation(putUpdateContactPrimary, {
        onSuccess: () => {
            queryClient.invalidateQueries(CUSTOMERS);
            addSnackbarSuccess({ message: "Successfully Updated Contact" });
        },
    });
}

const postUpdateContact = (payload) => api.post(CONTACTS, payload);
export const usePostUpdateContact = () => {
    const { addSnackbarSuccess } = useHubSnackBar();
    const queryClient = useQueryClient();

    return useMutation(postUpdateContact, {
        onSuccess: () => {
            queryClient.invalidateQueries(CUSTOMERS);
            addSnackbarSuccess({ message: "Successfully Created Contact" });
        },
    });
}

const deleteContactById = (id) => api.delete(`${CONTACTS}/${id}`);
export const useDeleteContactById = () => {
    const { addSnackbarSuccess } = useHubSnackBar();
    const queryClient = useQueryClient();

    return useMutation(deleteContactById, {
        onSuccess: () => {
            queryClient.invalidateQueries(CUSTOMERS);
            addSnackbarSuccess({ message: "Successfully Deleted Contact" });
        },
    });
}