import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import {
  setUpdatingOrder,
  getPurchaseOrderDetails,
  getPurchaseOrderItemsLength,
  getPurchaseOrderCustomerParts,
} from "../../redux/editPurchaseOrder";

import { useGetOrderEditData, usePutOrderData } from "../../hooks";
import { HubHookFormWrapper, PageContent } from "../../components";
import { formOrderMapper } from "./helper";

import OrderDetails from "./OrderDetails";
import OrderList from "./OrderList";
import { StyledPageStack } from "./styles";

const OrderPage = ({
  items = 0,
  parts = [],
  details = {},
  _setUpdatingOrder = () => {},
}) => {
  const { id } = useParams();
  const { data, isLoading, isSuccess } = useGetOrderEditData(id);
  const { mutate: putOrderData } = usePutOrderData();

  const onSubmit = (fd) => {
    const customer = details.customer;
    const orderId = parseInt(id);
    const payload = formOrderMapper(orderId, fd, customer, parts, items - 1);

    putOrderData(payload);
  };

  useEffect(() => {
    isSuccess && _setUpdatingOrder(data);
  }, [data]);

  return (
    <PageContent isLoading={isLoading} gap="15px" smallPadding>
      <HubHookFormWrapper onSubmit={onSubmit}>
        <StyledPageStack>
          <OrderList />
          <OrderDetails isLoading={isLoading} />
        </StyledPageStack>
      </HubHookFormWrapper>
    </PageContent>
  );
};

const mapStateToProps = (state) => ({
  details: getPurchaseOrderDetails(state),
  items: getPurchaseOrderItemsLength(state),
  parts: getPurchaseOrderCustomerParts(state),
});
const mapDispatchToProps = {
  _setUpdatingOrder: setUpdatingOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderPage);
