import React from "react";
import { Navigate } from "react-router-dom";

import {
  CompaniesPage,
  PartsPage,
  PartDetailsPage,
  ContactPage,
  OrdersPage,
  WorkOrdersPage,
  WorkOrderDetails,
} from "./pages";
import SignIn from "./pages/SignIn/SignIn";
import OrderPage from "./pages/Order/OrderPage";

const authenticate = true;

export const routes = [
  {
    path: "/",
    element: <Navigate to="/signin" />,
  },
  {
    path: "/signin",
    title: "Foundry Sign In",
    element: <SignIn />,
  },
  {
    path: "/signup",
    title: "Foundry Sign Up",
    element: <Navigate to="/signin" />,
  },
  {
    path: "/customers",
    title: "Foundry Customers",
    element: <CompaniesPage />,
    authenticate,
  },
  {
    path: "/customers/:id",
    title: "Customer Contact",
    element: <ContactPage />,
    authenticate,
  },
  {
    path: "/parts",
    title: "Foundry Group Parts",
    element: <PartsPage />,
    authenticate,
  },
  {
    path: "/parts/:id",
    title: "Part Details",
    element: <PartDetailsPage />,
    authenticate,
  },

  {
    path: "/works",
    title: "Foundry Work Orders",
    element: <WorkOrdersPage />,
    authenticate,
  },
  {
    path: "/works/:id",
    title: "Work Order Details",
    element: <WorkOrderDetails />,
    authenticate,
  },
  {
    path: "/orders",
    title: "Purchase Orders",
    element: <OrdersPage />,
    authenticate,
  },
  {
    path: "/order/:id",
    title: "View Order",
    element: <OrderPage />,
    authenticate,
  },
  {
    path: "/not-found",
    element: <Navigate to="/customers" />,
  },
  {
    path: "*",
    element: <Navigate to="/not-found" />,
  },
];
export default routes;
