import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
// import { valueTypes } from "../../../constants/response_value_types";

export const StyledStack = styled(Stack, {
  shouldForwardProp: (props) => !["type"].includes(props),
})(({ theme, type }) => ({
  justifyContent: "center",
  alignItems: "center",
  padding: "25px",
  gap: "25px",
  borderRadius: "20px",
  // border: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  backgroundColor: theme.palette.hubColors.white,
  "& .MuiBarElement-root": {
    fill: theme.palette.hubColors.mainFocus, //theme.palette.hubColors[ type === valueTypes.STAR ? "star" : "mainFocus" ],
  },
  "& .MuiChartsAxis-line, & .MuiChartsAxis-tick": {
    stroke: theme.palette.hubColors.grey,
  },
  "& .MuiChartsAxis-line": {
    display: "none",
  },
  "& .MuiChartsAxis-directionY": {
    "& .MuiChartsAxis-line": {
      display: "none",
    },
    "& .MuiChartsAxis-tick": {},
  },
  "& .MuiChartsAxis-directionX": {
    "& .MuiChartsAxis-tick": {
      display: "none",
    },
  },
}));
