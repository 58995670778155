import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import { HubButton } from "../inputs";
import { DatePicker } from "@mui/x-date-pickers";

export const StyledFormControl = styled(FormControl, {
  shouldForwardProp: (prop) => !["hidden", "fullWidth"].includes(prop),
})(({ hidden, fullWidth }) => ({
  ...(hidden && {
    display: "none",
  }),
  width: fullWidth ? "100%" : "max-content",
  "& .MuiRadio-root": {
    padding: 0,
  },
  "& .MuiFormGroup-root": {
    width: "fit-content",
  },
  "& .MuiIconButton-root": {
    "& .MuiSvgIcon-root": {
      width: 12,
      height: 12,
    },
  },
}));

export const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  "& .Mui-selected": {
    border: "none",
    backgroundColro: theme.palette.hubColors.mainFocus,
    color: theme.palette.hubColors.fontWhite,
    borderRadius: "15px",
  },
}));

export const StyledFormButton = styled(HubButton, {
  shouldForwardProp: (prop) => prop !== "isEmoji" && prop !== "selected",
})(({ theme, isEmoji, selected }) => ({
  ...(!selected && {
    backgroundColor: theme.palette.hubColors.greyLight,
  }),
  ...(isEmoji && {
    fontSize: 25,
    padding: "3px 10px",
    minWidth: "fit-content",
    backgroundColor: selected
      ? theme.palette.hubColors.mainFocusLightest
      : "transparent",
  }),
}));
