import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";

export const StyledFormControl = styled(FormControl)(({ hidden }) => ({
  ...(hidden && {
    display: "none",
  }),
}));

export const StyledInput = styled(Input, {
  shouldForwardProp: (props) => props !== "boldValue",
})(({ boldValue }) => ({
  ...(boldValue && {
    fontWeight: 500,
  }),
}));
