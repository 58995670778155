import React, { useMemo } from "react";
import ReactDOM from "react-dom"; //import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { createBrowserHistory as createHistory } from "history";
import { QueryClient, QueryClientProvider } from "react-query";
import { SnackbarProvider } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import App from "./App";
import { useHubSnackBar } from "./hooks/useSnackBar";
import createStore from "./redux/createStore";
import IpcThemeProvider from "./IpcThemeProvider";

const container = document.getElementById("root");
// const root = createRoot(container); // createRoot(container!) if you use TypeScript

const HubQueryClientProvider = ({ children }) => {
  const { addSnackbarError } = useHubSnackBar();

  const HubQueryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          mutations: {
            onError: (error) => {
              addSnackbarError({ error });
            },
          },
          queries: {
            refetchOnWindowFocus: false,
            retry: false,
          },
        },
      }),
    // eslint-disable-next-line
    []
  );

  return (
    <QueryClientProvider client={HubQueryClient}>
      {children}
    </QueryClientProvider>
  );
};

const history = createHistory();
const store = createStore(history, {});

ReactDOM.render(
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Provider store={store}>
      <IpcThemeProvider>
        <SnackbarProvider
          maxSnack={1}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <HubQueryClientProvider>
            <App />
          </HubQueryClientProvider>
        </SnackbarProvider>
      </IpcThemeProvider>
    </Provider>
  </LocalizationProvider>,
  container
);
