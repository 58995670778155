import React from "react";
import { TableBody } from "@mui/material";
import { connect } from "react-redux";

import { getPartDips } from "../../redux/routing";
import DipsTableRow from "./DipsTableRow";

const DipsTableBody = ({ dips = [] }) => {
  return (
    <TableBody>
      {dips?.map((dip, idx) => {
        const key = dip.id || idx;
        const id = `${idx}-${key}`;
        const props = { key, idx, dip, id };
        return <DipsTableRow {...props} />;
      })}
    </TableBody>
  );
};

const mapStateToProps = (state) => ({
  dips: getPartDips(state),
});
export default connect(mapStateToProps)(DipsTableBody);
