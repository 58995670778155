import { createSelector } from "reselect";

const LOAD_CUSTOMERS = "LOAD_CUSTOMERS";
const CLEAR_CUSTOMERS = "CLEAR_CUSTOMERS";

const initialState = {
    customers: [],
    meta: {}
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "FLUSH":
        case CLEAR_CUSTOMERS: {
            return initialState;
        }

        case LOAD_CUSTOMERS: {
            const newState = {
                customers: action?.customers,
                meta: action?.meta
            };
            return newState;
        }

        default:
            return state;
    }
}

export function setCustomers(data = {}) {
    return {
        type: LOAD_CUSTOMERS,
        customers: data?.customers,
        meta: data?.meta
    };
}

export const getCustomersState = createSelector(
    (state) => state,
    (state) => state?.customers
);

export const getCustomers = createSelector(getCustomersState,
    (customerState) => customerState?.customers || []
);

export const getCustomersMeta = createSelector(getCustomersState,
    (customerState) => customerState?.meta || {}
);

