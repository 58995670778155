import React from "react";

import { HubTableHeader, InputLabel } from "../../components";

const headCells = [
  {
    id: "routing",
    label: "Routing",
    required: true,
  },
];

export const RoutingTableHeader = () => {
  const ITEMS = headCells.map((cell) => ({
    id: cell.id,
    label: cell?.required ? (
      <InputLabel
        label={cell.label}
        required
        disableMargin
        // sx={{ lineHeight: 1 }}
      />
    ) : (
      cell.label
    ),
  }));

  return (
    <HubTableHeader
      items={ITEMS}
      sx={{ backgroundColor: "hubColors.greyLighter" }}
    />
  );
};

export default RoutingTableHeader;
