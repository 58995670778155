import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TableBody } from "@mui/material";

import { getCustomerDataOrders } from "../../redux/customerData";
import CustomerOrderRow from "./CustomerOrderRow";
import { WarningDeleteDialog } from "../../dialogs";
import { useDeleteOrderById } from "../../hooks";

export const CustomerOrdersBody = ({ orders, includeCustomer = false }) => {
  const [deletingId, setDeletingId] = useState(false);
  const { mutate: deleteOrderById, isSuccess } = useDeleteOrderById();

  const handleClose = () => setDeletingId(false);
  const handleSubmit = () => deletingId && deleteOrderById(deletingId);

  useEffect(() => {
    isSuccess && handleClose();
  }, [isSuccess]);

  return (
    <>
      <TableBody>
        {orders?.map((order) => (
          <CustomerOrderRow
            order={order}
            includeCustomer={includeCustomer}
            setDeletingId={setDeletingId}
          />
        ))}
      </TableBody>
      <WarningDeleteDialog
        isOpen={!!deletingId}
        onClose={handleClose}
        onSubmit={handleSubmit}
        title="You are about to delete this order!"
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  orders: getCustomerDataOrders(state),
});

export default connect(mapStateToProps)(CustomerOrdersBody);
