import React from "react";
import { Stack, Typography } from "@mui/material";

import { HubCardStack, HubHookFormSelect, HubIcon } from "../../components";
import { formatDateMmDdYyyy } from "../../helpers/dateHelper";
import { StyledCardStack, StyledHubButton } from "./styles";
import { DEPARTMENTS } from "../../constants";

const Field = ({ field = "", value = "" }) => (
  <Stack gap="5px" display="grid" gridTemplateColumns="75px 1fr">
    <Typography variant="caption" sx={{ fontWeight: "600" }}>
      {field}
    </Typography>
    <Typography variant="caption">{value}</Typography>
  </Stack>
);

const Title = ({ icon = "", title = "" }) => (
  <Stack
    direction="row"
    gap="5px"
    color="hubColors.mainFocus"
    alignItems="center"
    mb="5px"
  >
    <HubIcon icon={icon} size={12} />
    <Typography variant="boldCaptionMain">{title}</Typography>
  </Stack>
);

const WorkOrderMain = ({
  customer = {},
  part = {},
  quantity = 0,
  lotNumber = "",
  poNumber,
  workOrder,
  startDate,
  dueDate,
  createdAt,
  address = "",
  status = 1,
  routings = []
}) => {
  const cardProps = { gap: "10px", padding: "15px", flexGrow: "0 !important" };
  const options = DEPARTMENTS.filter((d) => routings?.includes(d?.id));

  return (
    <StyledCardStack>
      <HubCardStack {...cardProps}>
        <Stack>
          <Title icon="Activity" title="Department" />
          <HubHookFormSelect
            name="department"
            options={options}
            defaultValue={status || options[0].id}
            required
          />
        </Stack>
      </HubCardStack>
      <HubCardStack {...cardProps}>
        <Stack>
          <Title icon="Layout" title="Order" />
          <Field field="P.0.#:" value={poNumber} />
          <Field field="W.0.#:" value={workOrder} />
          <Field field="Quantity:" value={quantity} />
          <Field field="Lot Number:" value={lotNumber} />
        </Stack>
      </HubCardStack>
      <HubCardStack {...cardProps}>
        <Stack>
          <Title icon="Calendar" title="Schedule" />
          <Field field="Start Date:" value={formatDateMmDdYyyy(startDate)} />
          <Field field="Due Date:" value={formatDateMmDdYyyy(dueDate)} />
          <Field field="Order Date:" value={formatDateMmDdYyyy(createdAt)} />
        </Stack>
      </HubCardStack>
      <HubCardStack {...cardProps}>
        <Stack>
          <Title icon="User" title="Customer" />
          <StyledHubButton
            variant="text"
            label={customer.name}
            icon="ExternalLink"
            page={`/customers/${customer.id}`}
          />
        </Stack>
        <Stack>
          <Typography variant="caption" sx={{ fontWeight: 600 }}>
            Ship To
          </Typography>
          {address?.split(",")?.map((a) => (
            <Typography variant="caption">{a}</Typography>
          ))}
        </Stack>
      </HubCardStack>
      <HubCardStack {...cardProps}>
        <Stack>
          <Title icon="Settings" title="Part" />
          <Typography variant="caption"></Typography>
          <StyledHubButton
            variant="text"
            label={`${part.partNumber} - ${part.partName}`}
            icon="ExternalLink"
            page={`/parts/${part.id}`}
          />
        </Stack>
      </HubCardStack>
    </StyledCardStack>
  );
};

export default WorkOrderMain;
