import React from "react";
import { useSnackbar } from "notistack";
import HubSnackBar from "../components/HubSnackBar/HubSnackBar";

export const useHubSnackBar = () => {
  const { enqueueSnackbar } = useSnackbar();

  // const anchorOrigin = { horizontal: "left", vertical: "right" };

  const addSnackbar = ({ message, severity, onClose = null, ...rest }) => {
    enqueueSnackbar(message, {
      content: (key, message) => {
        return (
          <HubSnackBar
            id={key}
            message={message}
            severity={severity}
            {...rest}
          />
        );
      },
      onClose,
    });
  };

  const addSnackbarError = ({
    error,
    persist = false,
    autoHideDuration = 7500,
    ...rest
  }) => {
    const message = error?.response?.data?.message || "An error occurred";

    enqueueSnackbar(message, {
      content: (key, message) => {
        return (
          <HubSnackBar
            id={key}
            message={message}
            title="Error"
            severity="error"
            {...rest}
          />
        );
      },
      persist,
      autoHideDuration,
    });
  };

  const addSnackbarSuccess = ({
    message,
    severity = "success",
    title = "Completed",
    onClose = null,
    ...rest
  }) => {
    enqueueSnackbar(message, {
      content: (key, message) => {
        return (
          <HubSnackBar
            id={key}
            message={message}
            severity={severity}
            title={title}
            {...rest}
          />
        );
      },
      onClose,
    });
  };

  const addSnackbarInfo = ({
    message,
    severity = "info",
    title = "",
    onClose = null,
    ...rest
  }) => {
    enqueueSnackbar(message, {
      content: (key, message) => {
        return (
          <HubSnackBar
            id={key}
            message={message}
            severity={severity}
            title={title}
            {...rest}
          />
        );
      },
      onClose,
    });
  };

  return {
    addSnackbarError,
    addSnackbarSuccess,
    addSnackbarInfo,
    addSnackbar,
  };
};
