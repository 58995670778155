import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import { Select, Stack } from "@mui/material";

export const StyledFormControl = styled(FormControl)(({ hidden }) => ({
  ...(hidden && {
    display: "none",
  }),
}));

export const StyledInput = styled(Input, {
  shouldForwardProp: (props) => props !== "boldValue",
})(({ boldValue }) => ({
  ...(boldValue && {
    fontWeight: 500,
  }),
}));

export const StyledPickerStack = styled(Stack, {
  shouldForwardProp: (prop) => !["onlyDys"].includes(prop),
})(({ onlyDays }) => ({
  display: "grid",
  gridTemplateColumns: onlyDays ? "1fr" : "repeat(2, 1fr)",
  alignItems: "center",
  padding: "5px 10px",
  gap: "5px",
}));

export const StyledSelect = styled(Select)({
  "& .MuiMenu-paper": {
    maxWidth: "300px",
  },
});
