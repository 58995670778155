import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import HubAnimationStack from "../HubAnimationStack/HubAnimationStack";

export const StyledStack = styled(HubAnimationStack, {
  shouldForwardProp: (prop) => !["animate", "direction"].includes(prop),
})(({ theme }) => ({
  backgroundColor: theme.palette.hubColors.redMainTransparent,
  color: theme.palette.hubColors.redMain,
  gap: "5px",
  padding: "25px",
  height: "fit-content",
  borderRadius: "7px",
  "& .MuiTypography-root": {
    color: theme.palette.hubColors.redMain,
  },
}));
