const generatedStrings = new Set();

export const generateRandomString = (length = 8) => {
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
};

export const generateUniqueRandomString = (length = 8) => {
  let randomString = generateRandomString(length);

  while (generatedStrings.has(randomString)) {
    randomString = generateRandomString(length);
  }

  generatedStrings.add(randomString);
  return randomString;
};

export const queryToObject = (search = "") => {
  const shape = JSON.parse(
    '{"' +
      decodeURI(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );

  return shape;
};
