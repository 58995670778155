import { takeEvery, put, all, fork } from "redux-saga/effects";
import { push } from "react-router-redux";

function* watchFlush() {
  yield takeEvery("FLUSH", function* () {
    yield put(push("/"));
  });
}

// function* watchOtherAction() {
//   yield takeEvery("OTHER_ACTION", function* () {

//   });
// }

export default function* rootSaga() {
  yield all([
    fork(watchFlush),
    //fork(watchOtherAction),
  ]);
}
