import { createSelector } from "reselect";

const LOAD_PARTS = "LOAD_PARTS";
const CLEAR_PARTS_TABlE = "CLEAR_PARTS_TABLE";

const initialState = {
    parts: [],
    meta: {}
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "FLUSH":
        case CLEAR_PARTS_TABlE: {
            return initialState;
        }

        case LOAD_PARTS: {
            const newState = {
                parts: action?.parts,
                meta: action?.meta
            };
            return newState;
        }

        default:
            return state;
    }
}

export function setParts(data = {}) {
    return {
        type: LOAD_PARTS,
        parts: data?.parts,
        meta: data?.meta
    };
}

export const getPartsState = createSelector(
    (state) => state,
    (state) => state?.partsTable
);

export const getParts = createSelector(getPartsState,
    (partTableState) => partTableState?.parts || []
);

export const getPartsMeta = createSelector(getPartsState,
    (partTableState) => partTableState?.meta || {}
);

