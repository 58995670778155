import React from "react";
import { Stack, Typography } from "@mui/material";
import { connect } from "react-redux";

import { addPartOutside, getPartRouteByName } from "../../../redux/routing";
import { HubButton, HubHookFormInput } from "../../../components";
import { OutsideProcessingTable } from "../../../tables";

import { StyledHubCardStack } from "../styles";

const gap = "10px";

const PartOutsideProcessing = ({
  details = {},
  _addPartOutside = () => {},
}) => {

  return (
    <StyledHubCardStack gap="25px">
      <Stack gap={gap}>
        <Typography variant="boldCaptionMain">
          Oustide Processing Routing
        </Typography>
        <OutsideProcessingTable />
        <HubButton
          label="+ Add Routing"
          variant="text"
          sx={{ fontSize: 10, ml: "auto", mb: -5 }}
          onClick={_addPartOutside}
        />
      </Stack>
      <Stack gap={gap}>
        <Typography variant="boldCaptionMain">
          Oustide Processing Details
        </Typography>
        <HubHookFormInput
          name="outside.notes"
          label="Special Instructions"
          placeholder="Post Foundry notes..."
          defaultValue={details.notes}
          rows={5}
          multiline
        />
      </Stack>
    </StyledHubCardStack>
  );
};

const mapStateToProps = (state) => ({
  details: getPartRouteByName(state, "outside"),
});
const mapDispatchToProps = {
  _addPartOutside: addPartOutside,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartOutsideProcessing);
