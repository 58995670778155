import React from "react";
import { Stack, Typography } from "@mui/material";

import { HubBackButton, HubButton } from "../../components";
import { StyledTitleStack } from "../PartDetails/styles";

const WorkOrderBar = ({ workOrder }) => {
  return (
    <StyledTitleStack>
      <Stack direction="row" gap="10px" alignItems="center">
        <HubBackButton />
        <Typography variant="caption">{workOrder}</Typography>
      </Stack>
      <Stack direction="row" gap="10px" alignItems="center">
        <HubButton
          variant="contained"
          size="xsmall"
          label="Save Changes"
          type="submit"
        />
        {/* <PartContextMenu id={id} /> */}
      </Stack>
    </StyledTitleStack>
  );
};

export default WorkOrderBar;
