import React from "react";
import { TableContainer, Table } from "@mui/material";

import CustomerOrdersHeader from "./CustomerOrdersHeader";
import CustomerOrdersBody from "./CustomerOrdersBody";

export const CustomerOrdersTable = () => {
  return (
    <TableContainer>
      <Table id="CustomerOrdersTable">
        <CustomerOrdersHeader />
        <CustomerOrdersBody />
      </Table>
    </TableContainer>
  );
};

export default CustomerOrdersTable;
