import React from "react";
import { Table, TableContainer } from "@mui/material";
import DipsTableHeader from "./DipsTableHeader";
import DipsTableBody from "./DipsTableBody";

export const DipsTable = () => {
  return (
    <TableContainer>
      <Table id="DipsTable">
        <DipsTableHeader />
        <DipsTableBody />
      </Table>
    </TableContainer>
  );
};

export default DipsTable;
