import { Radio, RadioGroup, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledStack = styled(Stack, {
  shouldForwardProp: (prop) => !["active"].includes(prop),
})(({ theme, active }) => ({
  cursor: "pointer",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "15px",
  padding: "12px 15px",
  border: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  borderRadius: "7px",
  width: "100%",
  span: {
    textTransform: "none",
  },
  svg: {
    color: theme.palette.hubColors.mainFocus,
  },
  ...(active
    ? {
        border: "2px solid",
        borderColor: theme.palette.hubColors.mainFocus,
        padding: "11px 14px",
      }
    : {}),
}));

export const StyledRadioGroup = styled(RadioGroup)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
  width: "100%",
  ".MuiFormControlLabel-root": {
    gap: 0,
    margin: 0,
    width: "100%",
    "& .MuiButtonBase-root": {
      padding: 0,
    },
  },
});

export const StyledRadio = styled(Radio)(({ theme }) => ({
  cursor: "pointer",
  width: "100%",
}));
