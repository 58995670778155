import React from "react";
import PropTypes from "prop-types";
import { FormProvider, useForm } from "react-hook-form";
import { Box } from "@mui/material";
//import { Box } from "@mui/material";

export const HubHookFormWrapper = ({
  children,
  onSubmit,
  defaultValues = {},
  sx = {},
  ref = null,
}) => {
  const form = useForm({ defaultValues });

  return (
    <FormProvider {...form}>
      <Box
        component="form"
        sx={{ width: "100%", ...sx }}
        onSubmit={(e) => {
          e.stopPropagation();

          return form.handleSubmit(onSubmit)(e);
        }}
        ref={ref}
      >
        {children}
      </Box>
    </FormProvider>
  );
};

HubHookFormWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default HubHookFormWrapper;
