import { styled } from "@mui/material/styles";
import { Chip, Paper, Stack } from "@mui/material";
import { HubCardStack, HubHookFormSelect } from "../../components";

export const StyledHubCardStack = styled(HubCardStack)(({ theme }) => ({
  // height: "400px",
  "& .MuiChartsLegend-root": {
    display: "none",
  },
  "& .MuiChartsReferenceLine-root": {
    "& .MuiChartsReferenceLine-line": {
      stroke: `${theme.palette.hubColors.greyDark} !important`,
      strokeDasharray: 5,
      strokeWidth: 1.75,
    },
    "& .MuiChartsReferenceLine-label": {
      fill: `${theme.palette.hubColors.greyDark} !important`,
      fontWeight: 500,
    },
  },
  "& .MuiChartsAxis-line, & .MuiChartsAxis-tick": {
    display: "none",
    stroke: theme.palette.hubColors.grey,
  },
  "& .MuiChartsGrid-line": {
    // stroke: theme.palette.hubColors.greyActive,
    // strokeDasharray: 5,
  },
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  border: `1px solid ${theme.palette.hubColors.greyLight} !important`,
  boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.2) !important",
  backgroundColor: theme.palette.hubColors.white,
  padding: "20px",
}));

export const StyledChip = styled(Chip)({
  fontSize: 12,
  fontWeight: 600,
  width: "min-content",
  height: "12px",
  border: "none",
  padding: `0 !important`,
  span: {
    padding: 0,
  },
});

export const StyledDataStack = styled(Stack)({
  flexDirection: "row",
  gap: "5px",
  alignItems: "center",
});

export const StyledHubHookFormSelect = styled(HubHookFormSelect, {
  shouldForwardProp: (prop) => !["small"].includes(prop),
})(({ small }) => ({
  "& .MuiSelect-select": {
    padding: "5px 15px",
    width: small ? "200px" : "300px",
  },
  "& .MuiTypography-root, .MuiMenuItem-root": {
    fontSize: "12px",
  },
}));