import React from "react";
import { Stack, Typography } from "@mui/material";
import { connect } from "react-redux";

import { getPartRoutingCreatedAt } from "../../redux/routing";
import { formatDateMmDdYyyy } from "../../helpers/dateHelper";
import { HubIcon } from "../../components";

import { StyledNavStack, StyledHubButton } from "./styles";
import { tabs } from "./helper";

const Title = ({ icon, label }) => (
  <Stack direction="row" gap="10px" alignItems="center" mt="15px" mb="10px">
    <HubIcon icon={icon} size={12} sx={{ color: "hubColors.greyShade" }} />
    <Typography variant="caption" sx={{ fontWeight: 600 }}>
      {label}
    </Typography>
  </Stack>
);

export const PartDetailsMenu = ({
  value = "ROUTING",
  setValue = () => {},
  createdAt = "",
  lastUpdated = "",
}) => {
  return (
    <StyledNavStack minWidth="185.36px">
      <Stack gap="5px">
        {tabs?.map(({ id, label, icon }) => (
          <StyledHubButton
            key={label}
            label={label}
            icon={icon}
            size="small"
            onClick={() => setValue(id)}
            iconProps={{ size: 12 }}
            variant="text"
            active={value === id}
          />
        ))}
      </Stack>
      <Stack gap="0px">
        <Typography
          variant="caption"
          sx={{
            fontSize: 11,
            color: "hubColors.grey",
          }}
        >
          Updated: {formatDateMmDdYyyy(createdAt)}
        </Typography>
        <Typography
          variant="caption"
          sx={{
            fontSize: 11,
            color: "hubColors.grey",
          }}
        >
          Created: {formatDateMmDdYyyy(createdAt)}
        </Typography>
      </Stack>
    </StyledNavStack>
  );
};

const mapStateToProps = (state) => ({
  createdAt: getPartRoutingCreatedAt(state),
});

export default connect(mapStateToProps)(PartDetailsMenu);
