import React from "react";
import { Stack, Typography } from "@mui/material";

import { PreFoundryTable } from "../../../tables";
import { StyledHubCardStack } from "../styles";

const gap = "10px";

const PartAssembly = ({}) => {
  return (
    <StyledHubCardStack>
      <Stack gap={gap}>
        <Typography variant="boldCaptionMain">PreFoundry Details</Typography>
        <PreFoundryTable />
      </Stack>
    </StyledHubCardStack>
  );
};

export default PartAssembly;
