import React from "react";
import { TableBody } from "@mui/material";

import AssemblyTableRow from "./AssemblyTableRow";
import AutoclaveTableRow from "./AutoclaveTableRow";

const PreFoundryTableBody = () => {
  return (
    <TableBody>
      <AssemblyTableRow />
      <AutoclaveTableRow />
    </TableBody>
  );
};

export default PreFoundryTableBody;
