import React from "react";
import { Stack } from "@mui/material";
import { PageContent } from "../../components";

export const WorkOrdersPage = () => {
  return (
    <PageContent gap="15px" white>
      <Stack gap="15px" height="100%"></Stack>
    </PageContent>
  );
};

export default WorkOrdersPage;
