import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";

export const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => !["hasLabel"].includes(prop),
})(({ hasLabel }) => ({
  justifyContent: "start",
  margin: 0,
  ...(hasLabel ? { gap: "7px" } : { gap: 0 }),
  "& .MuiCheckbox-root": {
    padding: 0,
  },
  "& .MuiFormControlLabel-label": {
    fontSize: 14,
    lineHeight: 1,
  },
}));
