import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { FormControlLabel, Radio, Stack, Typography } from "@mui/material";

import { HubIcon } from "../../components";
import { StyledRadio, StyledRadioGroup, StyledStack } from "./styles";
import { templates } from "./helper";

const CreateCustomerPartTemplate = () => {
  const { setValue, register } = useFormContext();

  useEffect(() => {
    register("templateId", { required: true });
    setValue("templateId", 0, { shouldDirty: false });
  }, [register]);

  return (
    <StyledRadioGroup
      aria-labelledby="template-part-radios"
      name="templateId"
      defaultValue={0}
      onChange={(e) => {
        setValue("templateId", e.target.value);
      }}
    >
      {templates?.map((t, key) => (
        <FormControlLabel
          value={t.id}
          control={<CustomRadio t={t} />}
          label=""
          key={key}
        />
      ))}
    </StyledRadioGroup>
  );
};

const CustomRadio = ({ t, ...rest }) => {
  return (
    <StyledRadio
      disableRipple
      checkedIcon={<CustomRadioOption {...t} selected />}
      icon={<CustomRadioOption {...t} />}
      {...rest}
    />
  );
};

export const CustomRadioOption = ({ selected, icon, title, label }) => {
  return (
    <StyledStack active={selected}>
      <Stack direction="row" gap="10px">
        <HubIcon icon={icon} size={12} sx={{ mt: "3px" }} />
        <Stack>
          <Typography variant="boldCaption">{title}</Typography>
          <Typography variant="caption">{label}</Typography>
        </Stack>
      </Stack>
      <Radio size="small" checked={selected} />
    </StyledStack>
  );
};
export default CreateCustomerPartTemplate;
