import { styled } from "@mui/material/styles";
import { HubButton } from "../inputs";

export const StyledHubButton = styled(HubButton)({
  boxShadow: "none",
  padding: "5px 15px",
  fontSize: "10px",
  "&:hover": {
    boxShadow: "none"
  }
});
