import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import { HubButton } from "../inputs";

export const StyledFormControl = styled(FormControl)(({ hidden }) => ({
  ...(hidden && {
    display: "none",
  }),
  width: "max-content",
  "& .MuiRadio-root": {
    padding: 0,
  },
  "& .MuiFormGroup-root": {
    width: "fit-content",
  },
}));

export const StyledFormButton = styled(HubButton, {
  shouldForwardProp: (prop) => prop !== "isEmoji" && prop !== "selected",
})(({ theme, isEmoji, selected }) => ({
  minWidth: "41px",
  ...(!selected && {
    backgroundColor: theme.palette.hubColors.greyLight,
  }),
  ...(isEmoji && {
    fontSize: 25,
    padding: "3px 10px",
    minWidth: "fit-content",
    backgroundColor: selected
      ? theme.palette.hubColors.mainFocusLightest
      : "transparent",
  }),
}));
