import React from "react";
import { Stack, Typography } from "@mui/material";
import { connect } from "react-redux";

import { HubButton, HubCardStack } from "../../components";
import { addPoLineItem } from "../../redux/editPurchaseOrder";
import OrderLineItemsTable from "../../tables/OrderLineItemsTable/OrderLineItemsTable";

const OrderList = ({ _addLineItem = () => {} }) => {
  return (
    <HubCardStack gap="15px" width="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="boldCaption">Line Items</Typography>
        <HubButton
          variant="contained"
          size="small"
          label="Add Line Item"
          onClick={_addLineItem}
        />
      </Stack>
      <OrderLineItemsTable />
    </HubCardStack>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  _addLineItem: addPoLineItem,
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
