import dayjs from "dayjs";

const addBusinessDays = (startDate, numberOfDays, startAdd) => {
  let currentDay = dayjs(startDate);

  while (numberOfDays >= 0) {
    currentDay = currentDay.add(startAdd, "day");
    if (currentDay.day() % 6 !== 0) numberOfDays -= 1;
  }

  return currentDay;
};

export const onUpdateDateHelper = (value, isStart = false, totalDays = 0) => {
  if (totalDays === 0) {
    return isStart ? { startDate: value } : { dueDate: value };
  }

  const startAdd = isStart ? 1 : -1;
  const secondDate = addBusinessDays(value, totalDays, startAdd);
  const second = secondDate.format("MM/DD/YYYY");

  const data = {
    startDate: isStart ? value : second,
    dueDate: isStart ? second : value,
  };

  return data;
};
