import { TableRow, Stack, Chip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { HubCardStack } from "../../components";
// import { HubCardStack } from "../../components";

export const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => !["isSelected"].includes(prop),
})(({ isSelected, theme }) => ({
  ...(!isSelected
    ? {
        backgroundColor: theme.palette.hubColors.greyLighter,
      }
    : {}),
}));

export const StyledSubStack = styled(Stack)(({ theme }) => ({
  padding: "25px",
  boxSizing: "border-box",
  backgroundColor: theme.palette.hubColors.greyLightest,
}));

export const StyledHubCardStack = styled(HubCardStack, {
  shouldForwardProp: (prop) => !["disableMx"].includes(prop),
})(({ disableMx }) => ({
  gap: "25px",
  width: "100%",
  height: "100%",
  ...(disableMx ? {} : { margin: "auto" }),
}));

export const StyledChip = styled(Chip)(({ theme }) => ({
  fontSize: 10,
  fontWeight: 600,
  borderRadius: 2,
  backgroundColor: theme.palette.hubColors.redMainTransparent,
  color: theme.palette.hubColors.redMain,
  padding: "1px",
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: 10,
  fontWeight: 600,
  color: theme.palette.hubColors.redMain,
}));
