import React from "react";
import { Chip, Divider, Stack, Typography } from "@mui/material";
import { connect } from "react-redux";

import {
  getPoIsUpdate,
  getPurchaseOrderDetails,
  getPurchasseOrderAverageMargin,
  getTotalPurchaseOrderCost,
  updateOrderData,
} from "../../redux/purchaseOrder";
import { FOUNDRIES } from "../../constants/industry";
import { getDollarValue } from "../../helpers/currency";
import {
  HubHookFormSelect,
  HubHookFormInput,
  HubButton,
  HubHookFormSwitch,
  HubCardStack,
} from "../../components";
import {
  getCustomerData,
  getCustomerDataAddresses,
} from "../../redux/customerData";
import { SHIPPING } from "../../constants";

const getAddressLabel = (add) => {
  const addressString = `${add?.street}, ${add?.city} ${add?.state}, ${add?.country} ${add?.zip}`;

  const type =
    add.billing && add.shipping
      ? "Main"
      : add.billing
      ? "Billing"
      : add.shipping
      ? "Shipping"
      : "General";

  return (
    <Stack>
      <Stack direction="row" gap="5px" alignItems="center">
        <Typography variant="boldCaption">{type}</Typography>
        {add?.deleted && (
          <Typography variant="boldCaptionMain">Archived</Typography>
        )}
      </Stack>
      <Typography variant="caption">{addressString}</Typography>
    </Stack>
  );
};

const PoDetails = ({
  total,
  margin,
  details,
  customer,
  isLoading = false,
  isUpdating = false,
  addresses = [],
  _updateOrderData = () => {},
}) => {
  const location =
    details?.location ||
    FOUNDRIES?.find((f) => customer?.location === f?.label)?.id;

  const updateHelper = (key, value) => {
    _updateOrderData(key, value);
  };

  const addressOptions = addresses
    ?.filter((a) => a?.id)
    ?.map((add) => ({
      id: add.id,
      label: getAddressLabel(add),
    }));

  return (
    <HubCardStack
      gap="15px"
      sx={{
        maxWidth: { sm: "100%", md: "450px" },
        width: "100%",
        height: "fit-content",
      }}
    >
      <Typography sx={{ fontWeight: 600 }}>Order Details</Typography>
      <HubHookFormInput
        name="company"
        label="Company"
        placeholder="ex. Winco"
        defaultValue={customer?.customer}
        disableHelperText
        disabled
        required
      />
      <Stack
        gap="10px"
        sx={{ flexDirection: { sm: "column", md: "row" } }}
        alignItems="center"
      >
        <HubHookFormInput
          name="orderNumber"
          label="Customer PO#"
          placeholder="Order #"
          defaultValue={details?.poNumber}
          onBlur={(e, value) => updateHelper("poNumber", value)}
          disableHelperText
          required
        />
        <HubHookFormSelect
          name="location"
          label="Foundry"
          defaultValue={location}
          options={FOUNDRIES}
          onChange={(e) => updateHelper("location", e?.target?.value)}
          disableHelperText
          required
        />
        <HubHookFormSwitch
          name="overShipping"
          label="Over Shipping"
          defaultValue={details?.overShipping}
          onChange={(e, value) => updateHelper("overShipping", value)}
          disableHelperText
          isCheckbox
        />
      </Stack>
      <Divider
        orientation="horizontal"
        flexItem
        sx={{ border: "1px dashed", borderColor: "hubColors.greyLight" }}
      />
      <Stack gap="15px">
        <Typography variant="body2">Shipping</Typography>
        <Stack gap="10px" sx={{ flexDirection: { sm: "column", md: "row" } }}>
          <HubHookFormInput
            name="freight"
            label="Freight"
            placeholder="X"
            defaultValue={details?.freight}
            onBlur={(e, value) => updateHelper("freight", value)}
            disableHelperText
            required
          />
          <HubHookFormSelect
            name="shipVia"
            label="Ship Via"
            defaultValue={details?.shipVia}
            options={SHIPPING}
            onChange={(e) => updateHelper("shipVia", e?.target?.value)}
            disableHelperText
            required
          />
        </Stack>
        <HubHookFormSelect
          name="shipTo"
          label="Ship To"
          defaultValue={details?.shipTo}
          options={addressOptions}
          onChange={(e) => updateHelper("shipTo", e?.target?.value)}
          disableHelperText
          required
        />
      </Stack>
      {false ? (
        <>
          <Divider
            orientation="horizontal"
            flexItem
            sx={{ border: "1px dashed", borderColor: "hubColors.greyLight" }}
          />
          <Stack gap="15px">
            <Typography variant="body2">Subtotals</Typography>
            <Stack gap="5px">
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="caption">Average Margin</Typography>
                <Typography variant="boldCaption">{margin} %</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="caption">Part Pricing</Typography>
                <Typography variant="boldCaption">
                  {getDollarValue(total / (margin / 100 + 1))}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </>
      ) : (
        <></>
      )}
      <Divider
        orientation="horizontal"
        flexItem
        sx={{ border: "1px dashed", borderColor: "hubColors.greyLight" }}
      />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography sx={{ fontWeight: 600, fontSize: 20 }}>Total</Typography>
        <Typography sx={{ fontWeight: 600, fontSize: 20 }}>
          {getDollarValue(total)}
        </Typography>
      </Stack>
      <HubButton
        variant="contained"
        size="small"
        label={`${isUpdating ? "Update" : "Submit"} Purchase Order`}
        disabled={isLoading}
        type="submit"
      />
    </HubCardStack>
  );
};

const mapStateToProps = (state) => ({
  total: getTotalPurchaseOrderCost(state),
  margin: getPurchasseOrderAverageMargin(state),
  details: getPurchaseOrderDetails(state),
  customer: getCustomerData(state),
  isUpdating: getPoIsUpdate(state),
  addresses: getCustomerDataAddresses(state),
});

const mapDispatchToProps = {
  _updateOrderData: updateOrderData,
};

export default connect(mapStateToProps, mapDispatchToProps)(PoDetails);
