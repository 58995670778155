import { styled } from "@mui/material/styles";
import { Stack, Typography } from "@mui/material";

export const FullWidthStack = styled(Stack)({
    width: "100%",
    alignItems: "center",
});

export const Title = styled(Typography)({
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: 1,
});

export const SubTitle = styled(Typography)({
    fontSize: "12px !important",
    maxWidth: "85%",
    padding: "0px",
});
