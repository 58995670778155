import React from "react";
import { useParams } from "react-router-dom";

import { HubHookFormWrapper, PageContent } from "../../components";
import { useGetWorkOrderData } from "../../hooks";
import { StyleDisplayStack } from "./styles";

import WorkOrderMain from "./WorkOrderMain";
import WorkOrderProd from "./WorkOrderProd";
import WorkOrderBar from "./WorkOrderBar";

const formSx = { height: "100%", overflow: "hidden" };

export const WorkOrderDetails = () => {
  const { id } = useParams();
  const { data, isLoading, isSuccess } = useGetWorkOrderData(id);

  const props = {
    customer: {
      name: data?.order?.customer?.name,
      id: data?.order?.customer?.id,
    },
    part: {
      id: data?.part?.id,
      partName: data?.part?.partName,
      partNumber: data?.part?.partNumber,
    },
    quantity: data?.quantity,
    lotNumber: data?.lotNumber,
    workOrder: data?.workOrder,
    poNumber: data?.order?.poNumber,
    startDate: data?.startDate,
    dueDate: data?.dueDate,
    createdAt: data?.creationDate,
    address: data?.order?.address,
    routings: data?.routings,
  };

  return (
    <PageContent gap="15px" isLoading={isLoading} disablePadding white>
      <HubHookFormWrapper onSubmit={() => {}} sx={formSx}>
        <WorkOrderBar workOrder={data?.workOrder} />
        <StyleDisplayStack>
          <WorkOrderProd />
          <WorkOrderMain {...props} />
        </StyleDisplayStack>
      </HubHookFormWrapper>
    </PageContent>
  );
};

export default WorkOrderDetails;
