import React from "react";
import { TableContainer, Table } from "@mui/material";

import CustomerContactsHeader from "./CustomerContactsHeader";
import CustomerContactsBody from "./CustomerContactsBody";

export const CustomerContactsTable = ({ onClick = () => {} }) => {
  return (
    <TableContainer>
      <Table id="CustomerContactsTable">
        <CustomerContactsHeader />
        <CustomerContactsBody onRowClick={onClick} />
      </Table>
    </TableContainer>
  );
};

export default CustomerContactsTable;
