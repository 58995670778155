export const getRandomHSL = () => {
  const degree = Math.floor(Math.random() * 360);
  return `hsl(${degree}, 100%, 45%)`;
};

export const stringToAlphabetSum = (str) => {
  const alphabet = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@.-_0123456789";
  let sum = 0;

  for (let char of str) {
    let index = alphabet.indexOf(char);
    if (index !== -1) sum += index;
  }

  return sum;
};
