import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";

export const StyledStack = styled(Stack)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "0.5fr 1fr",
  gap: "15px",
  overflow: "hidden",
  height: "100%",
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  [theme.breakpoints.down("md")]: {
    overflow: "visible",
    gridTemplateColumns: "repeat(1, 1fr)",
    gridTemplateRows: "500px 500px",
  },
}));

export const StyledChartStack = styled(Stack)(({ theme }) => ({
  display: "grid",
  gridTemplateRows: "auto",
  gap: "15px",
  height: "100%",
}));

export const StyledDataStack = styled(Stack)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(5, 1fr)",
  gap: "10px",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  [theme.breakpoints.down("xs")]: {
    gridTemplateColumns: "repeat(1, 1fr)",
  },
}));
