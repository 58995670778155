import React from "react";
import { Typography, Stack } from "@mui/material";
import { connect } from "react-redux";

import { getPartRoutingName, setPartRoutingSubmit } from "../../redux/routing";
import { HubBackButton, HubButton } from "../../components";

import PartContextMenu from "./PartContextMenu";
import { StyledTitleStack } from "./styles";

const PartDetailsTitle = ({ part, id }) => {
  return (
    <StyledTitleStack>
      <Stack direction="row" gap="10px" alignItems="center">
        <HubBackButton />
        <Typography variant="caption">
          {part.partNumber} - {part.partName}
        </Typography>
      </Stack>
      <Stack direction="row" gap="10px" alignItems="center">
        <HubButton
          variant="contained"
          size="xsmall"
          label="Save Changes"
          type="submit"
        />
        <PartContextMenu id={id} />
      </Stack>
    </StyledTitleStack>
  );
};

const mapStateToProps = (state) => ({
  part: getPartRoutingName(state),
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(PartDetailsTitle);
