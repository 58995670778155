import React from "react";

import { HubTableHeader, InputLabel } from "../../components";

const required = true;

const headCells = [
  {
    id: "pi-service",
    label: "Service",
    required,
  },
  {
    id: "pi-lead",
    label: "Lead Time",
    required,
  },
  {
    id: "pi-cycle",
    label: "Cycle Time",
    required,
  },
  {
    id: "pi-notes",
    label: "Notes",
  },
];

export const PostFoundryTableHeader = () => {
  const ITEMS = headCells.map((cell) => ({
    id: cell.id,
    label: cell?.required ? (
      <InputLabel label={cell.label} required disableMargin />
    ) : (
      cell.label
    ),
  }));

  return (
    <HubTableHeader
      items={ITEMS}
      includeCheckbox={false}
      sx={{ backgroundColor: "hubColors.greyLighter" }}
    />
  );
};

export default PostFoundryTableHeader;
