export const PARTNERS = [
  {
    id: 1,
    label: "QTI, LLC",
  },
  {
    id: 2,
    label: "EX Works",
  },
  {
    id: 3,
    label: "MSI Testing",
  },
  {
    id: 4,
    label: "Costco",
  },
  {
    id: 5,
    label: "Blanchard",
  },
  {
    id: 6,
    label: "Diversified",
  },
  {
    id: 7,
    label: "Lamoreaux",
  },
  {
    id: 8,
    label: "Metal Mart",
  },
  {
    id: 9,
    label: "Westech",
  },
  {
    id: 10,
    label: "Taylor Tech Inc.",
  },
  {
    id: 11,
    label: "Stoner",
  },
  {
    id: 12,
    label: "Stephen Bader & Co.",
  },
  {
    id: 13,
    label: "Remelt",
  },
  {
    id: 14,
    label: "R.S Hughes Co.",
  },
  {
    id: 15,
    label: "Officesupply.com",
  },
  {
    id: 16,
    label: "Neiko",
  },
  {
    id: 17,
    label: "Croniment",
  },
  {
    id: 18,
    label: "Melco Steel",
  },
  {
    id: 19,
    label: "Larsen",
  },
  {
    id: 20,
    label: "KELCO",
  },
  {
    id: 21,
    label: "Freeway Propane",
  },
  {
    id: 22,
    label: "Filter Technologies",
  },
  {
    id: 23,
    label: "DTA",
  },
  {
    id: 24,
    label: "DB Industrial",
  },
  {
    id: 25,
    label: "Davis Alloys",
  },
  {
    id: 26,
    label: "Chem-Aqua",
  },
  {
    id: 27,
    label: "Ceramtech",
  },
  {
    id: 28,
    label: "Castalloy",
  },
  {
    id: 29,
    label: "Brenntag",
  },
  {
    id: 30,
    label: "BISCO",
  },
  {
    id: 31,
    label: "B&B",
  },
  {
    id: 32,
    label: "Ask Chem",
  },
  {
    id: 33,
    label: "Arizona Hydro.",
  },
  {
    id: 34,
    label: "Amazon",
  },
  {
    id: 35,
    label: "Allred's",
  },
];
