import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";

export const StyledFormControl = styled(FormControl)(({ hidden }) => ({
  ...(hidden && {
    display: "none",
  }),
  "& .MuiFormGroup-root": {
    gap: "3px",
  },
  "& .MuiFormControlLabel-root": {
    margin: 0,
    gap: "7px",
  },
  "& .MuiCheckbox-root, & .MuiRadio-root": {
    padding: 0,
  },
  "& .MuiSvgIcon-root": {
    width: "22px",
    height: "22px"
  },
}));
