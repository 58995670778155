import { styled } from "@mui/material/styles";
import { Stack, Typography } from "@mui/material";

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 12,
  lineHeight: 1,
  color: theme.palette.hubColors.mainFocus,
  display: "none",
  cursor: "pointer",
}));

export const StyledStack = styled(Stack)({
  flexDirection: "row",
  gap: "10px",
  alignItems: "center",
  padding: "2px 0",
  "&:hover": {
    "#OnlyButton": {
      display: "inline",
    },
  },
});
