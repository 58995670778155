import React from "react";
import { connect } from "react-redux";

import { HubPagination } from "../../components";
import { getCustomersMeta } from "../../redux/customers";

const CompaniesPagination = ({ meta }) => {
  return meta?.count > 25 ? <HubPagination {...meta} /> : <></>;
};

const mapStateToProps = (state) => ({
  meta: getCustomersMeta(state),
});
export default connect(mapStateToProps)(CompaniesPagination);
