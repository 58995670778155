import React, { useState } from "react";
import { Stack, Typography, Box } from "@mui/material";
import { connect } from "react-redux";

import {
  getCustomerContactsCount,
  getCustomerId,
} from "../../redux/customerData";
import { EmptyGeneral } from "../../svgs";
import { CustomerContactsTable } from "../../tables";
import { EditCustomerContact } from "../../dialogs";
import { DialogButton, HubButton } from "../../components";

import CustomerContactsBar from "./CustomerContactsBar";

const CustomerContactsContainer = ({ customerId, count = 0 }) => {
  const [contact, setContact] = useState({});
  const [isUpdate, setIsUpdate] = useState(true);

  const handleCreateContact = () => {
    setIsUpdate(false);
    setContact({});
  };

  const handleEditContact = (contact) => {
    setIsUpdate(true);
    setContact(contact);
  };

  return (
    <>
      {count ? (
        <Stack gap="25px">
          <CustomerContactsBar onClick={handleCreateContact} />
          <CustomerContactsTable onClick={handleEditContact} />
          <DialogButton
            id="EditContact"
            name="edit-contact"
            label=""
            sx={{ display: "none" }}
          />
        </Stack>
      ) : (
        <Stack gap="25px" margin="auto" alignItems="center">
          <Box component="img" src={EmptyGeneral} sx={{ height: "150px" }} />
          <Stack gap="10px" alignItems="center" textAlign="center">
            <Typography variant="boldCaption">No Contacts Found</Typography>
            <Typography variant="caption" width="250px">
              Click "Create Contact" button to get started in adding this
              customer's contact information
            </Typography>
            <Stack direction="row" gap="10px">
              <HubButton
                variant="outlined"
                size="small"
                color="neutral"
                label="Learn More"
              />
              <DialogButton
                id="EditContact"
                name="edit-contact"
                label="Create Contact"
                onClick={handleCreateContact}
                variant="contained"
                size="xsmall"
              />
            </Stack>
          </Stack>
        </Stack>
      )}
      <EditCustomerContact
        contact={contact}
        isUpdate={isUpdate}
        customerId={customerId}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  customerId: getCustomerId(state),
  count: getCustomerContactsCount(state),
});

export const CustomerContacts = connect(mapStateToProps)(
  CustomerContactsContainer
);
