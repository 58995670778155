export const symbols = {
  USD: "$", // United States Dollar
  EUR: "€", // Euro
  GBP: "£", // British Pound Sterling
  JPY: "¥", // Japanese Yen
  AUD: "A$", // Australian Dollar
  CAD: "C$", // Canadian Dollar
  CHF: "CHF", // Swiss Franc
  CNY: "¥", // Chinese Yuan
  SEK: "kr", // Swedish Krona
  NZD: "NZ$", // New Zealand Dollar
  MXN: "$", // Mexican Peso
  SGD: "S$", // Singapore Dollar
  HKD: "HK$", // Hong Kong Dollar
  NOK: "kr", // Norwegian Krone
  KRW: "₩", // South Korean Won
  TRY: "₺", // Turkish Lira
  RUB: "₽", // Russian Ruble
  INR: "₹", // Indian Rupee
  BRL: "R$", // Brazilian Real
  ZAR: "R", // South African Rand
  PHP: "₱", // Philippine Peso
  PLN: "zł", // Polish Zloty
  THB: "฿", // Thai Baht
  IDR: "Rp", // Indonesian Rupiah
  MYR: "RM", // Malaysian Ringgit
  VND: "₫", // Vietnamese Dong
  AED: "د.إ", // United Arab Emirates Dirham
  SAR: "﷼", // Saudi Riyal
  QAR: "﷼", // Qatari Riyal
  BHD: "BD", // Bahraini Dinar
  OMR: "﷼", // Omani Rial
  COP: "$", // Colombian Peso
  CLP: "$", // Chilean Peso
  TWD: "NT$", // New Taiwan Dollar
  ARS: "$", // Argentine Peso
  CZK: "Kč", // Czech Koruna
  ILS: "₪", // Israeli New Sheqel
  HUF: "Ft", // Hungarian Forint
};

export const combinedSymbols = Object.entries(symbols).map(([key, value]) => ({
  id: key,
  label: `${value} ${key}`,
}));

export const getCurrencySymbol = (currency) => {
  return symbols[currency] || symbols["USD"]; // Default to USD if the currency code is not found
};

export const currencyKeys = Object.keys(symbols);

export const getDollarValue = (total) => {
  const dollars = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  })
    .format(total)
    ?.replace("$", "$ ");

  return dollars;
};