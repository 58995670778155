export const FoundrySoloLogo = ({ size = 117, fill = "#fff", ...rest }) => {
  const width = size;
  const height = (size * 41.42) / 52.63;
  const color = fill;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 54 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M30.2784 41.7112H42.8435C48.6798 41.7112 53.3832 36.9879 53.3145 31.2112L53.1429 17.1772H30.0381V25.0947H45.2124L45.3154 33.7597H30.2441V41.7112H30.2784Z"
        fill={color}
      />
      <path
        d="M53.0398 8.2063C53.0398 3.8228 49.4351 0.288818 45.0407 0.288818H17.8849H13.4219H0.685059V8.2063H13.4219V33.6578C13.4219 38.0413 17.0266 41.5752 21.421 41.5752V8.17232L53.0398 8.2063Z"
        fill={color}
      />
    </svg>
  );
};
export const FoundryLogo = ({ size = 117, fill = "#fff", ...rest }) => {
  const width = size;
  const height = (size * 42) / 117;
  const color = fill;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 117 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M61.1777 5.64075H61.83V12.4369H61.1777V5.64075Z" fill={color} />
      <path
        d="M64.7822 5.64075H66.7734C67.0137 5.64075 67.2541 5.67473 67.5287 5.70871C67.8034 5.77667 68.0437 5.84463 68.2497 5.98055C68.4556 6.11648 68.6273 6.28638 68.799 6.52424C68.9363 6.7621 69.0049 7.06793 69.0049 7.40774C69.0049 7.71356 68.9363 7.98541 68.8333 8.18929C68.7303 8.42716 68.5586 8.59706 68.387 8.73298C68.181 8.8689 67.975 8.97084 67.7347 9.03881C67.4944 9.10677 67.2197 9.14075 66.9451 9.14075H65.4688V12.3689H64.8166V5.64075H64.7822ZM65.4002 8.63104H66.8421C67.0137 8.63104 67.1854 8.59706 67.3914 8.56307C67.563 8.52909 67.7347 8.46114 67.872 8.35919C68.0093 8.25725 68.1123 8.15531 68.2153 7.98541C68.284 7.8155 68.3527 7.6456 68.3527 7.40774C68.3527 7.20385 68.3183 6.99997 68.2153 6.86405C68.1123 6.69414 68.0093 6.5922 67.872 6.49026C67.7347 6.38832 67.563 6.32036 67.3914 6.28638C67.2197 6.2524 67.0137 6.21842 66.8421 6.21842H65.4345V8.63104H65.4002Z"
        fill={color}
      />
      <path
        d="M77.0728 11.3834C76.7295 11.8252 76.3519 12.131 75.9399 12.3349C75.4936 12.5388 75.0473 12.6067 74.5323 12.6067C74.0174 12.6067 73.5367 12.5048 73.0904 12.3349C72.6441 12.165 72.2665 11.9271 71.9575 11.5873C71.6485 11.2475 71.4082 10.9077 71.2366 10.466C71.0649 10.0242 70.9619 9.5485 70.9619 9.03879C70.9619 8.52908 71.0649 8.05335 71.2366 7.6116C71.4082 7.16986 71.6485 6.79607 71.9575 6.49024C72.2665 6.18442 72.6441 5.91257 73.0904 5.74267C73.5367 5.57277 74.0174 5.47083 74.5323 5.47083C75.013 5.47083 75.4249 5.53879 75.8369 5.70869C76.2489 5.87859 76.5922 6.15043 76.9012 6.52422L76.3862 6.96597C76.1802 6.66014 75.9056 6.42228 75.5623 6.25238C75.219 6.11646 74.8757 6.04849 74.5323 6.04849C74.086 6.04849 73.6741 6.11645 73.3308 6.28636C72.9875 6.45626 72.6785 6.66015 72.4038 6.93199C72.1635 7.20384 71.9575 7.50966 71.8202 7.88344C71.6829 8.25723 71.6142 8.63102 71.6142 9.07276C71.6142 9.48053 71.6829 9.8883 71.8202 10.2621C71.9575 10.6359 72.1635 10.9417 72.4038 11.2135C72.6441 11.4854 72.9531 11.6893 73.3308 11.8592C73.6741 12.0291 74.086 12.097 74.5323 12.097C74.704 12.097 74.8756 12.0631 75.0816 12.0291C75.2533 11.9951 75.4593 11.9271 75.6309 11.8252C75.8026 11.7233 75.9742 11.6213 76.1459 11.4854C76.3176 11.3495 76.4549 11.1796 76.5579 11.0097L77.0728 11.3834Z"
        fill={color}
      />
      <path
        d="M61.1777 17.5H65.2975V18.0777H61.83V20.4903H65.0571V21.068H61.83V24.2961H61.1777V17.5Z"
        fill={color}
      />
      <path
        d="M67.1855 20.898C67.1855 20.3883 67.2885 19.9126 67.4602 19.4709C67.6319 19.0291 67.8722 18.6553 68.1812 18.3495C68.4901 18.0437 68.8678 17.7718 69.3141 17.6019C69.7604 17.432 70.241 17.3301 70.756 17.3301C71.2709 17.3301 71.7516 17.432 72.1979 17.6019C72.6442 17.7718 73.0218 18.0437 73.3308 18.3495C73.6398 18.6553 73.8801 19.0291 74.0517 19.4709C74.2234 19.9126 74.3264 20.3883 74.3264 20.898C74.3264 21.4077 74.2234 21.8835 74.0517 22.3252C73.8801 22.767 73.6398 23.1408 73.3308 23.4466C73.0218 23.7524 72.6442 24.0243 72.1979 24.1942C71.7516 24.3641 71.2709 24.466 70.756 24.466C70.241 24.466 69.7604 24.3641 69.3141 24.1942C68.8678 24.0243 68.4901 23.7864 68.1812 23.4466C67.8722 23.1068 67.6319 22.767 67.4602 22.3252C67.2885 21.8835 67.1855 21.4077 67.1855 20.898ZM67.8378 20.898C67.8378 21.3058 67.9065 21.7136 68.0438 22.0874C68.1811 22.4611 68.3871 22.767 68.6275 23.0388C68.8678 23.3107 69.1768 23.5145 69.5544 23.6844C69.8977 23.8544 70.3097 23.9223 70.756 23.9223C71.2023 23.9223 71.6143 23.8544 71.9576 23.6844C72.3009 23.5145 72.6098 23.3107 72.8845 23.0388C73.1248 22.767 73.3308 22.4611 73.4681 22.0874C73.6055 21.7136 73.6741 21.3398 73.6741 20.898C73.6741 20.4903 73.6055 20.0825 73.4681 19.7087C73.3308 19.3349 73.1248 19.0291 72.8845 18.7573C72.6442 18.4854 72.3352 18.2815 71.9576 18.1116C71.6143 17.9417 71.2023 17.8738 70.756 17.8738C70.3097 17.8738 69.8977 17.9417 69.5544 18.1116C69.2111 18.2815 68.9021 18.4854 68.6275 18.7573C68.3871 19.0291 68.1811 19.3349 68.0438 19.7087C67.9065 20.0825 67.8378 20.4563 67.8378 20.898Z"
        fill={color}
      />
      <path
        d="M81.6735 21.6796C81.6735 22.0534 81.6391 22.3932 81.5361 22.733C81.4675 23.0728 81.2958 23.3446 81.1242 23.6165C80.9182 23.8883 80.6779 24.0922 80.3346 24.2281C80.0256 24.398 79.6136 24.466 79.1673 24.466C78.6867 24.466 78.309 24.398 78 24.2281C77.6911 24.0582 77.4164 23.8544 77.2104 23.6165C77.0044 23.3446 76.8671 23.0728 76.7985 22.733C76.7298 22.3932 76.6611 22.0534 76.6611 21.6796V17.466H77.3134V21.5097C77.3134 21.7476 77.3478 22.0194 77.3821 22.2912C77.4164 22.5631 77.5194 22.8349 77.6567 23.0728C77.7941 23.3107 77.9657 23.5145 78.206 23.6505C78.4463 23.8204 78.7553 23.8883 79.1673 23.8883C79.5793 23.8883 79.8882 23.8204 80.1286 23.6505C80.3689 23.4806 80.5749 23.3107 80.6779 23.0728C80.8152 22.8349 80.8838 22.5631 80.9525 22.2912C80.9868 22.0194 81.0212 21.7476 81.0212 21.5097V17.466H81.6735V21.6796Z"
        fill={color}
      />
      <path
        d="M84.5229 17.5H85.3469L89.5353 23.4466H89.5696V17.5H90.2219V24.2961H89.398L85.2096 18.3495H85.1752V24.2961H84.5229V17.5Z"
        fill={color}
      />
      <path
        d="M93.0371 17.5H95.1656C95.7493 17.5 96.2642 17.568 96.6762 17.7039C97.0882 17.8398 97.4315 18.0437 97.7061 18.2476C97.9808 18.4854 98.1868 18.7233 98.3584 18.9951C98.5301 19.267 98.6331 19.5388 98.7017 19.7767C98.7704 20.0146 98.839 20.2524 98.839 20.4563C98.8734 20.6602 98.8734 20.7961 98.8734 20.8981C98.8734 20.966 98.8734 21.1359 98.839 21.3398C98.8047 21.5437 98.7704 21.7816 98.7017 22.0194C98.6331 22.2573 98.4957 22.5291 98.3584 22.801C98.1868 23.0728 97.9808 23.3107 97.7061 23.5485C97.4315 23.7864 97.0882 23.9563 96.6762 24.0922C96.2642 24.2282 95.7493 24.2961 95.1656 24.2961H93.0371V17.5ZM93.6894 23.7184H94.994C95.4403 23.7184 95.8866 23.6505 96.2642 23.5485C96.6419 23.4466 96.9852 23.2767 97.2942 23.0388C97.5688 22.801 97.8091 22.5291 97.9808 22.1553C98.1524 21.8155 98.2211 21.3738 98.2211 20.8981C98.2211 20.4223 98.1524 19.9806 97.9808 19.6408C97.8091 19.301 97.5688 18.9951 97.2942 18.7573C97.0195 18.5194 96.6762 18.3495 96.2642 18.2476C95.8866 18.1456 95.4403 18.0777 94.994 18.0777H93.6894V23.7184Z"
        fill={color}
      />
      <path
        d="M101.379 17.5H103.199C103.302 17.5 103.405 17.5 103.577 17.5C103.748 17.5 103.886 17.534 104.092 17.568C104.263 17.6019 104.469 17.6359 104.641 17.7379C104.812 17.8058 104.984 17.9078 105.156 18.0437C105.293 18.1796 105.43 18.3495 105.533 18.5534C105.636 18.7573 105.671 18.9951 105.671 19.301C105.671 19.6408 105.602 19.9126 105.499 20.1165C105.396 20.3204 105.259 20.4903 105.087 20.6262C104.915 20.7621 104.71 20.8641 104.504 20.932C104.298 21 104.092 21.034 103.92 21.068L105.911 24.3301H105.19L103.268 21.1019H102.066V24.3301H101.414V17.5H101.379ZM102.032 20.4903H103.405C103.748 20.4903 104.023 20.4563 104.229 20.3544C104.435 20.2864 104.607 20.1845 104.71 20.0485C104.812 19.9126 104.915 19.8107 104.95 19.6408C104.984 19.5049 105.018 19.3689 105.018 19.267C105.018 19.165 104.984 19.0291 104.95 18.8932C104.915 18.7573 104.812 18.6214 104.71 18.4854C104.607 18.3495 104.435 18.2476 104.229 18.1796C104.023 18.1117 103.748 18.0437 103.405 18.0437H102.032V20.4903Z"
        fill={color}
      />
      <path
        d="M109.619 21.3738L107.113 17.5H107.868L109.928 20.7282L112.056 17.5H112.777L110.237 21.3738V24.2961H109.585V21.3738H109.619Z"
        fill={color}
      />
      <path
        d="M66.8424 35.7136C66.4304 35.9515 66.0184 36.0874 65.6065 36.1893C65.1601 36.2913 64.7482 36.3253 64.3019 36.3253C63.7869 36.3253 63.3063 36.2233 62.86 36.0534C62.4137 35.8835 62.036 35.6456 61.727 35.3058C61.4181 34.966 61.1777 34.6262 61.0061 34.1845C60.8344 33.7427 60.7314 33.267 60.7314 32.7573C60.7314 32.2476 60.8344 31.7719 61.0061 31.3301C61.1777 30.8884 61.4181 30.5146 61.727 30.2087C62.036 29.9029 62.4137 29.6311 62.86 29.4612C63.3063 29.2913 63.7869 29.1893 64.3019 29.1893C65.3318 29.1893 66.1557 29.4952 66.7737 30.1408L66.2931 30.6165C66.1901 30.4806 66.0528 30.3786 65.9154 30.2767C65.7781 30.1748 65.6065 30.0728 65.4348 30.0049C65.2631 29.9369 65.0572 29.8689 64.8855 29.835C64.6795 29.801 64.5079 29.767 64.3019 29.767C63.8556 29.767 63.4436 29.835 63.1003 30.0049C62.757 30.1748 62.448 30.3786 62.1734 30.6505C61.933 30.9223 61.727 31.2282 61.5897 31.602C61.4524 31.9757 61.3837 32.3495 61.3837 32.7913C61.3837 33.199 61.4524 33.6068 61.5897 33.9806C61.727 34.3544 61.933 34.6602 62.1734 34.932C62.4137 35.2039 62.7226 35.4078 63.1003 35.5777C63.4436 35.7476 63.8556 35.8155 64.3019 35.8155C64.6795 35.8155 65.0228 35.7816 65.3318 35.7136C65.6408 35.6456 65.9498 35.5437 66.2244 35.4078V33.1311H64.5765V32.5534H66.8767V35.7136H66.8424Z"
        fill={color}
      />
      <path
        d="M69.7949 29.3592H71.6145C71.7175 29.3592 71.8204 29.3592 71.9921 29.3592C72.1638 29.3592 72.3011 29.3932 72.5071 29.4272C72.6787 29.4612 72.8847 29.4951 73.0564 29.5971C73.228 29.665 73.3997 29.767 73.5713 29.9029C73.7087 30.0388 73.846 30.2087 73.949 30.4126C74.052 30.6165 74.0863 30.8544 74.0863 31.1602C74.0863 31.5 74.0176 31.7718 73.9146 31.9757C73.8116 32.1796 73.6743 32.3495 73.5027 32.4854C73.331 32.6214 73.125 32.7233 72.919 32.7913C72.7131 32.8592 72.5071 32.8932 72.3354 32.9272L74.3266 36.1893H73.6057L71.6831 32.9612H70.4815V36.1893H69.8293V29.3592H69.7949ZM70.4472 32.3495H71.8204C72.1638 32.3495 72.4384 32.3155 72.6444 32.2136C72.8504 32.1456 73.022 32.0437 73.125 31.9078C73.228 31.7718 73.331 31.6699 73.3653 31.5C73.3997 31.3641 73.434 31.2281 73.434 31.1262C73.434 31.0243 73.3997 30.8884 73.3653 30.7524C73.331 30.6165 73.228 30.4806 73.125 30.3447C73.022 30.2087 72.8504 30.1068 72.6444 30.0388C72.4384 29.9709 72.1638 29.9029 71.8204 29.9029H70.4472V32.3495Z"
        fill={color}
      />
      <path
        d="M76.1802 32.7573C76.1802 32.2476 76.2832 31.7719 76.4548 31.3301C76.6265 30.8884 76.8668 30.5146 77.1758 30.2087C77.4848 29.9029 77.8624 29.6311 78.3087 29.4612C78.755 29.2913 79.2356 29.1893 79.7506 29.1893C80.2656 29.1893 80.7462 29.2913 81.1925 29.4612C81.6388 29.6311 82.0164 29.9029 82.3254 30.2087C82.6344 30.5146 82.8747 30.8884 83.0464 31.3301C83.218 31.7719 83.321 32.2476 83.321 32.7573C83.321 33.267 83.218 33.7427 83.0464 34.1845C82.8747 34.6262 82.6344 35 82.3254 35.3058C82.0164 35.6117 81.6388 35.8835 81.1925 36.0534C80.7462 36.2233 80.2656 36.3253 79.7506 36.3253C79.2356 36.3253 78.755 36.2233 78.3087 36.0534C77.8624 35.8835 77.4848 35.6456 77.1758 35.3058C76.8668 34.966 76.6265 34.6262 76.4548 34.1845C76.2488 33.7427 76.1802 33.267 76.1802 32.7573ZM76.7981 32.7573C76.7981 33.1651 76.8668 33.5728 77.0041 33.9466C77.1414 34.3204 77.3474 34.6262 77.5877 34.8981C77.8281 35.1699 78.137 35.3738 78.5147 35.5437C78.858 35.7136 79.27 35.7816 79.7163 35.7816C80.1626 35.7816 80.5745 35.7136 80.9179 35.5437C81.2612 35.3738 81.5701 35.1699 81.8448 34.8981C82.0851 34.6262 82.2911 34.3204 82.4284 33.9466C82.5657 33.5728 82.6344 33.199 82.6344 32.7573C82.6344 32.3495 82.5657 31.9418 82.4284 31.568C82.2911 31.1942 82.0851 30.8884 81.8448 30.6165C81.6045 30.3447 81.2955 30.1408 80.9179 29.9709C80.5745 29.801 80.1626 29.733 79.7163 29.733C79.27 29.733 78.858 29.801 78.5147 29.9709C78.1714 30.1408 77.8624 30.3447 77.5877 30.6165C77.3474 30.8884 77.1414 31.1942 77.0041 31.568C76.8668 31.9418 76.7981 32.3155 76.7981 32.7573Z"
        fill={color}
      />
      <path
        d="M90.6681 33.5388C90.6681 33.9126 90.6338 34.2524 90.5308 34.5922C90.4621 34.932 90.2904 35.2038 90.1188 35.4757C89.9128 35.7475 89.6725 35.9514 89.3292 36.0873C89.0202 36.2572 88.6082 36.3252 88.1619 36.3252C87.6813 36.3252 87.3036 36.2572 86.9947 36.0873C86.6857 35.9174 86.411 35.7135 86.2051 35.4757C85.9991 35.2038 85.8617 34.932 85.7931 34.5922C85.7244 34.2524 85.6558 33.9126 85.6558 33.5388V29.3252H86.308V33.3689C86.308 33.6067 86.3424 33.8786 86.3767 34.1504C86.411 34.4223 86.514 34.6941 86.6514 34.932C86.7887 35.1699 86.9603 35.3737 87.2007 35.5097C87.441 35.6796 87.7499 35.7475 88.1619 35.7475C88.5739 35.7475 88.8829 35.6796 89.1232 35.5097C89.3635 35.3398 89.5695 35.1699 89.6725 34.932C89.8098 34.6941 89.8785 34.4223 89.9471 34.1504C89.9815 33.8786 90.0158 33.6067 90.0158 33.3689V29.3252H90.6681V33.5388Z"
        fill={color}
      />
      <path
        d="M93.4829 29.3592H95.4741C95.7144 29.3592 95.9547 29.3932 96.2294 29.4272C96.504 29.4951 96.7444 29.5631 96.9503 29.699C97.1563 29.835 97.328 30.0049 97.4996 30.2427C97.637 30.4806 97.7056 30.7864 97.7056 31.1262C97.7056 31.432 97.637 31.7039 97.534 31.9078C97.431 32.1456 97.2593 32.3155 97.0877 32.4515C96.8817 32.5874 96.6757 32.6893 96.4354 32.7573C96.1951 32.8252 95.9204 32.8592 95.6458 32.8592H94.1695V36.0874H93.5172V29.3592H93.4829ZM94.1352 32.3495H95.5771C95.7488 32.3495 95.9204 32.3155 96.1264 32.2816C96.2981 32.2476 96.4697 32.1796 96.607 32.0777C96.7444 31.9757 96.8474 31.8738 96.9503 31.7039C97.019 31.534 97.0877 31.3641 97.0877 31.1262C97.0877 30.9223 97.0533 30.7184 96.9503 30.5825C96.8474 30.4126 96.7444 30.3107 96.607 30.2087C96.4697 30.1068 96.2981 30.0388 96.1264 30.0049C95.9547 29.9709 95.7488 29.9369 95.5771 29.9369H94.1695V32.3495H94.1352Z"
        fill={color}
      />
      <path d="M117 41.3204H59.1523V42H117V41.3204Z" fill={color} />
      <path d="M117 0H59.1523V0.679612H117V0Z" fill={color} />
      <path
        d="M29.5933 41.5243H42.1585C47.9948 41.5243 52.6981 36.801 52.6294 31.0243L52.4578 16.9903H29.353V24.9078H44.5273L44.6303 33.5728H29.559V41.5243H29.5933Z"
        fill={color}
      />
      <path
        d="M52.3548 8.01941C52.3548 3.63591 48.75 0.101929 44.3557 0.101929H17.1998H12.7368H0V8.01941H12.7368V33.4709C12.7368 37.8544 16.3416 41.3883 20.7359 41.3883V7.98543L52.3548 8.01941Z"
        fill={color}
      />
    </svg>
  );
};
