import React from "react";
import { InputAdornment, OutlinedInput, Typography } from "@mui/material";

import { StyledPickerStack } from "./styles";

const getInputAdornment = (adornment, position) =>
  adornment ? (
    <InputAdornment position={position}>
      {typeof adornment === "string" ? (
        <Typography variant="inputAdornment">{adornment}</Typography>
      ) : (
        adornment
      )}
    </InputAdornment>
  ) : null;

const HubTimePicker = ({
  time = {},
  handleTimeChange = () => {},
  onlyDays,
}) => {
  const handleChange = (e, time) =>
    handleTimeChange(time, parseInt(e.target.value || 0, 10));

  return (
    <StyledPickerStack onlyDays={onlyDays}>
      <OutlinedInput
        type="number"
        defaultValue={time?.days || 0}
        onChange={(e) => handleChange(e, "days")}
        inputProps={{ min: 0, max: 99 }}
        endAdornment={getInputAdornment("days", "end")}
      />
      {!onlyDays && (
        <>
          <OutlinedInput
            type="number"
            defaultValue={time?.hours || 0}
            onChange={(e) => handleChange(e, "hours")}
            inputProps={{ min: 0, max: 23 }}
            endAdornment={getInputAdornment("hours", "end")}
          />
          <OutlinedInput
            type="number"
            defaultValue={time?.minutes}
            onChange={(e) => handleChange(e, "minutes")}
            inputProps={{ min: 0, max: 59 }}
            endAdornment={getInputAdornment("mins", "end")}
          />
          <OutlinedInput
            type="number"
            defaultValue={time?.seconds}
            onChange={(e) => handleChange(e, "seconds")}
            inputProps={{ min: 0, max: 59 }}
            endAdornment={getInputAdornment("sec", "end")}
          />
        </>
      )}
    </StyledPickerStack>
  );
};

export default HubTimePicker;
