import React from "react";

import { HubTableHeader } from "../../components";

const sortable = true;

const headCells = [
  {
    id: "partName",
    label: "Part",
    sortable,
  },
  {
    id: "lastSentPrice",
    label: "Sales Price",
    sortable,
  },
  {
    id: "material",
    label: "Metal",
  },
  {
    id: "tree",
    label: "Tree",
  },
  {
    id: "weight",
    label: "Weight",
  },
  {
    id: "parts/tree",
    label: "Pts/Tree",
  },
  // DETAILS //
  // {
  //   id: "manualMold",
  //   label: "Manual Mold",
  // },
  // {
  //   id: "flashing",
  //   label: "Flashing",
  // },
  // {
  //   id: "handDipping",
  //   label: "Hand Dipping",
  // },
  // {
  //   id: "handBlasting",
  //   label: "Hand Blasting",
  // },
  // {
  //   id: "gates",
  //   label: "Gates",
  // },
  // {
  //   id: "flush",
  //   label: "Flush",
  // },
  // {
  //   id: "hardToFixture",
  //   label: "Hard To Fixture",
  // },
  // {
  //   id: "weldRepair",
  //   label: "Weld Repair",
  // },
  // {
  //   id: "fineDetail",
  //   label: "Fine Detail",
  // },
  // {
  //   id: "straightening",
  //   label: "Straightening",
  // },
];

export const CompaniesHeader = () => {
  return (
    <HubTableHeader
      items={headCells}
      includeCheckbox={false}
      sx={{ backgroundColor: "hubColors.greyLighter" }}
    />
  );
};

export default CompaniesHeader;
