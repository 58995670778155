import { useMutation, useQuery, useQueryClient } from "react-query";
import qs from "qs-lite";
import api from "../../utils/api";
import { useHubSnackBar } from "../useSnackBar";

export const CUSTOMERS = "/customers";

const getAllCustomers = () => api.get(`${CUSTOMERS}/all`);
export const useGetAllCustomers = () =>
  useQuery([CUSTOMERS, "all"], () => getAllCustomers());

const getCustomerDetailsById = (id) => api.get(`${CUSTOMERS}/${id}`);
export const useGetCustomerDetailsById = ({ id }) =>
  useQuery([CUSTOMERS], () => getCustomerDetailsById(id));

const getCustomerDetails = ({ queryKey }) => {
  const queryString = qs.stringify(queryKey[1]);
  return api.get(`${CUSTOMERS}?${queryString}`);
};

export const useGetCustomerDetails = ({ defaultActive, defaultLocation }) => {
  const query = new URLSearchParams(window.location.search);

  const queryLocation = query.get("location");
  const location =
    queryLocation === "All" ? "" : queryLocation || defaultLocation;
  const active = query.get("active") || defaultActive;

  const orderBy = query.get("orderBy") || "name";
  const sortOrder = query.get("sortOrder") || "asc";
  const limit = query.get("limit") || 100;
  const offset = query.get("offset") || 0;

  const queryProps = {
    ...(location ? { location } : {}),
    ...(active ? { active } : {}),
    limit,
    offset,
    orderBy,
    sortOrder,
  };

  return useQuery([CUSTOMERS, queryProps], getCustomerDetails, {
    enabled: !!defaultActive && !!defaultLocation,
  });
};

const postCustomerData = (data) => api.post(CUSTOMERS, data);
export const usePostCustomerData = () => {
  const { addSnackbarSuccess } = useHubSnackBar();
  const queryClient = useQueryClient();

  return useMutation(postCustomerData, {
    onSuccess: () => {
      queryClient.invalidateQueries(CUSTOMERS);
      addSnackbarSuccess({ message: "Successfully Created Customer" });
    },
  });
};

const putUpdateCustomerActive = (payload) =>
  api.put(`${CUSTOMERS}/active/${payload.id}`, payload.data);
export const usePutUpdateCustomerActive = () => {
  const { addSnackbarSuccess } = useHubSnackBar();
  const queryClient = useQueryClient();

  return useMutation(putUpdateCustomerActive, {
    onSuccess: () => {
      queryClient.invalidateQueries(CUSTOMERS);
      addSnackbarSuccess({ message: "Successfully Updated Customer Status" });
    },
  });
};

const putUpdateCustomerData = (payload) => {
  const { id, ...rest } = payload;
  return api.put(`${CUSTOMERS}/${id}`, { ...rest });
};
export const usePutUpdateCustomerData = () => {
  const { addSnackbarSuccess } = useHubSnackBar();
  const queryClient = useQueryClient();

  return useMutation(putUpdateCustomerData, {
    onSuccess: () => {
      queryClient.invalidateQueries(CUSTOMERS);
      addSnackbarSuccess({ message: "Successfully Updated Customer" });
    },
  });
};

const deleteCustomerById = (id) => api.delete(`${CUSTOMERS}/${id}`);
export const useDeleteCustomerById = () => {
  const { addSnackbarSuccess } = useHubSnackBar();
  const queryClient = useQueryClient();

  return useMutation(deleteCustomerById, {
    onSuccess: () => {
      queryClient.invalidateQueries(CUSTOMERS);
      addSnackbarSuccess({ message: "Successfully Deleted Customer" });
    },
  });
};
