import React, { useState } from "react";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";

import { HubIcon } from "../HubIcon";
import HubContextMenuItem from "./HubContextMenuItem";
import HubCircularProgresss from "../HubCircularProgress/HubCircularProgress";
import { StyledMenu } from "./styles";

export const HubContextMenu = ({
  id,
  menuItems,
  onSelect,
  loading = false,
  optionalLabelValue = "label",
  menuButton,
  children,
  IconButtonProps,
  MenuProps,
  disabled,
  EndElements,
  HubIconProps = { size: 14 },
  ...rest
}) => {
  const iconId = `more-button-${id}`;
  const menuId = `more-menu-${id}`;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };
  const onItemSelect = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
    onSelect(item);
  };

  if (disabled)
    return (
      <>
        {menuButton ? (
          menuButton
        ) : (
          <HubIcon icon="MoreVertical" {...HubIconProps} />
        )}
      </>
    );

  return (
    <>
      {loading ? (
        <HubCircularProgresss size={24} />
      ) : (
        <IconButton
          id={iconId}
          aria-controls={open ? menuId : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          {...IconButtonProps}
        >
          {menuButton ? (
            menuButton
          ) : (
            <HubIcon icon="MoreVertical" {...HubIconProps} />
          )}
        </IconButton>
      )}
      <StyledMenu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock
        MenuListProps={{
          "aria-labelledby": iconId,
        }}
        {...MenuProps}
        sx={{
          "& .MuiPaper-root": {
            minWidth: "220px",
            ...MenuProps?.sx,
          },
        }}
      >
        {children
          ? children
          : menuItems.map((item) => (
              <HubContextMenuItem
                key={item?.id}
                item={item}
                optionalLabelValue={optionalLabelValue}
                onSelect={onItemSelect}
                {...rest}
              />
            ))}
        {EndElements}
      </StyledMenu>
    </>
  );
};

HubContextMenu.propTypes = {
  onSelect: PropTypes.func,
  id: PropTypes.string,
  menuItems: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  optionalLabelValue: PropTypes.string,
  menuButton: PropTypes.node,
  children: PropTypes.node,
  IconButtonProps: PropTypes.object,
  MenuProps: PropTypes.object,
};

export default HubContextMenu;
