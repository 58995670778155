import React from "react";
import { Stack, Typography } from "@mui/material";
import { connect } from "react-redux";
import dayjs from "dayjs";

import {
  PERIODS,
  PERIOD_LABEL,
  PERIOD_OPTIONS,
  PERIOD_STEPS,
} from "../../constants";
import {
  getOrdersEndDate,
  getOrdersPeriod,
  getOrdersStartDate,
  setOrdersEndDate,
  setOrdersPeriod,
  setOrdersStartDate,
} from "../../redux";
import {
  HubButton,
  HubHookFormDatePicker,
  HubHookFormWrapper,
} from "../../components";

import { StyledHubHookFormSelect } from "./styles";

export const JobsFilterMenu = ({
  period = "",
  startDate = "",
  endDate = "",
  _setOrdersPeriod = () => {},
  _setOrdersStartDate = () => {},
  _setOrdersEndDate = () => {},
}) => {
  const shouldDisableDate = (date) => date.day() !== 0;

  const handleChange = (date, isEnd) => {
    const origin = dayjs(date); // start new dayjs object from date;
    const label = PERIOD_LABEL[period]; // get label from period
    const final = origin.startOf(label); // start of week/month/year;
    const formatted = final.format("MM/DD/YYYY"); // turn date to string

    // count increments for bars
    const ogs = dayjs(startDate);
    const steps = isEnd ? origin.diff(ogs, label) : PERIOD_STEPS[period];

    if (isEnd) _setOrdersEndDate(formatted, steps);
    else {
      const end = origin.add(PERIOD_STEPS[period], label);
      const formattedEnd = end.format("MM/DD/YYYY");
      _setOrdersStartDate(formatted);
      _setOrdersEndDate(formattedEnd, steps);
    }
  };

  const dateProps = {
    disableHelperText: true,
    required: true,
    ...(period === PERIODS.weekly ? { shouldDisableDate } : {}),
    ...(period === PERIODS.monthly ? { views: ["month", "year"] } : {}),
    ...(period === PERIODS.yearly ? { views: ["year"] } : {}),
  };

  return (
    <HubHookFormWrapper onSubmit={() => {}}>
      <Stack gap="15px" padding="15px" maxWidth="550px">
        <Stack>
          <Typography variant="boldCaption" color="hubColors.mainFocus">
            Filter Jobs By Period
          </Typography>
          <Typography variant="caption">
            This filter adjusts the graph below, grouping jobs by the period
            selected as well as the number of data points you select within a
            start and end date.
          </Typography>
        </Stack>

        <Stack direction="row" gap="5px">
          <StyledHubHookFormSelect
            name="period"
            label="Period"
            defaultValue={period}
            options={PERIOD_OPTIONS}
            onChange={(e) => _setOrdersPeriod(e.target.value)}
            fullWidth={false}
            required
          />
          <HubHookFormDatePicker
            name="startDate"
            label="Start Date"
            onAccept={handleChange}
            defaultValue={startDate}
            showDaysOutsideCurrentMonth
            {...dateProps}
          />
          <HubHookFormDatePicker
            name="endDate"
            label="End Date"
            onAccept={(date) => handleChange(date, true)}
            defaultValue={endDate}
            showDaysOutsideCurrentMonth
            {...dateProps}
          />
        </Stack>

        <HubButton
          label="Reset Filter"
          variant="text"
          size="small"
          sx={{ padding: "0px 5px" }}
          onClick={() => _setOrdersPeriod("weekly")}
        />
      </Stack>
    </HubHookFormWrapper>
  );
};

const mapStateToProps = (state) => ({
  period: getOrdersPeriod(state),
  startDate: getOrdersStartDate(state),
  endDate: getOrdersEndDate(state),
});
const mapDispatchToProps = {
  _setOrdersPeriod: setOrdersPeriod,
  _setOrdersStartDate: setOrdersStartDate,
  _setOrdersEndDate: setOrdersEndDate,
};
export default connect(mapStateToProps, mapDispatchToProps)(JobsFilterMenu);
