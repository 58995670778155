import React from "react";
import { Box, Stack, Typography } from "@mui/material";

import { DialogButton, HubButton } from "../../components";
import { EmptyParts } from "../../svgs";

const CustomerPartsEmpty = () => {
  return (
    <Stack gap="25px" margin="auto" alignItems="center">
      <Box component="img" src={EmptyParts} sx={{ height: "150px" }} />
      <Stack gap="10px" alignItems="center" textAlign="center">
        <Typography variant="boldCaption">No Parts Found</Typography>
        <Typography variant="caption" width="250px">
          Click "Create Part" button to get started in creating a part for this
          customer
        </Typography>
        <Stack direction="row" gap="10px">
          <HubButton
            variant="outlined"
            size="small"
            color="neutral"
            label="Learn More"
          />
          <DialogButton
            name="create-customer-part"
            label="Create Part"
            variant="contained"
            size="xsmall"
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CustomerPartsEmpty;
