import React from "react";
import { Stack, Typography } from "@mui/material";
import { connect } from "react-redux";

import { HubHookFormInput, HubMaterialHookSelect } from "../../../components";
import { getPartRoutingDetails } from "../../../redux/routing";

const gap = "15px";
const columnGap = "10px";

const PartMainDetails = ({ details = {} }) => {
  return (
    <Stack gap={gap}>
      <Typography variant="boldCaptionMain">Part Details</Typography>
      <Stack direction="row" gap={columnGap}>
        <HubHookFormInput
          name="partName"
          label="Part Name"
          defaultValue={details?.partName}
          placeholder="ex. Pocket"
          required
        />
        <HubHookFormInput
          name="partNumber"
          label="Part Number"
          defaultValue={details?.partNumber}
          placeholder="ex. 29-100C"
          required
        />
      </Stack>
      <Stack direction="row" gap={columnGap}>
        <HubMaterialHookSelect
          defaultValue={details?.material}
          name="materialId"
          label="Metal Alloy"
        />
        <HubMaterialHookSelect
          type="trees"
          label="Tree Type"
          name="treeId"
          defaultValue={details?.tree}
        />
        <HubHookFormInput
          name="partsPerTree"
          label="Parts / Tree"
          defaultValue={details?.ppt}
          placeholder="Ex. 12"
          required
          isNumber
        />
      </Stack>
      <Stack direction="row" gap={columnGap}>
        <HubHookFormInput
          name="weight"
          label="Part Weight"
          defaultValue={details?.weight}
          placeholder="ex. 4.55"
          endAdornment="lbs"
          isDecimal
          required
        />
        <HubHookFormInput
          name="lastSentPrice"
          label="Price"
          defaultValue={details?.price}
          placeholder="ex. 4.55"
          startAdornment="$"
          isDecimal
          required
        />
      </Stack>
    </Stack>
  );
};
const mapStateToProps = (state) => ({
  details: getPartRoutingDetails(state),
});

export default connect(mapStateToProps)(PartMainDetails);
