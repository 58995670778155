import React from "react";
import { Stack } from "@mui/material";

import { HubHookFormInput, HubHookFormSwitch } from "../../components";
import { FullWidthStack, SubTitle, Title } from "./styles";

const EditCustomerForm = ({
  disabled = false,
  contact = {},
  isUpdate = true,
}) => {
  return (
    <Stack gap="30px" width="100%">
      <FullWidthStack gap="10px">
        <Title>{isUpdate ? "Update" : "Create"} Customer Contact</Title>
        <SubTitle variant="dialogBody2">
          {isUpdate ? "Update" : "Create"} information for customer's
          contact
        </SubTitle>
      </FullWidthStack>
      <Stack gap="15px">
        <HubHookFormInput
          name="title"
          label="Title"
          placeholder="ex. Sales Manager"
          defaultValue={contact?.title}
          disabled={disabled}
          disableHelperText
        />
        <HubHookFormInput
          name="name"
          label="Name"
          placeholder="ex. John Barker"
          defaultValue={contact?.name}
          disabled={disabled}
          disableHelperText
          required
        />
        <HubHookFormInput
          name="email"
          label="Email"
          placeholder="ex. john.barker@foundry.com"
          defaultValue={contact?.email}
          disabled={disabled}
          disableHelperText
          isEmail
        />
        <Stack direction="row" gap="10px">
          <HubHookFormInput
            name="cellNumber"
            label="Phone"
            placeholder="ex. (800) 000-0000"
            defaultValue={contact?.phoneNumber}
            disabled={disabled}
            disableHelperText
          />
          <HubHookFormInput
            name="workNumber"
            label="Work Number"
            placeholder="ex. (800) 000-0000"
            defaultValue={contact?.workNumber}
            disabled={disabled}
            disableHelperText
          />
        </Stack>
        <HubHookFormSwitch
          name="isPrimary"
          label="Mark this contact as the Primary Contact"
          defaultValue={contact?.isPrimary}
          labelPlacement="end"
          disabled={disabled}
          disableHelperText
          isCheckbox
        />
      </Stack>
    </Stack>
  );
};

export default EditCustomerForm;
