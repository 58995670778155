import { createSelector } from "reselect";

const LOAD_CUSTOMER_DATA = "LOAD_CUSTOMER_DATA";
const CLEAR_CUSTOMER_DATA = "CLEAR_CUSTOMER_DATA";
const ADD_CUSTOMER_ADDRESS = "ADD_CUSTOMER_ADDRESS";
const REMOVE_CUSTOMER_ADDRESS = "REMOVE_CUSTOMER_ADDRESS";
const UPDATE_CUSTOMER_ADDRESS = "UPDATE_CUSTOMER_ADDRESS";

const initialState = {
  id: 0,
  active: true,
  addresses: [],
  contacts: [],
  customer: "",
  industry: false,
  location: "UT",
  orders: [],
  priority: "B-",
  revenue: [],
};

const addressTemplate = {
  street: "",
  unit: "",
  state: "",
  zip: "",
  country: "",
  name: "",
};

const addNewAddressHelper = (addresses = []) => {
  const newItems = [...addresses, addressTemplate];
  return newItems;
};

const findItemByIndex = (items = [], idx) => (items.length >= idx ? idx : -1);

const removeAddressHelper = (adddresses, idx) => {
  const index = findItemByIndex(adddresses, idx);
  if (index === -1) return adddresses;

  const newItems = [
    ...adddresses.slice(0, index),
    ...adddresses.slice(index + 1),
  ];
  return newItems;
};

const updateAddressHelper = (items, idx, data = {}) => {
  const index = findItemByIndex(items, idx);
  if (index === -1) return items;

  const updatedItem = {
    ...items[index],
    ...data,
  };

  const newItems = [
    ...items.slice(0, index),
    updatedItem,
    ...items.slice(index + 1),
  ];

  return newItems;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "FLUSH":
    case CLEAR_CUSTOMER_DATA: {
      return initialState;
    }

    case LOAD_CUSTOMER_DATA: {
      const newState = {
        id: action.data.id,
        active: action.data.active,
        addresses: action.data.addresses || [],
        contacts: action.data.contacts || [],
        customer: action.data.customer,
        industry: action.data.industry,
        location: action.data.location,
        orders: action.data.orders || [],
        parts: action.data.parts || [],
        priority: action.data.priority,
        revenue: action.data.revenue,
      };
      return newState;
    }

    case ADD_CUSTOMER_ADDRESS: {
      const newState = {
        ...state,
        addresses: addNewAddressHelper(state.addresses),
      };
      return newState;
    }

    case REMOVE_CUSTOMER_ADDRESS: {
      const newState = {
        ...state,
        addresses: removeAddressHelper(state.addresses, action.idx),
      };
      return newState;
    }

    case UPDATE_CUSTOMER_ADDRESS: {
      const newState = {
        ...state,
        addresses: updateAddressHelper(
          state.addresses,
          action.idx,
          action.data
        ),
      };
      return newState;
    }

    default:
      return state;
  }
}

export function setCustomerData(data = {}) {
  return {
    type: LOAD_CUSTOMER_DATA,
    data,
  };
}

export function clearCustomerData() {
  return {
    type: CLEAR_CUSTOMER_DATA,
  };
}

export const addCustomerAddress = () => ({
  type: ADD_CUSTOMER_ADDRESS,
});

export const removeCustomerAddress = (idx) => ({
  type: REMOVE_CUSTOMER_ADDRESS,
  idx,
});

export const updateCustomerAddress = (idx, data) => ({
  type: UPDATE_CUSTOMER_ADDRESS,
  idx,
  data,
});

export const getCustomerDataState = createSelector(
  (state) => state,
  (state) => state?.customerData
);

export const getCustomerData = createSelector(
  getCustomerDataState,
  (customerState) => ({
    id: customerState.id,
    active: customerState.active,
    customer: customerState.customer,
    industry: customerState.industry,
    location: customerState.location,
    priority: customerState.priority,
  })
);

export const getCustomerId = createSelector(
  getCustomerData,
  (customerData) => customerData?.id
);

export const getCustomerDataAddresses = createSelector(
  getCustomerDataState,
  (customerState) => customerState?.addresses || []
);

export const getCustomerDataContacts = createSelector(
  getCustomerDataState,
  (customerState) => customerState?.contacts || []
);

export const getCustomerDataOrders = createSelector(
  getCustomerDataState,
  (customerState) => customerState?.orders || []
);

export const getCustomerDataParts = createSelector(
  getCustomerDataState,
  (customerState) => customerState?.parts || []
);

export const getCustomerPartsCount = createSelector(
  getCustomerDataParts,
  (customerParts) => customerParts?.length || 0
);

export const getCustomerOrderCount = createSelector(
  getCustomerDataOrders,
  (customerOrders) => customerOrders?.length || 0
);

export const getCustomerContactsCount = createSelector(
  getCustomerDataContacts,
  (customerContacts) => customerContacts?.length || 0
);

export const getCustomerDataRevenue = createSelector(
  getCustomerDataState,
  (customerState) => customerState.revenue || []
);
