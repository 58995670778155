import React from "react";
import { HubButton } from "../inputs";
import { useNavigate } from "react-router-dom";

export const HubBackButton = ({ onBack, backLinkOverride }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    onBack && onBack();
    backLinkOverride
      ? navigate(backLinkOverride, { replace: true })
      : navigate(-1);
  };

  return (
    <HubButton
      size="xsmall"
      variant="contained"
      color="grey"
      icon="ArrowLeft"
      shape="sharp"
      onClick={handleClick}
      sx={{
        minWidth: 0,
        padding: "5px",
      }}
      iconProps={{ size: 12 }}
    />
  );
};

export default HubBackButton;
