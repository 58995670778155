import { styled } from "@mui/material/styles";
import React from "react";

const StyledRadioWrapper = styled("div")(({ checked }) => ({
  display: "inline-block",
  transition: "transform 0.3s ease-in-out, color 0.3s ease-in-out",
  transform: checked ? "scale(1.2)" : "scale(1)", // Adjust scale for animation
  "& svg": {
    transition: "fill 0.3s ease-in-out",
  },
}));

export const CustomRadio = ({ checked }) => {
  return (
    <StyledRadioWrapper checked={checked}>
      {checked ? <CheckedRadio /> : <Radio />}
    </StyledRadioWrapper>
  );
};

export const Radio = ({ color = "#C0C0C0", size = 18 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="11" stroke={color} stroke-width="2" />
    </svg>
  );
};

export const CheckedRadio = ({ color = "#DF9E44", size = 18 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill={color}
      />
      <g filter="url(#filter0_d_676_5092)">
        <path
          d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_676_5092"
          x="7"
          y="8"
          width="10"
          height="10"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.691124 0 0 0 0 0.448352 0 0 0 0 0.112205 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_676_5092"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_676_5092"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
