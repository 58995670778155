import { styled } from '@mui/material/styles';
import { Tab, Tabs } from '@mui/material';

export const StyledTab = styled(Tab, {
    shouldForwardProp: (prop) => !["active"].includes(prop),
})(({ active, theme }) => ({
    fontSize: "12px",
    textTransform: "none",
    color: theme.palette.hubColors.grey,
    padding: "10px 14px",
    minHeight: "29px",
    minWidth: "75px",
    ...(active ? {
        borderBottom: "2px solid",
        borderColor: theme.palette.hubColors.mainFocus,
        color: theme.palette.hubColors.mainFocus,
        paddingBottom: "8px",
        borderRadius: "5px 5px 0 0"
    } : {})
}))

export const StyleTabContainer = styled(Tabs)({
    overflow: "visible",
    minHeight: "35px",
    marginBottom: -1,
    "& .MuiTabs-scroller": {
        overflow: "visible !important"
    }
})