import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/material";
// import Background from "./2023-09-20.jpg";

export const StyledStack = styled(Stack)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",

  width: "100vw",
  height: "100vh",

  alignContent: "center",
  justifyItems: "center",

  backgroundColor: theme.palette.hubColors.greyLightest,
  // backgroundImage: `url(${Background})`,
  // backgroundPosition: "100% 0%",
}));

export const StyledBox = styled(Box)({
  display: "grid",
  gridTemplateColumns: "50% 50%",

  width: "100vw",
  height: "100vh",

  alignContent: "center",
  justifyItems: "center",
});
export const StyledStackTwo = styled(Stack)(({ theme }) => ({
  width: "100%",
  height: "100vh",
  justifyContent: "center",
  alignItems: "center",
  gap: "25px",
  padding: "45px",
  boxSizing: "border-box",
}));
export const StyledStackThree = styled(Stack)(({ theme }) => ({
  flexDirection: "column",
  gap: "25px",
  width: "65%",
  minWidth: "390px",
  alignItems: "center",
  justifySelf: "center",
  margin: "auto !important",
  borderRadius: "10px",
  padding: "50px",
  border: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  backgroundColor: theme.palette.hubColors.white,
}));
export const StyledStackFour = styled(Stack)(({ theme }) => ({
  width: "100%",
  height: "100vh",
  padding: "25px",
  boxSizing: "border-box",
  alignItems: "center",
  justifyContent: "center",
  // borderLeft: "1px solid",
  // borderColor: theme.palette.hubColors.greyLight,
}));

export const StyledTransparantStack = styled(Stack)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.22)",
  alignItems: "start",
  justifyContent: "center",
  borderRadius: "50px",
  padding: "100px 50px",
  boxSizing: "border-box",
  width: "550px",
  gap: "50px",
  boxShadow: "0 0 50px rgba(0,0,0,0.05)",
  "& .MuiTypography-root": {
    span: {
      color: theme.palette.hubColors.greyDark2,
    },
  },
  "& .MuiDivider-root": {
    borderColor: "rgba(255, 255, 255, 0.5)",
  },
  svg: {
    fill: theme.palette.hubColors.fontWhite,
  },
}));
