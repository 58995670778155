import React from "react";

import { HubTableHeader } from "../../components";

const sortable = true;
const headCells = [
  {
    id: "workOrder",
    label: "WO #",
    sortable
  },
  {
    id: "customer",
    label: "Customer",
  },
  {
    id: "part",
    label: "Part",
  },
  // {
  //   id: "po",
  //   label: "PO #",
  // },
  {
    id: "qty",
    label: "Qty",
  },
  {
    id: "price",
    label: "Price",
  },
  {
    id: "hours",
    label: "Hours",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "startDate",
    label: "Start Date",
    sortable
  },
  {
    id: "dueDate",
    label: "Due Date",
    sortable
  },
  {
    id: "location",
    label: "State",
    sortable
  },
];

const JobsTableHeader = () => {
  return (
    <HubTableHeader
      items={headCells}
      includeCheckbox={false}
      includeEnd={false}
      sx={{ backgroundColor: "hubColors.greyLighter" }}
    />
  );
};

export default JobsTableHeader;
