import React from "react";
import { TableBody } from "@mui/material";
import { connect } from "react-redux";

import { getPartOutsideRoutes } from "../../redux/routing";
import OutsideProcessingTableRow from "./OutsideProcessingTableRow";

const OutsideProcessingTableBody = ({ routes = [] }) => {
  return (
    <TableBody>
      {routes?.map((route, idx) => {
        const key = route.id || idx;
        const id = `${idx}-${key}`;
        const props = { key, idx, route, id };
        return <OutsideProcessingTableRow {...props} />;
      })}
    </TableBody>
  );
};

const mapStateToProps = (state) => ({
  routes: getPartOutsideRoutes(state),
});

export default connect(mapStateToProps)(OutsideProcessingTableBody);
