import React from "react";

import { Helmet } from "react-helmet";

export const PageTabTitle = ({ title = "Foundry Group" }) => {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export default PageTabTitle;
