import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { PageTabTitle, TopNavBar, AuthenticatedUser } from "./components";

import { routes } from "./routes";

const AuthenticatedRoute = ({ auth, title, element }) => {
  if (auth) {
    return (
      <AuthenticatedUser>
        <PageTabTitle title={title} />
        <TopNavBar />
        {element}
      </AuthenticatedUser>
    );
  }

  return (
    <>
      <PageTabTitle title={title} />
      {element}
    </>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        {routes?.map((route, key) => (
          <Route
            path={route?.path}
            element={
              <AuthenticatedRoute
                auth={route?.authenticate}
                element={route?.element}
                title={route?.title}
              />
            }
            key={key}
          />
        ))}
      </Routes>
    </Router>
  );
};

export default App;
