import React, { useEffect } from "react";
import { TableCell } from "@mui/material";
import { connect } from "react-redux";

import { updatePoLineItem, fetchPartJobData } from "../../redux/purchaseOrder";
import { HubHookFormInput, HubHookFormSelect } from "../../components";
import { PART_STATUS } from "../../constants/parts";
import { getCustomerDataParts } from "../../redux/customerData";

import { onUpdateDateHelper } from "./helper";
import { StyledHubHookFormDatePicker, StyledTableRow } from "./styles";

import LineItemContextMenu from "./LineItemContextMenu";
import OrderSublineRow from "./OrderSublineRow";

const OrderLineItemRow = ({
  idx,
  item = {},
  parts = [],
  _updateLineItem = () => {},
  _fetchPartJobData = () => {},
}) => {
  const partItems = parts?.map((p) => ({ id: p.id, label: p.name }));

  const updateLineItemAttr = (key, value) => {
    const data = item;
    data[key] = value;

    _updateLineItem(idx, data);
  };

  const onUpdatePart = (partId) => {
    const part = parts?.find((p) => p?.id === partId);
    if (part?.id) {
      const data = {
        ...item,
        price: undefined,
        part,
      };

      _updateLineItem(idx, data);
      _fetchPartJobData(part?.id, idx);
    }
  };

  const onUpdateQuantity = (quantity) => {
    const trees = Math.ceil(quantity / item?.part?.partsPerTree);
    const patReq = trees * item?.part?.partsPerTree;

    const data = {
      ...item,
      quantity: parseInt(quantity),
      trees,
      patReq,
    };

    _updateLineItem(idx, data);
  };

  const onUpdateDate = (value, isStart = false) => {
    const newDates = onUpdateDateHelper(value, isStart, item?.part?.totalDays);

    const data = {
      ...item,
      ...newDates,
    };
    _updateLineItem(idx, data);
  };

  useEffect(() => {
    if (!item?.part?.id) {
      partItems?.length && onUpdatePart(partItems[0]?.id);
    }
  }, []);

  useEffect(() => {
    item.quantity && onUpdateQuantity(item.quantity);
  }, [item.quantity]);

  const WorkOrder =
    item?.overrideWo || `W${(item?.workOrder).toString().padStart(5, "0")}`;

  return (
    <>
      <StyledTableRow key={item.id} bgDisabled>
        <TableCell sx={{ width: 75 }}>
          <HubHookFormInput
            name={"line" + idx}
            placeholder="Line"
            defaultValue={item?.line || "1"}
            onBlur={(e, value) => updateLineItemAttr("line", value)}
            disableHelperText
            required
          />
        </TableCell>
        <TableCell sx={{ minWidth: 250 }}>
          <HubHookFormSelect
            name={"part" + idx}
            onChange={(e) => onUpdatePart(e?.target?.value)}
            disabled={!partItems?.length}
            defaultValue={item?.part?.id}
            options={partItems}
            disableHelperText
            required
          />
        </TableCell>
        <TableCell sx={{ width: 100 }}>
          <HubHookFormInput
            name={"workOrder" + idx}
            placeholder="W00000"
            defaultValue={WorkOrder}
            onBlur={(e, value) => updateLineItemAttr("overrideWo", value)}
            disableHelperText
            required
          />
        </TableCell>
        <TableCell sx={{ width: 100 }}>
          <HubHookFormInput
            name={"lotNumber" + idx}
            placeholder="UT240000"
            defaultValue={item?.lotNumber}
            onBlur={(e, value) => updateLineItemAttr("lotNumber", value)}
            disableHelperText
            required
          />
        </TableCell>
        <TableCell sx={{ width: 80 }}>
          <HubHookFormInput
            name={"qty" + idx}
            placeholder="1000"
            defaultValue={item?.quantity}
            onBlur={(e, value) => onUpdateQuantity(value)}
            disableHelperText
            isNumber
            required
          />
        </TableCell>
        <TableCell sx={{ width: 125 }}>
          <HubHookFormInput
            name={"price" + idx}
            placeholder="10.00"
            defaultValue={item?.price || item?.part?.lastSentPrice}
            onBlur={(e, value) => updateLineItemAttr("price", value)}
            disableHelperText
            isCurrency
            required
          />
        </TableCell>
        <TableCell sx={{ width: 125 }}>
          <StyledHubHookFormDatePicker
            name={"start" + idx}
            placeholder="MM/DD/YYYY"
            defaultValue={item?.startDate}
            onChange={(value) => onUpdateDate(value, true)}
            disabled={!item?.part?.id}
            required
          />
        </TableCell>
        <TableCell sx={{ width: 125 }}>
          <StyledHubHookFormDatePicker
            name={"due" + idx}
            placeholder="MM/DD/YYYY"
            defaultValue={item?.dueDate}
            onChange={(value) => onUpdateDate(value)}
            disabled={!item?.part?.id}
            required
          />
        </TableCell>
        <TableCell sx={{ maxWidth: 35 }}>
          <LineItemContextMenu id={idx} />
        </TableCell>
      </StyledTableRow>
      <OrderSublineRow item={item} />
    </>
  );
};

const mapStateToProps = (state) => ({
  parts: getCustomerDataParts(state),
});

const mapDispatchToProps = {
  _updateLineItem: updatePoLineItem,
  _fetchPartJobData: fetchPartJobData,
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderLineItemRow);
