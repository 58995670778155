import React from "react";
import { connect } from "react-redux";
import { TableRow, TableCell, TableBody, Checkbox } from "@mui/material";

import { getCustomerDataContacts } from "../../redux/customerData";
import ContactContextMenu from "./ContactContextMenu";

const CustomerContactsBody = ({ contacts = [], onRowClick = () => {} }) => {
  const onClick = (e, contact) => {
    e.preventDefault();
    e.stopPropagation();

    onRowClick(contact);
    const button = document.getElementById("EditContact");
    button?.click();
  };

  return (
    <TableBody>
      {contacts?.map((contact) => (
        <TableRow key={contact.id} onClick={(e) => onClick(e, contact)}>
          <TableCell padding="checkbox">
            <Checkbox
              checked={contact.isPrimary}
              sx={{ padding: 0, mx: "auto", width: "100%" }}
            />
          </TableCell>
          <TableCell>{contact.name}</TableCell>
          <TableCell>{contact.title}</TableCell>
          <TableCell>{contact.email}</TableCell>
          <TableCell>{contact.phoneNumber}</TableCell>
          <TableCell>{contact.workNumber}</TableCell>
          <TableCell padding="checkbox">
            <ContactContextMenu id={contact.id} />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

const mapStateToProps = (state) => ({
  contacts: getCustomerDataContacts(state),
});

export default connect(mapStateToProps)(CustomerContactsBody);
