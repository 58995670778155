import React, { useState } from "react";
import { Stack } from "@mui/material";
import { connect } from "react-redux";

import { getCustomerDataRevenue } from "../../redux/customerData";

import { DataRateContainer, HubCardStack, HubTabs } from "../../components";
import {
  CustomerOrders,
  CustomerContacts,
  CustomerParts,
} from "../../containers";

import { StyledDataStack } from "./styles";

const tabs = [
  {
    id: "orders",
    label: "Orders",
    view: <CustomerOrders />,
  },
  {
    id: "contacts",
    label: "Contacts",
    view: <CustomerContacts />,
  },
  {
    id: "parts",
    label: "Parts",
    view: <CustomerParts />,
  },
];

const ContactContent = ({ revenue = [] }) => {
  const [value, setValue] = useState(tabs[0].id);
  const index = tabs.findIndex((tab) => tab.id === value);

  return (
    <Stack
      width="100%"
      padding="25px 15px 25px 25px"
      gap="10px"
      overflow="clip auto"
      sx={{
        scrollbarGutter: "stable",
      }}
    >
      <StyledDataStack>
        {revenue.map((data) => (
          <DataRateContainer {...data} />
        ))}
      </StyledDataStack>
      <HubCardStack gap="25px">
        <HubTabs tabs={tabs} value={value} setValue={setValue} />
        {index !== -1 && tabs[index].view}
      </HubCardStack>
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  revenue: getCustomerDataRevenue(state),
});
export default connect(mapStateToProps)(ContactContent);
