import React from "react";

import { HubTableHeader } from "../../components";

export const CustomerOrdersHeader = ({
  includeCustomer = false,
  includeCheckbox = false,
  sortable = false
}) => {
  const headCells = [
    {
      id: "parts",
      label: "Parts",
    },
    {
      id: "poNumber",
      label: "PO #",
      sortable
    },
    {
      id: "wo",
      label: "WO #",
    },
    {
      id: "status",
      label: "Status",
    },
    {
      id: "state",
      label: "Location",
      sortable
    },
    {
      id: "total",
      label: "Revenue",
      sortable,
    },
    {
      id: "createdAt",
      label: "Created Date",
      sortable,
    },
  ];
  
  const CompanyItem = {
    id: "customer",
    label: "Customer",
  };

  const ITEMS = includeCustomer ? [CompanyItem, ...headCells] : headCells;

  return (
    <HubTableHeader
      items={ITEMS}
      includeCheckbox={includeCheckbox}
      sx={{ backgroundColor: "hubColors.greyLighter" }}
    />
  );
};

export default CustomerOrdersHeader;
