import React from "react";
import {
  Stack,
  TableCell,
  TableRow,
  Typography,
  Collapse,
} from "@mui/material";
import { getDollarValue } from "../../helpers/currency";

import OrderItemDetails from "./OrderItemDetails";
import { StyledSubStack } from "./styles";

const OrderSublineRow = ({ item = {}, isOpen = true }) => {
  const sx = isOpen
    ? { ":hover": { backgroundColor: "transparent !important" } }
    : { border: "none" };

  const getWaxHours = () => {
    const hours = (item?.quantity * Number(item?.part?.waxInjection || 0)) / 60;
    const rounded = Math.round(hours * 100) / 100;
    return rounded;
  };

  return (
    <TableRow sx={sx} disabled>
      <TableCell colSpan={10} sx={{ padding: "0px !important" }}>
        <Collapse in={isOpen}>
          <StyledSubStack>
            <Stack gap="10px" alignItems="center" direction="row">
              <OrderItemDetails item={item} />
              <Typography variant="caption">
                Wax Hours: <strong>{getWaxHours()}</strong>
              </Typography>
            </Stack>

            <Typography variant="boldCaption" sx={{ fontSize: 12 }}>
              {getDollarValue(
                item.quantity * (item?.price || item?.part?.lastSentPrice) ||
                  0.0
              )}
            </Typography>
          </StyledSubStack>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default OrderSublineRow;
