import React from "react";

import { HubContextMenu } from "../../components";
import { useDeleteContactById, usePutUpdateContactPrimary } from "../../hooks";

const MENU_ITEMS = [
  {
    id: "primary",
    label: "Mark as Primary",
    icon: "User",
  },
  {
    id: "delete",
    label: "Delete",
    icon: "Trash2",
    color: "redError",
  },
];

export const ContactContextMenu = ({ active, id, ...rest }) => {
  const { mutate: deleteContactById } = useDeleteContactById();
  const { mutate: putUpdateContactPrimary } = usePutUpdateContactPrimary();

  const onSelect = (item) => {
    switch (item?.id) {
      case "primary":
        putUpdateContactPrimary({ id, isPrimary: true });
        break;
      case "delete":
        deleteContactById(id);
        break;
      default:
        break;
    }
  };

  return (
    <HubContextMenu
      id={"contacts"}
      loading={false}
      menuItems={MENU_ITEMS}
      onSelect={onSelect}
      {...rest}
    />
  );
};

export default ContactContextMenu;
