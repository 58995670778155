export const templates = [
    {
      id: 0,
      title: "New",
      label: "Build Part From Scratch",
      icon: "Settings",
    },
    {
      id: 1,
      title: "Multiple PPT Template",
      label: "Part To Reuse Common Routing",
      icon: "RefreshCw",
    },
    {
      id: 2,
      title: "Single PPT Template",
      label: "Unique Routing for Common Disks",
      icon: "CheckCircle",
    },
    {
      id: 3,
      title: "International Template",
      label: "Routing for international parts",
      icon: "Globe",
    },
  ];