import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

export const StyledStack = styled(Stack)({
  flexDirection: "row",
  gap: "7px",
  alignItems: "center",
});
export const StyledSignUpStack = styled(Stack)({
  flexDirection: "column",
  gap: "10px",
  justifyContent: "space-between",
  padding: "30px",
  width: "100vw",
  height: "100vh",
  boxSizing: "border-box",
  backgroundSize: "cover",
  overflow: "auto",
});
export const StyledSignUpModal = styled(Stack)({
  padding: "50px",
  minWidth: "30%",
  width: "fit-content",
  maxWidth: "90%",
  flexDirection: "column",
  alignItems: "center",
  justifySelf: "center",
  borderRadius: "10px",
  boxShadow: "0 0 3px rgba(0,0,0,0.35)",
});
