import { CircularProgress } from "@mui/material";
import React from "react";

const centerScreenStyles = {
  position: "absolute",
  margin: "auto",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};
export const HubCircularProgress = ({ size = "2em", centerScreen = false }) => {
  const styles = {
    color: "hubColors.mainFocus",
    ...(centerScreen ? centerScreenStyles : {}),
  };
  return <CircularProgress size={size} sx={styles} />;
};

export default HubCircularProgress;
