import React from "react";
import { Stack } from "@mui/material";

import { JOB_STATUSES } from "../../constants/jobs";
import { HubFilterMenu, HubHookFormWrapper } from "../../components";
import ContactsLocationFilter from "../../pages/Companies/ContactsLocationFilter";

const options = [
  {
    id: JOB_STATUSES.NOT_STARTED,
    label: JOB_STATUSES.NOT_STARTED,
  },
  {
    id: JOB_STATUSES.IN_PROGRESS,
    label: JOB_STATUSES.IN_PROGRESS,
  },
  {
    id: JOB_STATUSES.COMPLETE,
    label: JOB_STATUSES.COMPLETE,
  },
];

const JobsFilters = () => {
  const defaultStatus = options?.map((op) => op.id);

  return (
    <HubHookFormWrapper onSubmit={() => {}} sx={{ width: "fit-content" }}>
      <Stack direction="row" gap="5px">
        <ContactsLocationFilter />
        <HubFilterMenu
          id="status"
          options={options}
          menuLabel="Filter orders by their status"
          label="Status"
          defaultValue={defaultStatus}
        />
      </Stack>
    </HubHookFormWrapper>
  );
};

export default JobsFilters;
