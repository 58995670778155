import React from "react";
import { Box } from "@mui/material";
import { StyledStackFour } from "./style.js";

import Background from "./2023-09-20.jpg";

const SignInLargeLogo = () => {
  return (
    <StyledStackFour>
      <Box
        component="img"
        src={Background}
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "15px",
        }}
      />
    </StyledStackFour>
  );
};

export default SignInLargeLogo;
