import React from "react";

import { HubDialogWrapper } from "../HubDialogWrapper";
import CreateCompanyContent from "./CreateCompanyContent";

export const CreateCompany = ({}) => {
  return (
    <HubDialogWrapper maxWidth="sm" name="create-company">
      <CreateCompanyContent />
    </HubDialogWrapper>
  );
};

export default CreateCompany;
