import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

export const StyledStack = styled(Stack)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  padding: "25px",
  gap: "25px",
  borderRadius: "20px",
  border: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  backgroundColor: theme.palette.hubColors.white,
  "& .MuiBarElement-root": {
    fill: theme.palette.hubColors.mainFocus,
  },
}));
