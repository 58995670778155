import axios from "axios";

import config from "../config/config";
import { buildHeaders } from "./buildAuthHeaders";

const axiosOptions = {
  baseURL: config.apiHost,
  withCredentials: true,
};

const api = axios.create({ ...axiosOptions });
const apiBase = axios.create({ ...axiosOptions });

const requestParams = [
  (request) => {
    request.headers = {
      ...request.headers,
      ...buildHeaders(),
    };

    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
];

api.interceptors.request.use(...requestParams);
apiBase.interceptors.request.use(...requestParams);

export const apiWithoutInterceptors = apiBase;

api.interceptors.response.use(
  (response) => response?.data,
  (error) => Promise.reject(error)
);

export default api;
