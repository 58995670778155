import React from "react";
import { connect } from "react-redux";
import { getIsDialogOpen, setIsOpenDialog } from "../../redux/dialogs";
import { HubButton } from "../inputs";

const mapStateToProps = (state, ownProps) => ({
  open: getIsDialogOpen(state, ownProps.name),
});
const mapDispatchToProps = {
  _setIsOpenDialog: setIsOpenDialog,
};

export const DialogButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  ({
    name,
    open = false,
    _setIsOpenDialog = () => {},
    onClick,
    children,
    ...rest
  }) => {
    const handleClick = (e) => {
      onClick && onClick(e);
      name && _setIsOpenDialog(name, !open);
    };

    return (
      <HubButton {...rest} onClick={handleClick}>
        {children}
      </HubButton>
    );
  }
);
