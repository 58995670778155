import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";

export const StyledStack = styled(Stack, {
  shouldForwardProp: (prop) => !["color"].includes(prop),
})(({ theme, color }) => ({
  backgroundColor: theme.palette.hubColors.white,
  color: theme.palette.hubColors[color || "greyDark"],
  borderLeft: "7px solid",
  borderColor: theme.palette.hubColors[color || "greyDark"],
  flexDirection: "row",
  gap: "10px",
  padding: "15px",
  maxWidth: "500px",
  "& .MuiTypography-root, svg, button": {
    color: "inherit",
  },
}));
