import React, { useState } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

import { EMAIL_REGEX } from "../../utils/validations";
import { usePostCheckEmail } from "../../hooks/react-query/useAccountData";
import { HubHookFormInput, HubIcon } from "../";

const NAME = "email";

const getIsValidEmail = (email) => EMAIL_REGEX.test(email);

export const UserEmailInput = ({ defaultValue, useAdornment = true }) => {
  const [hasError, setHasError] = useState(false);
  const [changeLoading, setChangeLoading] = useState(false);
  const { setError, clearErrors, formState } = useFormContext();

  const removeErrors = () => {
    setHasError(false);
    clearErrors(NAME);
  };

  const onError = () => {
    setHasError(true);
    setError(NAME, { type: "custom", message: "Email already exists" });
  };

  const onSuccess = () => {
    removeErrors();
  };

  const { mutate: postCheckEmail, isLoading } = usePostCheckEmail({
    onError,
    onSuccess,
  });

  const onChange = ({ target }) => {
    const isValidEmail = getIsValidEmail(target?.value);
    // start loading indicator only if the email is valid
    setChangeLoading(isValidEmail);
  };

  const onChangeDebounce = async ({ target }) => {
    // clear onChange loading after debounce function is called
    setChangeLoading(false);
    // if it's not a valid email, don't check availability
    const isValidEmail = getIsValidEmail(target?.value);
    if (isValidEmail) {
      const data = {
        username: target?.value,
      };
      return postCheckEmail({ data });
    }

    // clears email availability error if form hasn't been validated
    if (!formState?.isSubmitted) {
      removeErrors();
    }
  };

  const rules = {
    validate: {
      available: () => (hasError ? "Email already exists" : true),
    },
  };

  return (
    <HubHookFormInput
      label="Email"
      defaultValue={defaultValue}
      disabled={!!defaultValue}
      onChange={onChange}
      onChangeDebounce={onChangeDebounce}
      onChangeDebounceTimeout={500}
      maxLength={50}
      name={NAME}
      placeholder="Work Email"
      isLoading={isLoading || changeLoading}
      rules={rules}
      required
      isEmail
      smallLabel
      disableHelperText={!hasError}
      {...(useAdornment
        ? {
            startAdornment: <HubIcon icon="Mail" />,
          }
        : {})}
    />
  );
};

UserEmailInput.propTypes = {
  defaultValue: PropTypes.string,
};

export default UserEmailInput;
