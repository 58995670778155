import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";

export const StyledAnimateStackX = styled(Stack, {
  shouldForwardProp: (prop) => !["reverse", "delay", "view"].includes(prop),
})(({ reverse, delay = 0, view }) => ({
  opacity: 0,
  transform: `translateX(${reverse ? "100%" : "-100%"})`,
  transition:
    "opacity 0.25s cubic-bezier(0, 0, 0.2, 1) 0ms, transform 0.25s cubic-bezier(0, 0, 0.2, 1) 0ms",
  ...(view
    ? {
        opacity: 1,
        transitionDelay: `${delay}ms`,
        transform: "translateX(0)",
      }
    : {}),
}));

export const StyledAnimateStackY = styled(Stack, {
  shouldForwardProp: (prop) => !["reverse", "delay", "view"].includes(prop),
})(({ reverse, delay = 0, view }) => ({
  opacity: 0,
  transform: `translateY(${reverse ? "-100%" : "100%"})`,
  transition:
    "opacity 0.25s cubic-bezier(0, 0, 0.2, 1) 0ms, transform 0.25s cubic-bezier(0, 0, 0.2, 1) 0ms",
  ...(view
    ? {
        opacity: 1,
        transitionDelay: `${delay}ms`,
        transform: "translateY(0)",
      }
    : {}),
}));
