import { styled } from "@mui/material/styles";
import HubInput from "../HubInput/HubInput";

export const inputLabelStyles = {
  fontSize: "16px",
  color: "vpColors.black",
  position: "relative",
  fontWeight: 500,
  left: "-14px",
  top: "-10px",
  "&.Mui-focused": {
    color: "vpColors.black",
  },
};

export const StyledHubInput = styled(HubInput)({
  "& .MuiInputBase-root": {
    padding: "0px !important",
    marginTop: "5px",
  },
  "& .MuiChip-root": {
    fontSize: 12,
    padding: "3px",
    //boxShadow: "0px 2px 0px rgba(0,0,0,0.25)",
  },
});
