import { styled } from "@mui/material/styles";
import { MenuItem, Menu } from "@mui/material";

export const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== "color",
})(({ theme, color }) => ({
  "& .MuiTypography-root": {
    color:
      theme.palette.hubColors[color] ||
      color ||
      theme.palette.hubColors.darkGray1,
    fontSize: 13,
  },
  svg: {
    color:
      theme.palette.hubColors[color] ||
      color ||
      theme.palette.hubColors.darkGray1,
    width: "15px",
    height: "15px",
  },
}));

//MuiButtonBase-root
export const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    border: "1px solid",
    borderColor: theme.palette.hubColors.greyLight,
    boxShadow: "0px 2px 7px rgba(0,0,0,0.25)",
  },
}));
