export const ROUTING_LABELS = [
  "Wax",
  "Assembly & Autoclave",
  "Dip",
  "Foundry",
  "Post Foundry",
  "Outside Processing",
];

export const DEPARTMENT_LABELS = [
  "Wax",
  "Assembly",
  "Dip",
  "Autoclave",
  "Foundry",
  "Post Foundry",
  "Outside Processing",
  "Inspection",
  "Shipping",
];

export const DEPARTMENTS = DEPARTMENT_LABELS.map((label, id) => ({
  id: id + 1,
  label,
}));

export const ROUTINGS = [
  {
    id: 1,
    label: "Wax",
    key: "wax",
  },
  {
    id: 2,
    label: "Assembly & Autoclave",
    key: "assembly&autoclave",
  },
  {
    id: 3,
    label: "Dip",
    key: "dip",
  },
  {
    id: 5,
    label: "Foundry",
    key: "foundry",
  },
  {
    id: 6,
    label: "Post Foundry",
    key: "postfoundry",
  },
  {
    id: 7,
    label: "Outside Processing",
    key: "outsideprocessing",
  },
];

export const PART_ROUTINGS = [
  {
    costcode: "0101",
    label: "Injection",
    id: 1,
  },
  {
    costcode: "0102",
    label: "Assembly",
    id: 2,
  },
  {
    costcode: "0103",
    label: "Etching",
    id: 3,
  },
  {
    costcode: "0201",
    label: "Dipping",
    id: 4,
  },
  {
    costcode: "0202",
    label: "Dipping | By Hand",
    id: 5,
  },
  {
    costcode: "0301",
    label: "Autoclave",
    id: 6,
  },
  {
    costcode: "0401",
    label: "Pouring",
    id: 7,
  },
  {
    costcode: "0501",
    label: "Cutoff - Robot",
    id: 8,
  },
  {
    costcode: "0502",
    label: "Cutoff - Chop | Friction",
    id: 9,
  },
  {
    costcode: "0503",
    label: "Blast - Spinner Hanger",
    id: 10,
  },
  {
    costcode: "0504",
    label: "Blast - Tumble",
    id: 11,
  },
  {
    costcode: "0505",
    label: "Welding",
    id: 12,
  },
  {
    costcode: "0506",
    label: "Grinding",
    id: 13,
  },
  {
    costcode: "0507",
    label: "Detailing",
    id: 14,
  },
  {
    costcode: "0508",
    label: "Heat Treat",
    id: 15,
  },
  {
    costcode: "0509",
    label: "Straightening",
    id: 16,
  },
  {
    costcode: "0535",
    label: "Blast - Sand Blasting",
    id: 17,
  },
  {
    costcode: "0701",
    label: "Inspection",
    id: 18,
  },
  {
    costcode: 225,
    label: "Drilling & Tapping",
    id: 19,
  },
];

export const OUTSIDE_ROUTINGS = [
  {
    label: "Charpy Testing",
    id: 1,
  },
  {
    label: "Customs Control",
    id: 2,
  },
  {
    label: "Flange Surface Grinding",
    id: 3,
  },
  {
    label: "Grind",
    id: 4,
  },
  {
    label: "Grind Gate Flush",
    id: 5,
  },
  {
    label: "Heat Treat",
    id: 6,
  },
  {
    label: "Machine",
    id: 7,
  },
  {
    label: "MSI Testing",
    id: 8,
  },
  {
    label: "NDT Testing",
    id: 9,
  },
  {
    label: "Surface Grind",
    id: 10,
  },
  {
    label: "Surface Grinding",
    id: 11,
  },
  {
    label: "Surface Grinding | Diversified",
    id: 12,
  },
  {
    label: "X-Ray",
    id: 13,
  },
];
