import React, { useState } from "react";
import { Checkbox, Stack, Typography } from "@mui/material";
import { connect } from "react-redux";

import {
  getCustomerData,
  getCustomerDataAddresses,
  getCustomerDataContacts,
} from "../../redux/customerData";
import {
  StyledSideBar,
  StyledTitleStack,
  StyledContactButton,
  StyledAddressStack,
  StyledDetailStack,
  StyledFormControlLabel,
  StyledEditButton,
} from "./styles";

import { InputLabel } from "../../components";
import ContactDrawer from "./ContactDrawer";

const sharedProps = {
  variant: "contained",
  size: "small",
};

const getAddressType = (address) => {
  return address.billing && address.shipping
    ? "Main"
    : address.billing
    ? "Billing"
    : address.shipping
    ? "Shipping"
    : "";
};

const NonEditInput = ({ label, value }) => (
  <Stack>
    <Typography variant="inputLabelSmall" sx={{ color: "hubColors.mainFocus" }}>
      {label}
    </Typography>
    <Typography variant="caption">{value}</Typography>
  </Stack>
);

const ContactSideBar = ({ details = {}, addresses = [], contacts = [] }) => {
  const [openEdit, setOpenEdit] = useState(false);

  const primary =
    contacts.length &&
    (contacts?.find((c) => c?.isPrimary) ||
      contacts?.find((c) => !!c?.email) ||
      contacts[0]);

  const filteredAddress = addresses?.filter((a) => a?.id && !a?.deleted);

  return (
    <StyledSideBar>
      <StyledTitleStack>
        <Typography variant="h3">{details.customer}</Typography>
        {!!primary && (
          <Stack alignItems="center">
            <Typography variant="body2">{primary?.name}</Typography>
            <Typography variant="body2">{primary?.email}</Typography>
          </Stack>
        )}
        <Stack direction="row" gap="15px">
          <StyledContactButton
            icon="Phone"
            {...sharedProps}
            disabled={!(primary?.phoneNumber || primary?.workNumber)}
          />
          <StyledContactButton
            icon="MessageSquare"
            {...sharedProps}
            disabled={!(primary?.phoneNumber || primary?.workNumber)}
          />
          <StyledContactButton
            icon="Mail"
            {...sharedProps}
            disabled={!primary?.email}
          />
        </Stack>
      </StyledTitleStack>
      <StyledAddressStack>
        <StyledEditButton
          label="Edit Details"
          variant="contained"
          size="small"
          color="grey"
          onClick={() => setOpenEdit(true)}
        />
        <Stack direction="row" justifyContent="space-between" gap="10px">
          <NonEditInput
            label="Industry"
            value={details.industry ? details.industry : "Not Set"}
          />
          <NonEditInput label="Location" value={details.location} />
          <NonEditInput
            label="Active"
            value={details.active ? "Active" : "Inactive"}
          />
        </Stack>
        {filteredAddress?.map((address) => (
          <Stack gap="25px" width="100%">
            <Typography variant="boldCaption">
              {getAddressType(address)} Address
            </Typography>
            <StyledDetailStack>
              <NonEditInput label="Address" value={address.street} />
              <NonEditInput label="City" value={address.city} />
              {address.unit && (
                <>
                  <NonEditInput label="Address Line" value={address.unit} />
                  <div></div>
                </>
              )}
              <NonEditInput label="State" value={address.state.toUpperCase()} />
              <NonEditInput label="Zip Code" value={address.zip} />
              <NonEditInput label="Country" value={address.country} />
              <div></div>
              <StyledFormControlLabel
                control={<Checkbox checked={address.billing} disabled />}
                labelPlacement="end"
                label={<InputLabel label="Billing" smallLabel />}
              />
              <StyledFormControlLabel
                control={<Checkbox checked={address.shipping} disabled />}
                labelPlacement="end"
                label={<InputLabel label="Shipping" smallLabel />}
              />
            </StyledDetailStack>
          </Stack>
        ))}
      </StyledAddressStack>
      <ContactDrawer open={openEdit} onClose={() => setOpenEdit(false)} />
    </StyledSideBar>
  );
};

const mapStateToProps = (state) => ({
  details: getCustomerData(state),
  addresses: getCustomerDataAddresses(state),
  contacts: getCustomerDataContacts(state),
});

export default connect(mapStateToProps)(ContactSideBar);
