import React from "react";
import { TableBody } from "@mui/material";
import { connect } from "react-redux";

import {
  addPoLineItem,
  getPurchaseOrderItems,
} from "../../redux/editPurchaseOrder";

import { JOB_STATUSES } from "../../constants";
import OrderLineItemRow from "./OrderLineItemRow";

const OrderLineItemsBody = ({ items = [] }) => {
  return (
    <TableBody>
      {items.map((item, idx) => {
        const disabled = item?.state != JOB_STATUSES.NOT_STARTED && item?.state;
        const complete = item?.state === JOB_STATUSES.COMPLETE;
        return (
          <OrderLineItemRow
            item={item}
            key={JSON.stringify(item)}
            idx={idx}
            disabled={disabled}
            complete={complete}
          />
        );
      })}
    </TableBody>
  );
};

const mapStateToProps = (state) => ({
  items: getPurchaseOrderItems(state),
});

const mapDispatchToProps = {
  _addLineItem: addPoLineItem,
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderLineItemsBody);
