import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

import { StyledBox, StyledStack, StyledHubIcon, StyledTooltip } from "./styles";

export const HubHoverInfo = ({
  title,
  titleProps,
  icon,
  iconProps,
  label = "",
  disablePointer = false,
  disableInteractive = true,
  isCard = false,
  children,
  fullWidth = false,
  disableFlex = false,
  enterDelay = 0,
  ...rest
}) => {
  const tooltip = (
    <StyledTooltip
      isCard={isCard}
      title={label}
      disableInteractive={disableInteractive && !isCard}
      enterDelay={enterDelay}
      {...rest}
    >
      <StyledBox
        fullWidth={fullWidth}
        disablePointer={disablePointer}
        disableFlex={disableFlex}
      >
        {icon && <StyledHubIcon icon={icon} {...iconProps} />}
        {children}
      </StyledBox>
    </StyledTooltip>
  );

  if (!title) {
    return tooltip;
  }
  return (
    <StyledStack fullWidth={fullWidth} disableFlex={disableFlex}>
      {title && (
        <Typography
          variant="dropDownText"
          sx={{ whiteSpace: "noWrap" }}
          {...titleProps}
        >
          {title}
        </Typography>
      )}
      {tooltip}
    </StyledStack>
  );
};
HubHoverInfo.propTypes = {
  title: PropTypes.string,
  titleProps: PropTypes.object,
  icon: PropTypes.string,
  iconProps: PropTypes.object,
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  disablePointer: PropTypes.bool,
  disableInteractive: PropTypes.bool,
  isCard: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disableFlex: PropTypes.bool,
};

export default HubHoverInfo;
