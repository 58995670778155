import { styled } from "@mui/material/styles";
import { Stack, Typography } from "@mui/material";

export const StyledCard = styled(Stack)(({ theme }) => ({
  padding: "25px",
  gap: "5px",
  borderRadius: "10px",
  border: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  backgroundColor: theme.palette.hubColors.white,
  boxSizing: "border-box",
  flexGrow: 1,
}));

export const StyledPercentStack = styled(Stack, {
  shouldForwardProp: (prop) => !["color"].includes(prop),
})(({ theme, color }) => ({
  flexDirection: "row",
  gap: "5px",
  alignItems: "center",
  color: theme.palette.hubColors[color],
}));

export const StyledPercentText = styled(Typography, {
  shouldForwardProp: (prop) => !["color", "heavy"].includes(prop),
})(({ theme, color, heavy }) => ({
  fontSize: 11,
  color: theme.palette.hubColors[color],
  fontWeight: heavy ? 600 : 300,
}));
