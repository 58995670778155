import React from "react";
import { Table, TableContainer } from "@mui/material";
import OutsideProcessingTableHeader from "./OutsideProcessingTableHeader";
import OutsideProcessingTableBody from "./OutsideProcessingTableBody";

export const OutsideProcessingTable = () => {
  return (
    <TableContainer>
      <Table id="OutsideProcessingTable">
        <OutsideProcessingTableHeader />
        <OutsideProcessingTableBody />
      </Table>
    </TableContainer>
  );
};

export default OutsideProcessingTable;
