import React, { useState } from "react";
import { Table, Collapse, TableRow, TableCell } from "@mui/material";
import { connect } from "react-redux";

import { getPartRoutings } from "../../redux/routing";
import { ROUTINGS as OPTIONS } from "../../constants";

import RoutingTableRow from "./RoutingTableRow";

const RoutingHiddenRows = ({ routings = [] }) => {
  const [open, setOpen] = useState(false);
  const onClick = () => setOpen(!open);
  const hidden = OPTIONS?.filter((route) => {
    if (route?.id === 2 && !(routings?.includes(2) || routings?.includes(4)))
      return true;
    if (!routings?.includes(route.id)) return true;
  });

  const sx = open
    ? { ":hover": { backgroundColor: "transparent !important" }, width: "100%" }
    : { border: "none" };

  return (
    <>
      <TableRow onClick={onClick}>
        <TableCell colSpan={2}>{open ? "Hide" : "Show Hidden"} </TableCell>
      </TableRow>
      <TableRow sx={sx}>
        <TableCell colSpan={3} sx={{ padding: "0px !important" }}>
          <Collapse in={open}>
            <Table sx={{ border: "none !important" }}>
              {hidden?.map((route) => (
                <RoutingTableRow key={route.id} route={route} />
              ))}
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const mapStateToProps = (state) => ({
  routings: getPartRoutings(state),
});
export default connect(mapStateToProps)(RoutingHiddenRows);
