import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import { useGetPartData, usePutPartData } from "../../hooks";
import { loadPartRoutings } from "../../redux/routing";
import { HubHookFormWrapper, PageContent } from "../../components";

import PartDetailsTitle from "./PartDetailsTitle";
import { PART_SECTIONS, UpdateMapper } from "./helper";
import PartDetailsMenu from "./PartDetailsMenu";
import { StyledCardAndMenuStack, StyledDisplayStack } from "./styles";

const formSx = { height: "100%", overflow: "hidden" };

export const PartDetails = ({ _loadPartRoutings = () => {} }) => {
  const { id } = useParams();
  const { data, isLoading, isSuccess } = useGetPartData({ id });
  const { mutate: putPartData } = usePutPartData();

  const [value, setValue] = useState("ROUTING");

  useEffect(() => {
    isSuccess && _loadPartRoutings(data);
  }, [isSuccess]);

  const tabProps = { value, setValue };
  const ActiveTab = PART_SECTIONS[value] || <></>;

  const handleSubmit = (formData) => {
    const payload = UpdateMapper(formData, id);
    putPartData(payload);
  };

  return (
    <PageContent isLoading={isLoading} disablePadding>
      <HubHookFormWrapper onSubmit={handleSubmit} sx={formSx}>
        <PartDetailsTitle id={id} />
        <StyledCardAndMenuStack>
          <PartDetailsMenu {...tabProps} />
          <StyledDisplayStack>{ActiveTab}</StyledDisplayStack>
        </StyledCardAndMenuStack>
      </HubHookFormWrapper>
    </PageContent>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  _loadPartRoutings: loadPartRoutings,
};

export const PartDetailsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(PartDetails);

export default PartDetailsPage;
