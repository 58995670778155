import React from "react";
import { Stack } from "@mui/material";
import { connect } from "react-redux";

import { getCustomerData } from "../../redux/customerData";
import { FOUNDRIES, INDUSTRIES } from "../../constants/industry";
import {
  HubHookFormInput,
  HubHookFormSelect,
  HubHookFormSwitch,
} from "../../components";
import { getUserLocation } from "../../redux/auth";

const CustomerDetails = ({ disabled = false, details = {}, userLocation = 1 }) => {
  const foundry = FOUNDRIES.find((f) => f.label === details.location)?.id;
  return (
    <Stack gap="15px">
      <HubHookFormInput
        name="customerName"
        label="Customer"
        placeholder="IPC Foundry Group"
        defaultValue={details.customer}
        disableHelperText
        disabled={disabled}
        required
      />
      <Stack
        direction="row"
        gap="10px"
        alignItems="center"
        justifyContent="center"
      >
        <HubHookFormSelect
          name="industry"
          label="Industry"
          placeholder="ex. Oil"
          options={INDUSTRIES}
          sx={{ flexGrow: "1" }}
          useDefault={!!details.industry}
          defaultValue={details.industry}
          disabled={disabled}
          disableHelperText
          required
        />
        <HubHookFormSelect
          name="location"
          label="Foundry"
          options={FOUNDRIES}
          sx={{ width: "125px" }}
          formControlProps={{ sx: { minWidth: "125px" } }}
          defaultValue={foundry || userLocation}
          fullWidth={false}
          disabled={disabled}
          disableHelperText
          required
        />
      </Stack>
      <HubHookFormSwitch
        name="active"
        label="Active Customer"
        labelPlacement="end"
        defaultValue={details.active}
        disabled={disabled}
      />
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  details: getCustomerData(state),
  userLocation: getUserLocation(state),
});

export default connect(mapStateToProps)(CustomerDetails);
