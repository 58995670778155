export const Play2 = ({ ...rest }) => {
  return (
    <svg
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M23.0711 8.85465L7.3794 0.586096C5.80538 -0.243245 3.9562 -0.189687 2.43286 0.729451C0.909517 1.64859 0 3.25948 0 5.03875V19.0652C0 20.8445 0.909517 22.4554 2.43286 23.3745C3.23751 23.8601 4.13275 24.104 5.03056 24.104C5.83248 24.104 6.63665 23.9091 7.37924 23.5179L23.071 15.2492C24.2608 14.6222 25 13.3971 25 12.0521C25 10.707 24.2609 9.48178 23.0711 8.85465Z"
        fill="white"
        fillOpacity="0.95"
      />
    </svg>
  );
};
